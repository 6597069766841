<ngx-select
    #ngxSelect
    (selectionChanges)="selectionChanges($event)"
    (typed)="typed.next($event)"
    [formControl]="selectCtrl"
    [items]="items"
    [allowClear]="true"
    [autoActiveOnMouseEnter]="false"
    placeholder="Enter user name or email.."
></ngx-select>
