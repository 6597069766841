import { Action } from '@ngrx/store';
import { CustomerMailTemplate } from '@appRoot/core/customer/models';
import { NGRXError } from '@appRoot/core/ngrx-store/models/NGRXError';


export enum ActionTypes {
    GET = '[Customer Mail Templates] get',
    GET_SUCCESS = '[Customer Mail Templates] get success',
    GET_FAILED = '[Customer Mail Templates] get failed',

    CREATE = '[Customer Mail Templates] create',
    CREATE_SUCCESS = '[Customer Mail Templates] create success',
    CREATE_FAILED = '[Customer Mail Templates] create failed',

    UPDATE = '[Customer Mail Templates] update',
    UPDATE_SUCCESS = '[Customer Mail Templates] update success',
    UPDATE_FAILED = '[Customer Mail Templates] update failed',

    DELETE = '[Customer Mail Templates] delete',
    DELETE_SUCCESS = '[Customer Mail Templates] delete success',
    DELETE_FAILED = '[Customer Mail Templates] delete failed',

    ERROR = '[Customer Mail Templates] error',
}

export class Get implements Action {
    readonly type = ActionTypes.GET;
}
export class GetSuccess implements Action {
    readonly type = ActionTypes.GET_SUCCESS;
    constructor(readonly payload: {
        variables: string[];
        templates: CustomerMailTemplate[];
    }) {}
}
export class GetFailed implements Action {
    readonly type = ActionTypes.GET_FAILED;
}

export class Create implements Action {
    readonly type = ActionTypes.CREATE;
    constructor(readonly payload: {notification: CustomerMailTemplate;}) { }
}
export class CreateSuccess implements Action {
    readonly type = ActionTypes.CREATE_SUCCESS;
    constructor(readonly payload: {notification: CustomerMailTemplate}) { }
}
export class CreateFailed implements Action {
    readonly type = ActionTypes.CREATE_FAILED;
}

export class Update implements Action {
    readonly type = ActionTypes.UPDATE;
    constructor(readonly payload: {
        notification: CustomerMailTemplate;
        changes: {notification: CustomerMailTemplate;};
    }) {}
}
export class UpdateSuccess implements Action {
    readonly type = ActionTypes.UPDATE_SUCCESS;
    constructor(readonly payload: {notification: CustomerMailTemplate}) { }
}
export class UpdateFailed implements Action {
    readonly type = ActionTypes.UPDATE_FAILED;
}

export class Delete implements Action {
    readonly type = ActionTypes.DELETE;
    constructor(readonly payload: {notification: CustomerMailTemplate}) { }
}
export class DeleteSuccess implements Action {
    readonly type = ActionTypes.DELETE_SUCCESS;
    constructor(readonly payload: {notification: CustomerMailTemplate}) { }
}
export class DeleteFailed implements Action {
    readonly type = ActionTypes.DELETE_FAILED;
}

export class Error implements Action {
    readonly type = ActionTypes.ERROR;

    constructor(readonly payload: NGRXError<Actions>) { }
}


export type Actions =
    | Get
    | GetSuccess
    | GetFailed
    | Create
    | CreateSuccess
    | CreateFailed
    | Update
    | UpdateSuccess
    | UpdateFailed
    | Delete
    | DeleteSuccess
    | DeleteFailed
    | Error
    ;
