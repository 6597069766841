import { Injectable } from '@angular/core';
import { LocalStorageService } from 'ngx-webstorage';

@Injectable({providedIn: "root"})
export class GlobalStorageService {

	readonly storageKey = 'globalStorage';

	constructor(
		private localStorage: LocalStorageService
		){
	}

	get(){
		return this.localStorage.retrieve(this.storageKey) || {};
	}

	set(data: {[key:string]: any}){
		this.localStorage.store(this.storageKey, { ...this.get(), ...data});
	}

	clear(){
		this.localStorage.clear(this.storageKey);
	}

}