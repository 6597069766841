import { NgModule } from '@angular/core';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import { SettingsService } from '@appRoot/core/services/settings.service';
import { MultiTranslateHttpLoader } from "ngx-translate-multi-http-loader";
import { Location } from '@angular/common';

// AoT requires an exported function for factories
export function createTranslateLoader(http: HttpClient, settings: SettingsService) {
    return new MultiTranslateHttpLoader(http, [
        {prefix: Location.joinWithSlash(settings.ASSETS_PATH, 'i18n/app/core/'), suffix: ".json"},
        {prefix: Location.joinWithSlash(settings.ASSETS_PATH, 'i18n/app/button/'), suffix: ".json"},
    ]);
}

@NgModule({
    imports: [
        HttpClientModule,
        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useFactory: (createTranslateLoader),
                deps: [HttpClient, SettingsService]
            },
            isolate: true
        }),
    ],
})
export class TranslatorModule {}
