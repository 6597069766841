export interface IAvailableDataIndexParams {
    uid: number;
}

export interface IAvailableDataResponse {
    customer_show: boolean;
    customer_id: number | boolean;
    shared_show: boolean;
}

export class AvailableData {
    customer_show: boolean;
    customer_id: number | boolean;
    shared_show: boolean;

    constructor(response?: IAvailableDataResponse) {
        if (response) {
            this.customer_show = response.customer_show;
            this.customer_id = response.customer_id;
            this.shared_show = response.shared_show;
        }
    }
}