import { Injectable } from '@angular/core';
import {
    ActivatedRouteSnapshot,
    CanActivate,
    CanActivateChild,
    CanLoad,
    Route,
    RouterStateSnapshot
} from '@angular/router';

import { Observable, of, race } from 'rxjs';
import { catchError, filter, map, switchMap, take } from 'rxjs/operators';

import { AuthenticationService } from '@appRoot/core/authentication/services/authentication.service';
import { select, Store } from '@ngrx/store';
import { State } from '../ngrx-store/reducers/authorization.reducer';
import { getError, getLoading, isAuthorized } from '../ngrx-store/selectors/authorization.selectors';
import * as auzActions from '../ngrx-store/actions/authorization.actions';


@Injectable()
export class AuthorizationGuard implements CanActivate, CanActivateChild, CanLoad {

    private loading = false;

    constructor(
        private authService: AuthenticationService,
        private store: Store<State>,
        ) {}

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
        return this.checkAccess();
    }

    canActivateChild(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
        return this.canActivate(route, state);
    }

    checkAccess(): Observable<boolean> {
        return this.store.pipe(
            select(getLoading),
            filter(loading => !loading),
            switchMap(loading => this.store.pipe(select(isAuthorized))),
            switchMap(e => {
                if(!e && !this.loading) {
                    this.loading = true;
                    this.store.dispatch(new auzActions.GetActiveUserPermissions);
                }

                return race(
                    this.store.pipe(select(isAuthorized), filter(e => e)),
                    this.store.pipe(select(getError), filter(error => error !== null)),
                );
            }),
            map(result => {
                this.loading = false;
                if(typeof result === "boolean") return result;
                this.authService.dispatchUnAuthenticate();
                return false;
            }),
            catchError((err) => {
                console.log( err );
                this.loading = false;
                return of(false);
            }),
            take(1),
        );
    }

    canLoad(route: Route): Observable<boolean> {
        return this.checkAccess();
    }

}
