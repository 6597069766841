<app-preloader *ngIf="loading" type="sk-fading-circle"></app-preloader>
<app-bs4-alert *ngIf="errorShow" [message]="errorMessage" [messages]="errorMessages" type="danger"></app-bs4-alert>
<!--<app-bs4-alert *ngIf="isTokenValid && !complete" message="Reset Token is valid, you can proceed to reset the password." type="success"></app-bs4-alert>-->
<app-bs4-alert *ngIf="complete" message="Password has been successfully changed. You can Sign In now." type="success"></app-bs4-alert>

<ng-container *ngIf="isTokenValid && !complete">
    <form  [formGroup]="form" (ngSubmit)="onSubmit()" class="form-validate" role="form" name="recoverForm" novalidate="">
    <div class="form-group">
        <label class="text-muted mb-1">{{ 'New password' | translate }}</label>
        <div class="input-group with-focus">
            <input formControlName="password" class="form-control border-right-0" id="id-password" type="password" name="password" />
            <div class="input-group-append">
                <span class="input-group-text text-muted bg-transparent border-left-0">
                    <fa-icon [icon]="['fas', 'lock']"></fa-icon>
                </span>
            </div>
        </div>
        <mat-error field-error [control]="password" [messages]="validationMessages"></mat-error>
    </div>
    <div class="form-group">
        <label class="text-muted mb-1">{{ 'Retype' | translate }} {{ 'Password' | translate }}</label>
        <div class="input-group with-focus">
            <input formControlName="confirmPassword" class="form-control border-right-0" type="password" name="confirmPassword" />
            <div class="input-group-append">
                <span class="input-group-text text-muted bg-transparent border-left-0">
                    <fa-icon [icon]="['fas', 'lock']"></fa-icon>
                </span>
            </div>
        </div>
        <mat-error field-error [control]="confirmPassword" [messages]="validationMessages"></mat-error>
    </div>
    <button [disabled]="loading" class="btn btn-danger btn-block" type="submit">{{ 'Save' | translate }}</button>
</form>    
</ng-container>
