<app-bs4-alert *ngIf="errorShow" [message]="errorMessage" type="danger"></app-bs4-alert>

<form (ngSubmit)="onSubmit()" [formGroup]="form" class="form-validate mb-3" novalidate="" role="form">
    <div class="form-group">
        <div class="input-group with-focus">
            <input class="form-control border-right-0" formControlName="username" placeholder="{{'Enter' | translate }} email" required="" type="email" />
            <div class="input-group-append">
                <span class="input-group-text text-muted bg-transparent border-left-0">
                    <fa-icon [icon]="['fas', 'envelope']"></fa-icon>
                </span>
            </div>
        </div>
        <mat-error field-error [control]="username" [messages]="validationMessages"></mat-error>
    </div>
    <div class="form-group">
        <div class="input-group with-focus">
            <input class="form-control border-right-0" formControlName="password" placeholder="{{'Enter' | translate }} {{'Password' | translate | lowercase }}" required="" type="password" maxlength="128" />
            <div class="input-group-append">
                <span class="input-group-text text-muted bg-transparent border-left-0">
                    <fa-icon [icon]="['fas', 'lock']"></fa-icon>
                </span>
            </div>
        </div>
        <mat-error field-error [control]="password" [messages]="validationMessages"></mat-error>
    </div>
    <div class="clearfix">
        <div class="float-right">
            <a class="text-muted" [routerLink]="'/reset-password'">{{'Forgot your password?' | translate }}</a>
        </div>
    </div>
    <button [disabled]="loading" class="btn btn-block btn-primary mt-3" type="submit">{{'Sign in' | translate }}</button>
</form>
