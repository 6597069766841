import { Routes } from '@angular/router';
import { AuthenticationGuard } from '@appRoot/core/authentication/guards/authentication-guard.service';
import { RoleGuard } from '@appRoot/core/roles-permissions/services/role.guard';
import { MasterGuard } from '@appRoot/core/services/master-guard.service';
import { AuthorizationGuard } from '@appRoot/core/user/guards/authorization-guard.service';
import { MODULES } from '@appRoot/lazy-modules/modules.map';
import { PermissionGuard } from '../core/roles-permissions/services/permission.guard';
import { LayoutComponent } from '../layout/layout.component';
import { Error404Component } from './pages/error404/error404.component';
import { Error500Component } from './pages/error500/error500.component';
import { LockComponent } from './pages/lock/lock.component';
import { LoginPageComponent } from './pages/login/login.component';
import { SelfSignupComponent } from './pages/self-signup/self-signup.component';
import { MaintenanceComponent } from './pages/maintenance/maintenance.component';
import { PasswordResetPageComponent } from './pages/password-reset/password-reset.component';
import { RegisterPageComponent } from './pages/register/register.component';
import { VerifyPageComponent } from './pages/verify/verify.component';
import { APP_ROOT_ROUTE_PATH, ROUTE_PATHS } from './routes.names';


export const routes: Routes = [
    {
        path: APP_ROOT_ROUTE_PATH,
        component: LayoutComponent,
        canActivateChild: [MasterGuard],
        data: {guards: [AuthenticationGuard, AuthorizationGuard, RoleGuard, PermissionGuard]},
        children: [
            {path: '', redirectTo: ROUTE_PATHS.HomeModule, pathMatch: 'full'},
            {path: ROUTE_PATHS.HomeModule, loadChildren: MODULES.HomeModule.loadChildren},
            {path: ROUTE_PATHS.UsersModule, loadChildren: MODULES.UsersModule.loadChildren},
            {path: ROUTE_PATHS.CustomersModule, loadChildren: MODULES.CustomersModule.loadChildren},
            {path: ROUTE_PATHS.DkPricelistsModule, loadChildren: MODULES.DkPricelistsModule.loadChildren},

            {
                path: 'settings', children: [
                    {path: '', redirectTo: ROUTE_PATHS.RolesModule, pathMatch: 'full'},
                    {path: ROUTE_PATHS.RolesModule, loadChildren: MODULES.RolesModule.loadChildren},
                    {path: ROUTE_PATHS.PermissionsModule, loadChildren: MODULES.PermissionsModule.loadChildren},
                    {path: ROUTE_PATHS.IpRestrictionsModule, loadChildren: MODULES.IpRestrictionsModule.loadChildren},
                    {path: ROUTE_PATHS.PackagesModule, loadChildren: MODULES.PackagesModule.loadChildren},
                    {path: ROUTE_PATHS.DkProductsModule, loadChildren: MODULES.DkProductsModule.loadChildren},
                    {path: ROUTE_PATHS.SourcesModule, loadChildren: MODULES.SourcesModule.loadChildren},
                    {path: ROUTE_PATHS.EmailLogsModule, loadChildren: MODULES.EmailLogsModule.loadChildren},
                    {path: ROUTE_PATHS.LoginLogsModule, loadChildren: MODULES.LoginLogsModule.loadChildren},
                ]
            },

            {
                path: 'user-settings', children: [
                    {path: '', redirectTo: ROUTE_PATHS.AccountModule, pathMatch: 'full'},
                    {path: ROUTE_PATHS.AccountModule, loadChildren: MODULES.AccountModule.loadChildren},
                ]
            },

            {
                path: 'modules', children: [
                    {
                        path: ROUTE_PATHS.WhmModule,
                        loadChildren: MODULES.WhmModule.loadChildren,
                    },{
                        path: ROUTE_PATHS.NotificationModule,
                        loadChildren: MODULES.NotificationModule.loadChildren,
                    },
                    {
                        path: ROUTE_PATHS.CustomersModule,
                        loadChildren: MODULES.CustomersModule.loadChildren,
                    },
                    {
                        path: ROUTE_PATHS.DkPricelistsModule,
                        loadChildren: MODULES.DkPricelistsModule.loadChildren,
                    },
                ]
            },

            // {path: ROUTE_PATHS.TacServicesModule, loadChildren: MODULES.TacServicesModule.loadChildren},
        ]
    },

    {
        path: APP_ROOT_ROUTE_PATH,
        // component: LayoutComponent,
        children: [
            {path: '', redirectTo: ROUTE_PATHS.login, pathMatch: 'full'},
            {path: ROUTE_PATHS.login, component: LoginPageComponent},
            {path: ROUTE_PATHS.register, component: RegisterPageComponent},
            {path: ROUTE_PATHS.register + '/:token', component: RegisterPageComponent},
            {path: ROUTE_PATHS.verify, component: VerifyPageComponent},
            {path: ROUTE_PATHS.verify + '/:token', component: VerifyPageComponent},
            {path: ROUTE_PATHS.resetPassword, component: PasswordResetPageComponent},
            {path: ROUTE_PATHS.resetPassword + '/:token', component: PasswordResetPageComponent},
            {path: ROUTE_PATHS.selfSignup + '/:service', component: SelfSignupComponent}
        ]
    },

    // Not lazy-loaded routes
    {path: ROUTE_PATHS.lock, component: LockComponent},
    {path: ROUTE_PATHS.maintenance, component: MaintenanceComponent},
    {path: ROUTE_PATHS.NotFound, component: Error404Component},
    {path: ROUTE_PATHS.ErrorPage, component: Error500Component},

    // Not found
    {path: '**', redirectTo: ROUTE_PATHS.NotFound}

];
