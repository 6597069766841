import { Injectable, Inject } from '@angular/core';
import { SwalComponent, ɵa as SwalDefaults } from '@sweetalert2/ngx-sweetalert2';
import { SweetAlertOptions } from 'sweetalert2';

@Injectable()
export class ModalService {

	private _component: SwalComponent;

	constructor(@Inject(SwalDefaults) private readonly defaultSwalOptions: SweetAlertOptions){
	}

	set component(component: SwalComponent){
		this._component = component;
	}
	get component(): SwalComponent{
		return this._component;
	}

	show<T>(): Promise<T>{
		return this._component.show();
	}

	reset(){
		let options = this._component.options;
		for(let prop in options){
			options[prop] = null;
		}
		this._component.options = {...options, ...this.defaultSwalOptions};
	}

	get options(){
		return this._component.options;
	}

	set options(options: SweetAlertOptions){
		this.reset();
		this._component.options = options;
	}
}
