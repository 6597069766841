<div class="wrapper">
    <div class="block-center mt-4 wd-xl">
        <!-- START card-->
        <div class="card card-flat">
            <div class="card-header text-center bg-dark">
                <a href="#">
                    <img class="block-center rounded" src="{{settings.app.logo.default}}" alt="Image" />
                </a>
            </div>
            <div class="card-body">
                <p *ngIf="!token" class="text-center py-2">PASSWORD RESET</p>
                <p *ngIf="token" class="text-center py-2">SET A NEW PASSWORD</p>

                <ng-container *ngIf="!token">
                    <app-reset-password-send></app-reset-password-send>
                    <br><br>
                    <a *ngIf="settings.registrationMethod === 'public'" class="btn btn-block btn-secondary" [routerLink]="'/register'">Sign Up</a>
                    <a class="btn btn-block btn-secondary" [routerLink]="'/login'">Sign In</a>
                </ng-container>

                <ng-container *ngIf="token">
                    <app-reset-password [token]="token" #resetPassword></app-reset-password>
                    <app-login *ngIf="resetPassword.complete"></app-login>
                    <ng-container *ngIf="!resetPassword.complete">
                        <br><br>
                        <a *ngIf="settings.registrationMethod === 'public'" class="btn btn-block btn-secondary" [routerLink]="'/register'">Sign Up</a>
                        <a class="btn btn-block btn-secondary" [routerLink]="'/login'">Sign In</a>
                    </ng-container>
                </ng-container>

            </div>
        </div>
        <!-- END card-->
        <div class="p-3 text-center">
            <span>&copy;</span>
            <span>{{ settings.app.year }}</span>
            <span class="mx-2">-</span>
            <span>{{ settings.app.name }}</span>
            <br/>
            <span>{{ settings.app.description }}</span>
        </div>
    </div>
</div>
