import { Injectable } from '@angular/core';
import { ListMessages } from "../error-catcher.types";
import { Observable, BehaviorSubject } from "rxjs";

@Injectable({
	providedIn: 'root'
})
export class ErrorCatcherMessageService {

	public type: string;
	public message: string;
	public messages: ListMessages;
	public _show: boolean;

	private showSubject: BehaviorSubject<boolean>;

	constructor() {
    this.showSubject = new BehaviorSubject<boolean>(false);
  }

	setMessage(message: string): ErrorCatcherMessageService{
		this.message = message;
		return this;
	}

	setMessages(messages: {	[key:string]: string[] }): ErrorCatcherMessageService{
		this.messages = messages;
		return this;
	}

	clear(): ErrorCatcherMessageService{
		this.message = null;
		this.messages = null;
		return this;
	}

	show(): ErrorCatcherMessageService{
		this._show = true;
		this.showSubject.next(this._show);
		return this;
	}

	hide(): ErrorCatcherMessageService{
		this._show = false;
		this.showSubject.next(this._show);
		return this;
	}

	setType(type: string): ErrorCatcherMessageService{
		this.type = type;
		return this;
	}

	isShows(): Observable<boolean> {
		return this.showSubject.asObservable();
	}

}
