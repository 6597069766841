import { ActionReducerMap, createFeatureSelector } from '@ngrx/store';
import * as user from './reducers/user.reducer';
import * as authorization from './reducers/authorization.reducer';
import * as user_storage from './reducers/user-storage.reducer';


export interface State {
    user_storage: user_storage.State;
    user: user.State;
    authorization: authorization.State;
}

export const reducers: ActionReducerMap<State> = {
    user_storage: user_storage.reducer,
    user: user.reducer,
    authorization: authorization.reducer,
};

export const ModuleStore = createFeatureSelector<State>('User');
