import { Omit } from '@appRoot/core/interfaces';
import { NGRXError } from '@appRoot/core/ngrx-store/models/NGRXError';
import { Action } from '@ngrx/store';
import { IMailingCreateParams, IMailingUpdateParams, IMailProvider, MailTemplate } from '../../models';


export enum ActionTypes {
    LOAD = '[Mailing] load',
    LOAD_SUCCESS = '[Mailing] load success',
    LOAD_FAILED = '[Mailing] load failed',

    CREATE = '[Mailing] create',
    CREATE_SUCCESS = '[Mailing] create success',
    CREATE_FAILED = '[Mailing] create failed',

    GET_BY_ID = '[Mailing] getById',
    GET_BY_ID_SUCCESS = '[Mailing] getById success',
    GET_BY_ID_FAILED = '[Mailing] getById failed',

    UPDATE = '[Mailing] update',
    UPDATE_SUCCESS = '[Mailing] update success',
    UPDATE_FAILED = '[Mailing] update failed',

    DELETE = '[Mailing] delete',
    DELETE_SUCCESS = '[Mailing] delete success',
    DELETE_FAILED = '[Mailing] delete failed',

    GET_PROVIDERS = '[Mailing] getProviders',
    GET_PROVIDERS_SUCCESS = '[Mailing] getProviders success',
    GET_PROVIDERS_FAILED = '[Mailing] getProviders failed',

    ERROR = '[Mailing] error',
    RESET = '[Mailing] reset',
}

export class Error implements Action {
    readonly type = ActionTypes.ERROR;

    constructor(readonly payload: NGRXError<Actions>) { }
}

export class Reset implements Action {
    readonly type = ActionTypes.RESET;
}

export class Load implements Action {
    readonly type = ActionTypes.LOAD
}

export class LoadSuccess implements Action {
    readonly type = ActionTypes.LOAD_SUCCESS;

    constructor(readonly payload: MailTemplate[]) {}
}

export class LoadFailed implements Action {
    readonly type = ActionTypes.LOAD_FAILED;
}

export class Create implements Action {
    readonly type = ActionTypes.CREATE;

    constructor(readonly payload: Omit<IMailingCreateParams, 'uid'>) {}
}

export class CreateSuccess implements Action {
    readonly type = ActionTypes.CREATE_SUCCESS;

    constructor(readonly payload: MailTemplate) {}
}

export class CreateFailed implements Action {
    readonly type = ActionTypes.CREATE_FAILED;
}

export class GetById implements Action {
    readonly type = ActionTypes.GET_BY_ID;

    constructor(readonly payload: number) {}
}

export class GetByIdSuccess implements Action {
    readonly type = ActionTypes.GET_BY_ID_SUCCESS;

    constructor(readonly payload: MailTemplate) {}
}

export class GetByIdFailed implements Action {
    readonly type = ActionTypes.GET_BY_ID_FAILED;
}

export class Update implements Action {
    readonly type = ActionTypes.UPDATE;

    constructor(readonly payload: { entity: MailTemplate, changes: Omit<IMailingUpdateParams, 'uid'> }) {}
}

export class UpdateSuccess implements Action {
    readonly type = ActionTypes.UPDATE_SUCCESS;

    constructor(readonly payload: MailTemplate) {}
}

export class UpdateFailed implements Action {
    readonly type = ActionTypes.UPDATE_FAILED;
}

export class Delete implements Action {
    readonly type = ActionTypes.DELETE;

    constructor(readonly payload: MailTemplate) {}
}

export class DeleteSuccess implements Action {
    readonly type = ActionTypes.DELETE_SUCCESS;

    constructor(readonly payload: MailTemplate) {}
}

export class DeleteFailed implements Action {
    readonly type = ActionTypes.DELETE_FAILED;
}

export class GetProviders implements Action {
    readonly type = ActionTypes.GET_PROVIDERS;
}

export class GetProvidersSuccess implements Action {
    readonly type = ActionTypes.GET_PROVIDERS_SUCCESS;

    constructor(readonly payload: Array<IMailProvider>) {}
}

export class GetProvidersFailed implements Action {
    readonly type = ActionTypes.GET_PROVIDERS_FAILED;
}

export type Actions =
    | Load
    | LoadSuccess
    | LoadFailed
    | Create
    | CreateSuccess
    | CreateFailed
    | GetById
    | GetByIdSuccess
    | GetByIdFailed
    | Update
    | UpdateSuccess
    | UpdateFailed
    | Delete
    | DeleteSuccess
    | DeleteFailed
    | GetProviders
    | GetProvidersSuccess
    | GetProvidersFailed
    | Error
    | Reset
    ;
