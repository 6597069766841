import { Component, Input, OnInit } from '@angular/core';
import { SettingsService } from '@appRoot/core/services/settings.service';
import { Subject } from 'rxjs';


@Component({
    selector: 'app-step-service-container',
    templateUrl: './step-service-container.component.html',
    styleUrls: ['./step-service-container.component.scss']
})
export class StepServiceContainer implements OnInit {

    readonly ngUnsubscribe = new Subject();

    @Input() public service: string;

    constructor(
        public settings: SettingsService
    ) { }

    ngOnInit() {}

    ngOnDestroy(): void {
        this.unsubscribe();
    }

    unsubscribe() {
        this.ngUnsubscribe.next();
        this.ngUnsubscribe.complete();
    }
}