import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    ElementRef,
    HostListener,
    Input,
    OnInit,
    Renderer2,
    ViewChild,
} from '@angular/core';
import { NgUnsubscribe } from '@appRoot/core/interfaces';

import { User } from '@appRoot/core/user/models';
import * as userSelectors from '@appRoot/core/user/ngrx-store/selectors/user.selectors';
import { loadUserById } from '@appRoot/core/user/utils';
import { UserInfoService } from '@appRoot/shared/components/user-info/user-info.service';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';
import { select, Store } from '@ngrx/store';
import { TooltipDirective } from 'ngx-bootstrap/tooltip';
import { BehaviorSubject, race, Subject, timer } from 'rxjs';

import { filter, switchMap, takeUntil, tap } from 'rxjs/operators';


@Component({
    selector: 'app-user-info-tooltip',
    templateUrl: './user-info-tooltip.component.html',
    styleUrls: ['./user-info-tooltip.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UserInfoTooltipComponent implements OnInit, NgUnsubscribe {

    @ViewChild('pop') tooltip: TooltipDirective;
    @ViewChild('pop', { static: true, read: ElementRef }) tooltipElemRef: ElementRef;

    readonly ngUnsubscribe = new Subject<void>();
    public directiveElem: ElementRef; // From directive must be passed!
    public faSpinner = faSpinner;
    public toggleDisplaySubject: Subject<'show' | 'hide'> = new Subject();
    private isShown: boolean = false;
    private userId$: BehaviorSubject<number> = new BehaviorSubject(null);
    private toShow: boolean = false;

    constructor(
        private store: Store<any>,
        private cdRef: ChangeDetectorRef,
        private renderer: Renderer2,
        private userInfoService: UserInfoService
    ) { }

    @Input()
    set userId(userId: number) {
        this.userId$.next(userId);
    }

    private _user: User;

    get user() {
        return this._user;
    }

    set user(val: User) {
        this._user = val;
        this.cdRef.detectChanges();
    }

    @HostListener('mouseenter', ['$event']) onMouseEnter(event) {
        this.show();
    }

    @HostListener('mouseleave', ['$event']) onMouseLeave(event) {
        this.hide();
    }

    ngOnInit(): void {
        this.getUser();
        this.toggleDisplayHandler();
    }

    ngOnDestroy(): void {
        this.unsubscribe();
    }

    unsubscribe(): void {
        this.ngUnsubscribe.next();
        this.ngUnsubscribe.complete();
    }

    show() {
        this.toggleDisplaySubject.next('show');
    }

    hide() {
        this.toggleDisplaySubject.next('hide');
    }

    show$() {
        return this.toggleDisplaySubject.pipe(filter(e => e === 'show'));
    }

    hide$() {
        return this.toggleDisplaySubject.pipe(filter(e => e === 'hide'));
    }

    hideAllTooltips() {
        this.userInfoService.toggleAllTooltips$.next('hide');
    }

    showAllTooltips() {
        this.userInfoService.toggleAllTooltips$.next('show');
    }

    allTooltipsHide$() {
        return this.userInfoService.toggleAllTooltips$.pipe(filter(e => e === 'hide'));
    }

    allTooltipsShow$() {
        return this.userInfoService.toggleAllTooltips$.pipe(filter(e => e === 'show'));
    }

    private loadUser$() {
        return this.userId$.pipe(
            filter(e => !!e),
            switchMap(userId => loadUserById(this.store, userId)),
        );
    }

    private getUser() {
        this.userId$.pipe(
            tap(id => this.user = null),
            switchMap(userId => this.store.pipe(select(userSelectors.getUserById(userId)))),
            takeUntil(this.ngUnsubscribe),
        ).subscribe((user: User) => {
            this.user = user;
        });
    }

    private toggleDisplayHandler(): void {
        this.show$().pipe(
            filter(e => !this.isShown),
            takeUntil(this.ngUnsubscribe),
        ).subscribe(e => {
            this.hideAllTooltips();
            this.toShow = true;
            this.showAllTooltips();
        });

        this.allTooltipsShow$().pipe(
            filter(e => this.toShow),
            takeUntil(this.ngUnsubscribe)
        ).subscribe(e => this.showTooltip());

        this.allTooltipsHide$().pipe(
            tap(e => this.toShow = false),
            takeUntil(this.ngUnsubscribe)
        ).subscribe(e => this.hideTooltip());

        this.show$().pipe(
            switchMap(e => this.loadUser$()),
            takeUntil(this.ngUnsubscribe),
        ).subscribe();

        this.hide$().pipe(
            tap(e => this.toShow = false),
            switchMap(e => (
                race(
                    timer(200),
                    this.show$()
                )
            )),
            takeUntil(this.ngUnsubscribe),
        ).subscribe(e => {
            if(e !== 'show') {
                this.toShow = false;
                this.hideTooltip();
            }
        });
    }

    private configureCoordinates(): void {
        if(this.directiveElem) {
            const tooltipElem = this.tooltipElemRef.nativeElement;
            const height = this.directiveElem.nativeElement.offsetHeight;
            const width = this.directiveElem.nativeElement.offsetWidth;
            this.renderer.setStyle(tooltipElem, 'width', width + 'px');
            this.renderer.setStyle(tooltipElem, 'height', height + 'px');
            this.renderer.setStyle(tooltipElem, 'marginTop', -height + 'px');
        } else {
            throw new Error("Property 'directiveElem' of 'UserInfoTooltipComponent' has not been passed.");
        }
    }

    private showTooltip(): void {
        if(this.isShown) return;
        this.configureCoordinates();
        this.isShown = true;
        this.tooltip.show();
    }

    private hideTooltip(): void {
        this.isShown = false;
        this.tooltip.hide();
    }

}
