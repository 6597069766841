import { Action } from '@ngrx/store';
import { Customer } from '@appRoot/core/customer/models';
import {
    ICustomerGetSubscriptionResponse,
    ICustomerGetInvoiceResponse,
    CustomerEmailLogs,
    ICustomerGetOpenExpensesResponse,
    ICustomerGeTimeTrackingResponse
} from '@appRoot/core/customer/models';


export enum ActionTypes {
    RESET = '[Customer storage] RESET',
    ADD_ONE = '[Customer storage] ADD_ONE',
    UPSERT_ONE = '[Customer storage] UPSERT_ONE',
    UPSERT_MANY = '[Customer storage] UPSERT_MANY',
    UPDATE_ONE = '[Customer storage] UPDATE_ONE',
    REMOVE_ONE = '[Customer storage] REMOVE_ONE',
    REMOVE_MANY = '[Customer storage] REMOVE_MANY',
    UPDATE_EMAIL_LOGS = '[Customer storage] UPDATE_EMAIL_LOGS',
    UPDATE_SUBSCRIPTION = '[Customer storage] UPDATE_SUBSCRIPTION',
    UPDATE_TIME_TRACKING = '[Customer storage] UPDATE_TIME_TRACKING',
    UPDATE_INVOICE = '[Customer storage] UPDATE_INVOICE',
    UPDATE_OPEN_EXPENSES = '[Customer storage] UPDATE_OPEN_EXPENSES',
    UPDATE_EMAIL = '[Customer storage] UPDATE_EMAIL',
    UPDATE_BILLING = '[Customer storage] UPDATE_BILLING',
    UPDATE_PAYMENT_MODE = '[Customer storage] UPDATE_PAYMENT_MODE',
    UPDATE_CREDIT_CARD = '[Customer storage] UPDATE_CREDIT_CARD',
}

export class Reset implements Action {
    readonly type = ActionTypes.RESET;
}

export class AddOne implements Action {
    readonly type = ActionTypes.ADD_ONE;

    constructor(readonly payload: Customer) { }
}

export class UpsertOne implements Action {
    readonly type = ActionTypes.UPSERT_ONE;

    constructor(readonly payload: Customer) { }
}

export class UpsertMany implements Action {
    readonly type = ActionTypes.UPSERT_MANY;

    constructor(readonly payload: Customer[]) { }
}

export class UpdateOne implements Action {
    readonly type = ActionTypes.UPDATE_ONE;

    constructor(readonly payload: Customer) { }
}

export class RemoveOne implements Action {
    readonly type = ActionTypes.REMOVE_ONE;

    constructor(readonly payload: Customer) { }
}

export class RemoveMany implements Action {
    readonly type = ActionTypes.REMOVE_MANY;

    constructor(readonly payload: Customer[]) { }
}

export class UpdateEmailLogs implements Action {
    readonly type = ActionTypes.UPDATE_EMAIL_LOGS;

    constructor(readonly payload: { id: number, email_logs: CustomerEmailLogs[] }) { }
}

export class UpdateSubscription implements Action {
    readonly type = ActionTypes.UPDATE_SUBSCRIPTION;

    constructor(readonly payload: { id: number, subscription: ICustomerGetSubscriptionResponse[] }) { }
}

export class UpdateTimeTracking implements Action {
    readonly type = ActionTypes.UPDATE_TIME_TRACKING;

    constructor(readonly payload: { id: number, time: ICustomerGeTimeTrackingResponse[] }) { }
}

export class UpdateInvoice implements Action {
    readonly type = ActionTypes.UPDATE_INVOICE;

    constructor(readonly payload: { id: number, invoice: ICustomerGetInvoiceResponse[] }) { }
}

export class UpdateOpenExpenses implements Action {
    readonly type = ActionTypes.UPDATE_OPEN_EXPENSES;

    constructor(readonly payload: { id: number, open_expenses: ICustomerGetOpenExpensesResponse[] }) { }
}

export class UpdateEmail implements Action {
    readonly type = ActionTypes.UPDATE_EMAIL;

    constructor(readonly payload: { id: number, email: string }) { }
}

export class UpdateBilling implements Action {
    readonly type = ActionTypes.UPDATE_BILLING;

    constructor(readonly payload: {
        id: number,
        paymentMode: string,
        creditCard?: string,
        creditName?: string,
        creditExpDate?: string,
    }) { }
}

export class UpdatePaymentMode implements Action {
    readonly type = ActionTypes.UPDATE_PAYMENT_MODE;

    constructor(readonly payload: { id: number, paymentMode: string }) { }
}

export class UpdateCreditCard implements Action {
    readonly type = ActionTypes.UPDATE_CREDIT_CARD;

    constructor(readonly payload: {
        id: number,
        creditCard: string,
        creditName?: string,
        creditExpDate?: string,
    }) { }
}

export type Actions =
    | Reset
    | AddOne
    | UpsertOne
    | UpsertMany
    | UpdateOne
    | RemoveOne
    | RemoveMany
    | UpdateEmailLogs
    | UpdateSubscription
    | UpdateInvoice
    | UpdateOpenExpenses
    | UpdateEmail
    | UpdateBilling
    | UpdatePaymentMode
    | UpdateCreditCard
    | UpdateTimeTracking
    ;
