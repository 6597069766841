import { IPaginatedResponse, Omit } from '@appRoot/core/interfaces';
import { NGRXError } from '@appRoot/core/ngrx-store/models/NGRXError';
import { Action } from '@ngrx/store';
import { ICustomerContactsRequestIndexParams, CustomerContacts } from '../../models';


export enum ActionTypes {
    ERROR = '[Customer contacts] error',
    RESET = '[Customer contacts] reset',

    LOAD = '[Customer contacts] load',
    LOAD_SUCCESS = '[Customer contacts] load success',
    LOAD_FAILED = '[Customer contacts] load failed',

    PAGINATION_SET = '[Customer contacts] pagination set',

    CREATE = '[Customer contacts] create',
    CREATE_SUCCESS = '[Customer contacts] create success',
    CREATE_FAILED = '[Customer contacts] create failed',

    GET_BY_ID = '[Customer contacts] get',
    GET_BY_ID_SUCCESS = '[Customer contacts] get success',
    GET_BY_ID_FAILED = '[Customer contacts] get failed',

    UPDATE = '[Customer contacts] update',
    UPDATE_SUCCESS = '[Customer contacts] update success',
    UPDATE_FAILED = '[Customer contacts] update failed',

    DELETE = '[Customer contacts] delete',
    DELETE_SUCCESS = '[Customer contacts] delete success',
    DELETE_FAILED = '[Customer contacts] delete failed',

    RESTORE = '[Customer contacts] restore',
    RESTORE_SUCCESS = '[Customer contacts] restore success',
    RESTORE_FAILED = '[Customer contacts] restore failed',

    RELOAD = '[Customer contacts] reload',

    EDIT_CONTACT = "[Customer contacts] edit main email contact",
}

export class Error implements Action {
    readonly type = ActionTypes.ERROR;
    constructor(readonly payload: NGRXError<Actions>) {}
}

export class Reset implements Action {
    readonly type = ActionTypes.RESET;
}

export class Load implements Action {
    readonly type = ActionTypes.LOAD;
    constructor(readonly payload: Omit<ICustomerContactsRequestIndexParams, 'uid'> = {}, readonly selector: string = null) {}
}

export class LoadSuccess implements Action {
    readonly type = ActionTypes.LOAD_SUCCESS;
    constructor(readonly payload: IPaginatedResponse<CustomerContacts[]>, readonly selector: string = null) {}
}

export class LoadFailed implements Action {
    readonly type = ActionTypes.LOAD_FAILED;
}

export class PaginationSet implements Action {
    readonly type = ActionTypes.PAGINATION_SET;
    constructor(readonly payload: IPaginatedResponse<number[]>) { }
}

export class Create implements Action {
    readonly type = ActionTypes.CREATE;
    constructor(readonly payload: {customer_contact: CustomerContacts}) { }
}
export class CreateSuccess implements Action {
    readonly type = ActionTypes.CREATE_SUCCESS;
    constructor(readonly payload: {customer_contact: CustomerContacts}) { }
}
export class CreateFailed implements Action {
    readonly type = ActionTypes.CREATE_FAILED;
}

export class GetById implements Action {
    readonly type = ActionTypes.GET_BY_ID;
    constructor(readonly payload: {customer_contact: CustomerContacts}) { }
}
export class GetByIdSuccess implements Action {
    readonly type = ActionTypes.GET_BY_ID_SUCCESS;
    constructor(readonly payload: {customer_contact: CustomerContacts}) { }
}
export class GetByIdFailed implements Action {
    readonly type = ActionTypes.GET_BY_ID_FAILED;
}

export class Update implements Action {
    readonly type = ActionTypes.UPDATE;
    constructor(readonly payload: {
        customer_contact: CustomerContacts;
        changes: {customer_contact: CustomerContacts;};
    }) { }
}
export class UpdateSuccess implements Action {
    readonly type = ActionTypes.UPDATE_SUCCESS;
    constructor(readonly payload: CustomerContacts[]) {}
}
export class UpdateFailed implements Action {
    readonly type = ActionTypes.UPDATE_FAILED;
}

export class Delete implements Action {
    readonly type = ActionTypes.DELETE;
    constructor(readonly payload: {customer_contact: CustomerContacts}) {}
}
export class DeleteSuccess implements Action {
    readonly type = ActionTypes.DELETE_SUCCESS;
}
export class DeleteFailed implements Action {
    readonly type = ActionTypes.DELETE_FAILED;
}

export class Restore implements Action {
    readonly type = ActionTypes.RESTORE;
    constructor(readonly payload: {customer_contacts: CustomerContacts[]}) { }
}
export class RestoreSuccess implements Action {
    readonly type = ActionTypes.RESTORE_SUCCESS;
    constructor(readonly payload: {customer_contacts: CustomerContacts[]}) { }
}
export class RestoreFailed implements Action {
    readonly type = ActionTypes.RESTORE_FAILED;
}

export class Reload implements Action {
    readonly type = ActionTypes.RELOAD;
}

export class EditContact implements Action {
    readonly type = ActionTypes.EDIT_CONTACT;
}

export type Actions =
    | Error
    | Reset
    | Load
    | LoadSuccess
    | LoadFailed
    | PaginationSet
    | Create
    | CreateSuccess
    | CreateFailed
    | GetById
    | GetByIdSuccess
    | GetByIdFailed
    | Update
    | UpdateSuccess
    | UpdateFailed
    | Delete
    | DeleteSuccess
    | DeleteFailed
    | Restore
    | RestoreSuccess
    | RestoreFailed
    | Reload
    | EditContact
    ;
