<!-- START Sidebar (left)-->
<div class="aside-inner">
    <nav class="sidebar" sidebar-anyclick-close="" [class.show-scrollbar]="settings.layout.asideScrollbar">

    	<ul *ngIf="user" class="sidebar-nav">
    		<li class="nav-heading">
    			<span class="p-15">{{ user?.customerName || user?.name }}</span>
    		</li>
    	</ul>

        <!-- START user info-->
        <!-- <div class="has-user-block">
            <app-userblock></app-userblock>
        </div> -->
        <!-- END user info-->

        <app-nav-item [navItems]="menuItems"></app-nav-item>

    </nav>
</div>
<!-- END Sidebar (left)-->
