import { ICustomerGeTimeTrackingResponse } from './ICustomerRequestParams';

export class TimeTracking {
	id: number;
	spent_date: string;
	hours: string;
	hours_without_timer: string;
	notes: string;
	created_at: string;

	constructor(data?: ICustomerGeTimeTrackingResponse){
		if (data) {
            this.id = data.id;
            this.spent_date = data.spent_date;
            this.hours = data.hours;
            this.hours_without_timer = data.hours_without_timer;
            this.notes = data.notes;
            this.created_at = data.created_at;
		}
	}
}