import { Component, OnDestroy, OnInit } from '@angular/core';
import { SettingsService } from '@appRoot/core/services/settings.service';
import { User } from '@appRoot/core/user/models';
import { UserService } from '@appRoot/core/user/services/user.service';
import { select, Store } from '@ngrx/store';
import { Subject, Subscription } from 'rxjs';
import { NavItemService } from '../../shared/components/nav-item/nav-item.service';
import { NavItem } from '../../shared/components/nav-item/NavItem';
import * as availableDataSelectors from "@appRoot/core/available-data/ngrx-store/selectors/available-data.selectors";
import { filter, takeUntil } from "rxjs/operators";
import * as availableDataActions from "@appRoot/core/available-data/ngrx-store/actions/available-data.actions";


@Component({
    selector: 'app-sidebar',
    templateUrl: './sidebar.component.html',
    styleUrls: ['./sidebar.component.scss']
})
export class SidebarComponent implements OnInit, OnDestroy {

    readonly ngUnsubscribe = new Subject();

    public menuItems: NavItem[];
    public user: User;
    private userSbn: Subscription;

    constructor(
    	private navService: NavItemService,
    	public settings: SettingsService,
    	private userService: UserService,
    	private store: Store<any>,
    	) {
        this.menuItems = navService.getMenu();
    }

    ngOnInit() {
    	this.userSbn = this.userService.activeUser$.subscribe(user => {
            this.user = user;
        });

        this.loadAvailableData();
    }

    ngOnDestroy(){
    	this.userSbn.unsubscribe();
    }

    private loadAvailableData(): void {
        this.store.pipe(
            select(availableDataSelectors.getLoaded),
            takeUntil(this.ngUnsubscribe),
            filter(e => !e),
        ).subscribe(e => {
            this.store.dispatch(new availableDataActions.Load());
        });
    }
}
