import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { NgUnsubscribe } from '@appRoot/core/interfaces';
import { User } from "@appRoot/core/user/models";
import * as userSelectors from '@appRoot/core/user/ngrx-store/selectors/user.selectors';
import { UserInfoService } from '@appRoot/shared/components/user-info/user-info.service';
import { select, Store } from '@ngrx/store';
import { BehaviorSubject, Subject } from 'rxjs';
import { filter, switchMap, takeUntil, tap } from 'rxjs/operators';


@Component({
    selector: 'app-user-render-info',
    templateUrl: './user-render-info.component.html',
    styleUrls: ['./user-render-info.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class UserRenderInfoComponent implements OnInit, NgUnsubscribe {

    readonly ngUnsubscribe = new Subject<any>();
    private userId$ = new BehaviorSubject<number>(null);

    private _user: User;
    get user() {
        return this._user;
    }
    set user(val: User) {
        this._user = val;
        this.cdRef.detectChanges();
    }

    @Input()
    set userId(userId: number) {
        this.userId$.next(userId);
    };

    @Input() showTooltip = true;

    private _fields: Array<keyof User | string> = ['name'];
    @Input() set fields(val: Array<keyof User | string>){
        this._fields = val;
        this.cdRef.detectChanges();
    }

    constructor(
        private store: Store<any>,
        private cdRef: ChangeDetectorRef,
        private userInfoService: UserInfoService
    ) {}

    ngOnInit(): void {
        this.loadUser();
        this.getUser();
    }

    ngOnDestroy(): void {
        this.unsubscribe();
    }

    unsubscribe(): void {
        this.ngUnsubscribe.next();
        this.ngUnsubscribe.complete();
    }

    getUserFields() {
        let userFields = [];
        if (this.user) {
            userFields = this._fields.map(field => {
                return this.formatField(field);
            });
        }
        return userFields.join(' ').trim();
    }

    private loadUser() {
        this.userId$.pipe(
            filter(e => !!e),
            takeUntil(this.ngUnsubscribe),
        ).subscribe(id => this.userInfoService.loadUser(id));
    }

    private getUser() {
        this.userId$.pipe(
            tap(id => this.user = null),
            switchMap(userId => this.store.pipe(select(userSelectors.getUserById(userId)))),
            takeUntil(this.ngUnsubscribe),
        ).subscribe((user: User) => {
            this.user = user;
        });
    }

    private formatField(field: string): string {
        let keys = field.split('/');
        for (let key of keys) {
            if (this.user[key]) {
                return this.user[key];
            }
        }
        return '';
    }

}
