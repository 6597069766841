import { Component } from '@angular/core';
import { SettingsService } from '@appRoot/core/services/settings.service';
import { ActivatedRoute } from "@angular/router";


@Component({
    selector: 'app-self-signup',
    templateUrl: './self-signup.component.html',
    styleUrls: ['./self-signup.component.scss']
})

export class SelfSignupComponent {

    public service: string;

    constructor(
        public settings: SettingsService,
        private route: ActivatedRoute
    ) {}

    ngOnInit() {
        if (this.route.snapshot.paramMap.has('service')) {
            this.service = this.route.snapshot.paramMap.get('service');
        }
    }
}