import {
    ComponentFactoryResolver,
    Directive,
    ElementRef,
    HostListener,
    Input,
    OnInit,
    ViewContainerRef,
} from '@angular/core';
import { CustomerInfoTooltipComponent } from './customer-info-tooltip.component';


@Directive({
    selector: '[appCustomerInfoTooltip]',
})
export class CustomerInfoTooltipDirective implements OnInit {
    private component: CustomerInfoTooltipComponent;

    constructor(
        private el: ElementRef,
        private viewContainerRef: ViewContainerRef,
        private componentFactoryResolver: ComponentFactoryResolver,
    ) { }

    private _customerId: number;

    @Input('appCustomerInfoTooltip')
    set customerId(customerId: number) {
        this._customerId = customerId;
        if(this.component) {
            this.component.customerId = customerId;
        }
    }

    @HostListener('mouseenter', ['$event']) onMouseEnter(event): void {
        this.component.show();
    }

    @HostListener('mouseleave', ['$event']) onMouseLeave(event): void {
        this.component.hide();
    }

    ngOnInit(): void {
        this.createComponent();
    }

    private createComponent(): void {
        const componentFactory = this.componentFactoryResolver.resolveComponentFactory(CustomerInfoTooltipComponent);
        const componentRef = this.viewContainerRef.createComponent(componentFactory);
        this.component = componentRef.instance;
        this.component.directiveElem = this.el;
        this.component.customerId = this._customerId;
    }
}
