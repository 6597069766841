import { CoreEffects } from './effects/core.effects';
import { NavigationEffects } from './effects/navigation.effects';
import { TranslatorEffects } from '@appRoot/core/translator/ngrx-store/effects/translator.effects';
import { AuthenticationEffects } from '@appRoot/core/authentication/ngrx-store/effects/authentication.effects';
import { RegistrationEffects } from '@appRoot/core/authentication/ngrx-store/effects/registration.effects';
import { ConfirmationEffects } from '@appRoot/core/authentication/ngrx-store/effects/confirmation.effects';
import { ResetPasswordEffects } from '@appRoot/core/authentication/ngrx-store/effects/reset-password.effects';
import { PermissionEffects } from '@appRoot/core/roles-permissions/ngrx-store/effects/permission.effects';
import { PermissionGroupEffects } from '@appRoot/core/roles-permissions/ngrx-store/effects/permission-group.effects';
import { RoleEffects } from '@appRoot/core/roles-permissions/ngrx-store/effects/role.effects';
import { AvailableDataEffects } from '@appRoot/core/available-data/ngrx-store/effects/available-data.effects';


export const effects = [
	CoreEffects,
	NavigationEffects,
	TranslatorEffects,
	AuthenticationEffects,
	PermissionEffects,
    PermissionGroupEffects,
	RoleEffects,
	RegistrationEffects,
	ConfirmationEffects,
	ResetPasswordEffects,
	AvailableDataEffects
];
