import { createFeatureSelector, createSelector } from '@ngrx/store';

import { State } from '../reducers/reset-password.reducer';

export const getStore = createFeatureSelector<State>('resetPassword');

export const getLoading = createSelector(
  getStore,
  (state) => state.loading
);

export const getError = createSelector(
  getStore,
  (state) => state.error
);

export const getCreate = createSelector(
  getStore,
  (state) => state.created
);

export const getValid = createSelector(
  getStore,
  (state) => state.valid
);

export const getComplete = createSelector(
  getStore,
  (state) => state.complete
);
