import {HttpClient, HttpParams} from '@angular/common/http';
import { SettingsService } from '../../../core/services/settings.service';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import {
    SelfSignupPackages,
    ISelfSignupRequestStorePaymentParams,
    IUserCustomersResponse,
    ISelfSignupRequestCreateParams
} from '../models';
import { Injectable } from "@angular/core";


@Injectable({
    providedIn: 'root'
})
export class SelfSignupHttpService {

    public readonly edge: string = 'self-signup/accounts-data';

    constructor(
        private http: HttpClient,
        private settings: SettingsService,
    ) {}

    fetch(): Observable<SelfSignupPackages[]> {
        return this.http.get<{ data: SelfSignupPackages[] }>(`${this.settings.API_PATH}/${this.edge}`).pipe(
            map(({ data }) => data),
        );
    }

    getCustomerUsers(email: string): Observable<IUserCustomersResponse[]>  {
        let params = new HttpParams({fromString: 'email='+email});

        return this.http.get<{ data: IUserCustomersResponse[] }>(`${this.settings.API_PATH}/${this.edge}/get-customer-users`, {params: params}).pipe(
            map(response => response.data),
        );
    }

    create(data: ISelfSignupRequestCreateParams): Observable<number> {
        return this.http.post<{ data: number }>(`${this.settings.API_PATH}/${this.edge}`, data).pipe(
            map(response => response.data),
        );
    }

    createPayment(data: ISelfSignupRequestStorePaymentParams): Observable<boolean> {
        return this.http.post<{ data: boolean }>(`${this.settings.API_PATH}/${this.edge}`, data).pipe(
            map(response => response.data),
        );
    }

}
