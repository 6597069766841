import { Injectable } from '@angular/core';
import { NGRXError } from '@appRoot/core/ngrx-store/models/NGRXError';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { Action } from '@ngrx/store';
import { isBoolean, isEmpty, pickBy } from 'lodash';

import { Observable, of as observableOf } from 'rxjs';
import {catchError, filter, map, mergeMap, switchMap, tap, withLatestFrom} from 'rxjs/operators';
import {
    IUserRequestDestroyParams,
    IUserRequestStoreParams,
    IUserRequestUpdateSelf,
    IUserRequestUpdateWithPermission,
    User
} from '../../models';

import { UserHttpService } from '../../services/user-http.service';
import { UserService } from '../../services/user.service';
import * as auzActions from '../actions/authorization.actions';
import * as userStorageActions from '../actions/user-storage.actions';
import * as userActions from '../actions/user.actions';


@Injectable()
export class UserEffects {

    constructor(
        private actions$: Actions,
        private httpService: UserHttpService,
        private userService: UserService,
    ) {}

    @Effect()
    load$: Observable<Action> = this.actions$.pipe(
        ofType(userActions.ActionTypes.LOAD),
        withLatestFrom(this.userService.activeUser$),
        filter(([action, user]: [userActions.Load, User]) => !!user),
        switchMap(([action, user]) =>
            this.httpService
            .fetch({...action.payload, uid: user.id }).pipe(
                switchMap(response => {
                    let users: User[] = [];

                    response.data.forEach(user => {
                        users.push(new User(user));
                    });

                    return [
                        new userStorageActions.UpsertMany(users),
                        new userActions.LoadSuccess({...response, data: users}, action.selector),
                    ];
                }),
                catchError(error => [
                    new userActions.Error(new NGRXError(action, error)),
                    new userActions.LoadFailed,
                ]),
            )
        )
    );

    @Effect()
    getCurrent$: Observable<Action> = this.actions$.pipe(
        ofType(userActions.ActionTypes.GET_CURRENT),
        switchMap((action: userActions.GetCurrent) =>
            this.httpService
            .getCurrent().pipe(
                switchMap(response => {
                    let user = new User(response);

                    return [
                        new userStorageActions.UpsertOne(user),
                        new userActions.GetCurrentSuccess({ user: user }),
                    ];
                }),
                catchError(error => [
                    new userActions.Error(new NGRXError(action, error)),
                    new userActions.GetCurrentFailed,
                ])
            )
        )
    );

    @Effect()
    create$: Observable<Action> = this.actions$.pipe(
        ofType(userActions.ActionTypes.CREATE),
        withLatestFrom(this.userService.activeUser$),
        filter(([action, user]: [userActions.Create, User]) => !!user),
        switchMap(([action, user]) => {
            let params: IUserRequestStoreParams = {
                uid: user.id,
                name: action.payload.user.name,
                status: action.payload.user.status,
                verified: action.payload.user.verified,
                email: action.payload.user.email
            };
            if(action.payload.password){
                params.password = action.payload.password;
                params.password_confirmation = action.payload.passwordConfirmation;
            }
            if(action.payload.notify){
                params.notify = action.payload.notify;
            }

            return this.httpService.create(params).pipe(
                switchMap(response => {
                    let user = new User(response);

                    return [
                        new userStorageActions.UpsertOne(user),
                        new userActions.CreateSuccess({ user: user }),
                        new userActions.Reload(),
                    ];
                }),
                catchError(error => [
                    new userActions.Error(new NGRXError(action, error)),
                    new userActions.CreateFailed,
                ])
            );
        })
    );

    @Effect()
    getById$: Observable<Action> = this.actions$.pipe(
        ofType(userActions.ActionTypes.GET_BY_ID),
        withLatestFrom(this.userService.activeUser$),
        filter(([action, user]: [userActions.GetById, User]) => !!user),
        switchMap(([action, user]) =>
            this.httpService.show({ uid: user.id, user_id: action.payload }).pipe(
                switchMap((response) => {
                    let user = new User(response);

                    return [
                        new userStorageActions.UpsertOne(user),
                        new userActions.GetByIdSuccess({ user: user }),
                    ];
                }),
                catchError(error => [
                    new userActions.Error(new NGRXError(action, error)),
                    new userActions.GetByIdFailed,
                ])
            )
        )
    );

    @Effect()
    update$: Observable<Action> = this.actions$.pipe(
        ofType(userActions.ActionTypes.UPDATE, userActions.ActionTypes.CHANGE_PASSWORD, userActions.ActionTypes.CHANGE_EMAIL),
        withLatestFrom(this.userService.activeUser$),
        filter(([action, user]: [userActions.Update | userActions.ChangePassword | userActions.ChangeEmail, User]) => !!user),
        switchMap(([action, user]) => {
            let data: IUserRequestUpdateWithPermission | IUserRequestUpdateSelf = {};

            if(action instanceof userActions.Update) {
                (<IUserRequestUpdateWithPermission>data).name = action.payload.changes.user.name && (action.payload.user.name !== action.payload.changes.user.name) ? action.payload.changes.user.name : undefined;
                (<IUserRequestUpdateWithPermission>data).email = action.payload.changes.user.email && (action.payload.user.email !== action.payload.changes.user.email) ? action.payload.changes.user.email : undefined;
                (<IUserRequestUpdateWithPermission>data).verified = isBoolean(action.payload.changes.user.verified) && action.payload.user.verified !== action.payload.changes.user.verified ? action.payload.changes.user.verified : undefined;
                (<IUserRequestUpdateWithPermission>data).status = action.payload.changes.user.status && (action.payload.user.status !== action.payload.changes.user.status) ? action.payload.changes.user.status : undefined;
                (<IUserRequestUpdateWithPermission>data).password = action.payload.changes.password ? action.payload.changes.password : undefined;
            } else if(action instanceof userActions.ChangePassword) {
                (<IUserRequestUpdateSelf>data).password = action.payload.changes.newPassword;
                (<IUserRequestUpdateSelf>data).password_confirmation = action.payload.changes.passwordConfirmation;
                (<IUserRequestUpdateSelf>data).current_password = action.payload.changes.currentPassword;
            } else if(action instanceof userActions.ChangeEmail) {
                if(action.payload.user.email !== action.payload.changes.newEmail) {
                    (<IUserRequestUpdateSelf>data).email = action.payload.changes.newEmail;
                    (<IUserRequestUpdateSelf>data).current_password = action.payload.changes.currentPassword;
                }
            }
            const cleanedData = pickBy(data, v => v !== undefined);
            if(isEmpty(cleanedData)) {
                return [new userActions.UpdateFailed];
            }

            return this.httpService.update({ uid: user.id, user_id: action.payload.user.id }, cleanedData).pipe(
                switchMap((response) => {
                    let user = new User(response);

                    return [
                        new userStorageActions.UpsertOne(user),
                        new userActions.UpdateSuccess({ user: user }),
                    ];
                }),
                catchError(error => [
                    new userActions.Error(new NGRXError(action, error)),
                    new userActions.UpdateFailed,
                ])
            );
        }),
    );

    @Effect()
    delete$: Observable<Action> = this.actions$.pipe(
        ofType(userActions.ActionTypes.DELETE),
        withLatestFrom(this.userService.activeUser$),
        filter(([action, user]: [userActions.Delete, User]) => !!user),
        mergeMap(([action, user]) => {
            let params: IUserRequestDestroyParams = {
                uid: user.id,
            };
            if(action.payload.force) {
                params.force = action.payload.force;
            }
            return this.httpService.destroy(action.payload.users.map(u => u.id), params).pipe(
                mergeMap((response) => {
                    let actions: Action[];
                    let users = response.map(e => new User(e));
                    let userIds = users.map(e => e.id);

                    if(action.payload.force === true) {
                        actions = [
                            new userStorageActions.RemoveMany(users),
                            new auzActions.RemoveMany(userIds),
                            new userActions.DeleteSuccess({ users: users, force: action.payload.force }),
                        ];
                    } else {
                        actions = [
                            new userStorageActions.UpsertMany(users),
                            new userActions.DeleteSuccess({ users: users, force: action.payload.force }),
                        ];
                    }
                    return actions;
                }),
                catchError(error => [
                    new userActions.Error(new NGRXError(action, error)),
                    new userActions.DeleteFailed,
                ])
            )
        })
    );

    @Effect()
    restore$: Observable<Action> = this.actions$.pipe(
        ofType(userActions.ActionTypes.RESTORE),
        withLatestFrom(this.userService.activeUser$),
        filter(([action, user]: [userActions.Restore, User]) => !!user),
        mergeMap(([action, user]) =>
            this.httpService
            .restore(action.payload.users.map(u => u.id), { uid: user.id })
            .pipe(
                mergeMap((response) => {
                    let users: User[] = [];
                    response.forEach(e => {
                        users.push(new User(e));
                    });
                    return [
                        new userStorageActions.UpsertMany(users),
                        new userActions.RestoreSuccess({ users: users }),
                    ];
                }),
                catchError(error => [
                    new userActions.Error(new NGRXError(action, error)),
                    new userActions.RestoreFailed,
                ])
            )
        )
    );

    @Effect()
    restoreSelf$: Observable<Action> = this.actions$.pipe(
        ofType(userActions.ActionTypes.RESTORE_SELF),
        switchMap((action: userActions.RestoreSelf) =>
            this.httpService
            .restoreSelf(action.payload)
            .pipe(
                switchMap((response) => {
                    let user = new User(response);
                    return [
                        new userStorageActions.UpsertOne(user),
                        new userActions.RestoreSelfSuccess({ user: user }),
                    ];
                }),
                catchError(error => [
                    new userActions.Error(new NGRXError(action, error)),
                    new userActions.RestoreSelfFailed,
                ])
            )
        )
    );

    @Effect()
    getByNameOrEmail$: Observable<Action> = this.actions$.pipe(
        ofType(userActions.ActionTypes.GET_BY_NAME_OR_EMAIL),
        map((action: userActions.GetByNameOrEmail) => {
            return new userActions.Load({
                search: {
                    strict: false,
                    method: 'OR',
                    name: action.payload,
                    email: action.payload,
                },
                page: 1,
                size: 20,
                status: 'active'
            });
        }),
    );

    @Effect()
    GetByNameEmailCustomerName$: Observable<Action> = this.actions$.pipe(
        ofType(userActions.ActionTypes.GET_BY_NAME_EMAIL_CUSTOMER_NAME),
        map((action: userActions.GetByNameEmailCustomerName) => {
            return new userActions.Load({
                search: {
                    strict: false,
                    method: 'OR',
                    name: action.payload,
                    email: action.payload,
                    customer_name: action.payload,
                },
                page: 1,
                size: 20,
                status: 'active'
            });
        }),
    );

}
