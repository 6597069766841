import { ICustomerGetInvoiceResponse, IInvoiceLines  } from './ICustomerRequestParams';
import { InvoiceLines } from './InvoiceLines';

export class Invoice {
	Number: string;
	Created: string;
	Modified: string;
	CreatedBy: string;
	CNumber: string;
	CName: string;
	CAddress1: string;
	CAddress2: string;
	CAddress3: string;
	CAddress4: string;
	CZipCode: string;
	CCountryCode: string;
	CSSNumber: string;
	CPhone: string;
	CContact: string;
	RecordID: number;
	OrderNumber: number;
	InvoiceDate: string;
	DueDate: string;
	DiscountPercent: number;
	Discount: number;
	TotalAmount: number;
	TotalAmountWithTax: number;
	Currency: string;
	Reference: string;
	Voucher: string;
	SettledType: number;
	SettledAmount: number;
	SalePerson: string;
	Text1: string;
	Text2: string;
	Dim1: string;
	Dim2: string;
	Origin: number;
	PaymentTerm: string;
	PaymentMode: string;
	ClaimStatus: number;
	Exchange: number;
	SalesType: number;
	Version: number;
	Project: string;
	IRNumber: string;
	IRName: string;
	IRAddress1: string;
	IRAddress2: string;
	IRZipCode: string;
	IRContact: string;
	ExternalInvoiceNumber: number;
	ClaimNumber: number;
	ClaimDate: string;
	Register: string;
	PosInvoice: number;
	JournalId: number;
	html?: string;
	Lines: IInvoiceLines[];

	constructor(data?: ICustomerGetInvoiceResponse){
		if (data) {
            this.Number = data.Number;
			this.Created = data.Created;
			this.Modified = data.Modified;
			this.CreatedBy = data.CreatedBy;
			this.CNumber = data.CNumber;
			this.CName = data.CName;
			this.CAddress1 = data.CAddress1;
			this.CAddress2 = data.CAddress2;
			this.CAddress3 = data.CAddress3;
			this.CAddress4 = data.CAddress4;
			this.CZipCode = data.CZipCode;
			this.CCountryCode = data.CCountryCode;
			this.CSSNumber = data.CSSNumber;
			this.CPhone = data.CPhone;
			this.CContact = data.CContact;
			this.RecordID = data.RecordID;
			this.OrderNumber = data.OrderNumber;
			this.InvoiceDate = data.InvoiceDate;
			this.DueDate = data.DueDate;
			this.DiscountPercent = data.DiscountPercent;
			this.Discount = data.Discount;
			this.TotalAmount = data.TotalAmount;
			this.TotalAmountWithTax = data.TotalAmountWithTax;
			this.Currency = data.Currency;
			this.Reference = data.Reference;
			this.Voucher = data.Voucher;
			this.SettledType = data.SettledType;
			this.SettledAmount = data.SettledAmount;
			this.SalePerson = data.SalePerson;
			this.Text1 = data.Text1;
			this.Text2 = data.Text2;
			this.Dim1 = data.Dim1;
			this.Dim2 = data.Dim2;
			this.Origin = data.Origin;
			this.PaymentTerm = data.PaymentTerm;
			this.PaymentMode = data.PaymentMode;
			this.ClaimStatus = data.ClaimStatus;
			this.Exchange = data.Exchange;
			this.SalesType = data.SalesType;
			this.Version = data.Version;
			this.Project = data.Project;
			this.IRNumber = data.IRNumber;
			this.IRName = data.IRName;
			this.IRAddress1 = data.IRAddress1;
			this.IRAddress2 = data.IRAddress2;
			this.IRZipCode = data.IRZipCode;
			this.IRContact = data.IRContact;
			this.ExternalInvoiceNumber = data.ExternalInvoiceNumber;
			this.ClaimNumber = data.ClaimNumber;
			this.ClaimDate = data.ClaimDate;
			this.Register = data.Register;
			this.PosInvoice = data.PosInvoice;
			this.JournalId = data.JournalId;
			this.html = data.html;

			data.Lines.map(lines => {
				this.Lines.push(new InvoiceLines(lines));
			})
		}
	}
}