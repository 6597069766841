import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { effects } from './ngrx-store/effects';
import { reducers } from './ngrx-store/reducers';

@NgModule({
	declarations: [],
	imports: [
        EffectsModule.forFeature(effects),
        StoreModule.forFeature('Notification', reducers),
	],
	providers: [
	]
})
export class NotificationModule { }