<app-preloader *ngIf="loading" type="sk-fading-circle"></app-preloader>
<app-bs4-alert *ngIf="errorShow" [message]="errorMessage" [messages]="errorMessages" type="danger"></app-bs4-alert>
<app-bs4-alert *ngIf="registrationComplete" message="Thank You for Signing Up!" type="success"></app-bs4-alert>
<app-bs4-alert *ngIf="confirmTokenSent" message="We have sent you an activation code. Check your mailbox to confirm your account." type="success"></app-bs4-alert>


<form *ngIf="!registrationComplete" [formGroup]="form" (ngSubmit)="onSubmit()" class="form-validate mb-3" role="form" name="registerForm" novalidate="">

    <div class="form-group">
        <label class="text-muted mb-1">{{ 'Username' | translate }}</label>
        <div class="input-group with-focus">
            <input class="form-control border-right-0" formControlName="name" placeholder="{{ 'Enter' | translate }} {{ 'Username' | translate | lowercase }}" required="" type="text" />
            <div class="input-group-append">
                <span class="input-group-text text-muted bg-transparent border-left-0">
                    <fa-icon [icon]="['fas', 'user']"></fa-icon>
                </span>
            </div>
        </div>
        <mat-error field-error [control]="name" [messages]="validationMessages"></mat-error>
    </div>

    <div class="form-group">
        <label class="text-muted mb-1">{{ 'Email address' | translate }}</label>
        <div class="input-group with-focus">
            <input formControlName="email"  class="form-control border-right-0" type="email" name="account_email" placeholder="{{ 'Enter' | translate }} email" required="" />
            <div class="input-group-append">
                <span class="input-group-text text-muted bg-transparent border-left-0">
                    <fa-icon [icon]="['fas', 'envelope']"></fa-icon>
                </span>
            </div>
        </div>
        <mat-error field-error [control]="email" [messages]="validationMessages"></mat-error>
    </div>

    <div formGroupName="passwordGroup">
        <div class="form-group">
            <label class="text-muted mb-1">{{ 'Password' | translate }}</label>
            <div class="input-group with-focus">
                <input formControlName="password" class="form-control border-right-0" id="id-password" type="password" name="password" />
                <div class="input-group-append">
                    <span class="input-group-text text-muted bg-transparent border-left-0">
                        <fa-icon [icon]="['fas', 'lock']"></fa-icon>
                    </span>
                </div>
            </div>
            <mat-error field-error [control]="password" [messages]="validationMessages"></mat-error>
        </div>

        <div class="form-group">
            <label class="text-muted mb-1">{{ 'Confirm password' | translate }}</label>
            <div class="input-group with-focus">
                <input formControlName="confirmPassword" class="form-control border-right-0" type="password" name="confirmPassword" />
                <div class="input-group-append">
                    <span class="input-group-text text-muted bg-transparent border-left-0">
                        <fa-icon [icon]="['fas', 'lock']"></fa-icon>
                    </span>
                </div>
            </div>
            <mat-error field-error [control]="confirmPassword" [messages]="validationMessages"></mat-error>
        </div>
    </div>

    <div class="clearfix">
        <div class="checkbox c-checkbox float-left mt0">
            <label>
                <input formControlName="accountagreed" type="checkbox" required="" name="account_agreed" />
                <span><fa-icon style="color: white;" [icon]="['fas', 'check']"></fa-icon></span>{{ 'I agree to the' | translate }} <a href="#" [routerLink]="'/terms'">{{ 'terms & conditions' | translate }}</a>
            </label>
        </div>
    </div>
    <mat-error field-error [control]="accountagreed" [messages]="validationMessages"></mat-error>
    <button [disabled]="loading" class="btn btn-block btn-primary mt-3" type="submit">{{ 'Create account' | translate }}</button>
</form>
