import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { Actions, ActionTypes } from '../actions/customer-groups-storage.actions';
import { CustomerGroups } from '@appRoot/core/customer/models';


export function selectId(entity: CustomerGroups): number {
    return entity.id;
}

export const adapter: EntityAdapter<CustomerGroups> = createEntityAdapter<CustomerGroups>({
    selectId: selectId,
    sortComparer: false,
});

export interface State extends EntityState<CustomerGroups> {}

export const initialState: State = adapter.getInitialState();

export function reducer(state = initialState, action: Actions): State {
    switch (action.type) {

        case ActionTypes.RESET:
            return {
                ...initialState
            };

        case ActionTypes.ADD_ONE:
            return adapter.addOne(action.payload, state);

        case ActionTypes.UPSERT_ONE:
            return adapter.upsertOne(action.payload, state);

        case ActionTypes.UPSERT_MANY:
            return adapter.upsertMany(action.payload, state);

        case ActionTypes.UPDATE_ONE:
            return adapter.updateOne({
                id: selectId(action.payload),
                changes: action.payload,
            }, state);

        case ActionTypes.REMOVE_ONE:
            return adapter.removeOne(selectId(action.payload), state);

        case ActionTypes.REMOVE_MANY:
            return adapter.removeMany(action.payload.map(e => selectId(e)), state);

        default:
            return state;
    }
}
