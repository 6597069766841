import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { NGRXError } from 'src/app/core/ngrx-store/models/NGRXError';
import { IMailProvider, MailTemplate } from '../../models';
import { Actions, ActionTypes } from '../actions/mailing.actions';


function selectId(entity: MailTemplate): number {
    return entity.id;
}

export const adapter: EntityAdapter<MailTemplate> = createEntityAdapter({
    selectId: selectId,
    sortComparer: false,
});

export interface State extends EntityState<MailTemplate> {
    error: NGRXError<Actions>;
    loaded: boolean;
    loading: boolean;
    creating: boolean;
    getting: boolean;
    updating: boolean;
    deleting: boolean;
    gettingProviders: boolean;
    providers: Array<IMailProvider>;
}

export const initialState: State = adapter.getInitialState({
    error: null,
    loaded: false,
    loading: false,
    creating: false,
    getting: false,
    updating: false,
    deleting: false,
    gettingProviders: false,
    providers: null,
});

export function reducer(state = initialState, action: Actions): State {
    switch (action.type) {

        case ActionTypes.ERROR:
            return {
                ...state,
                error: action.payload
            };

        case ActionTypes.RESET:
            return {
                ...initialState
            };

        case ActionTypes.LOAD:
            return {
                ...state,
                loading: true,
            };
        case ActionTypes.LOAD_SUCCESS:
            return adapter.addAll(action.payload, {
                ...state,
                loading: false,
                loaded: true,
            });
        case ActionTypes.LOAD_FAILED:
            return {
                ...state,
                loading: false,
                loaded: false,
            };

        case ActionTypes.CREATE:
            return {
                ...state,
                creating: true,
            };
        case ActionTypes.CREATE_SUCCESS:
            return adapter.addOne(action.payload, {
                ...state,
                creating: false,
            });
        case ActionTypes.CREATE_FAILED:
            return {
                ...state,
                creating: false,
            };

        case ActionTypes.GET_BY_ID:
            return {
                ...state,
                getting: true,
            };
        case ActionTypes.GET_BY_ID_SUCCESS:
            return adapter.upsertOne(action.payload, {
                ...state,
                getting: false,
            });
        case ActionTypes.GET_BY_ID_FAILED:
            return {
                ...state,
                getting: false,
            };

        case ActionTypes.UPDATE:
            return {
                ...state,
                updating: true,
            };
        case ActionTypes.UPDATE_SUCCESS:
            return adapter.upsertOne(action.payload, {
                ...state,
                updating: false,
            });
        case ActionTypes.UPDATE_FAILED:
            return {
                ...state,
                updating: false,
            };

        case ActionTypes.DELETE:
            return {
                ...state,
                deleting: false,
            };
        case ActionTypes.DELETE_SUCCESS:
            return adapter.removeOne(selectId(action.payload), {
                ...state,
                deleting: false,
            });
        case ActionTypes.DELETE_FAILED:
            return {
                ...state,
                deleting: false,
            };

        case ActionTypes.GET_PROVIDERS:
            return {
                ...state,
                gettingProviders: true,
            };
        case ActionTypes.GET_PROVIDERS_SUCCESS:
            return {
                ...state,
                gettingProviders: false,
                providers: action.payload,
            };
        case ActionTypes.GET_PROVIDERS_FAILED:
            return {
                ...state,
                gettingProviders: false,
            };

        default:
            return state;
    }
}
