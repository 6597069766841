<form [formGroup]="form" (ngSubmit)="onSubmit()" role="form" name="customerCreateForm" novalidate="">
    <div class="form-group">
        <label class="text-muted">{{ 'Customer ID' | translate }} *</label>
        <div class="input-group with-focus">
            <input formControlName="ssid" class="form-control" type="text" placeholder="{{ 'Enter Customer ID' | translate }}" autocomplete="off" />
        </div>
        <mat-error field-error [control]="ssidCtrl" [messages]="validationMessages"></mat-error>
    </div>

    <div class="form-group">
        <label class="text-muted">{{ 'Name' | translate }} *</label>
        <div class="input-group with-focus">
            <input formControlName="name" class="form-control" type="text" placeholder="{{ 'Enter name' | translate }}" autocomplete="off" />
        </div>
        <mat-error field-error [control]="nameCtrl" [messages]="validationMessages"></mat-error>
    </div>

    <div class="form-group">
        <label class="text-muted">{{ 'Email' | translate }} *</label>
        <div class="input-group with-focus">
            <input formControlName="email" class="form-control" type="email" placeholder="{{ 'Enter email' | translate }}" autocomplete="off" />
        </div>
        <mat-error field-error [control]="emailCtrl" [messages]="validationMessages"></mat-error>
    </div>

    <div class="form-group">
        <label class="text-muted">{{ 'Phone Number' | translate }} *</label>
        <div class="input-group with-focus">
            <input formControlName="phone" class="form-control" type="tel" placeholder="{{ 'Enter phone' | translate }}" autocomplete="off" />
        </div>
        <mat-error field-error [control]="phoneCtrl" [messages]="validationMessages"></mat-error>
    </div>

    <div class="form-group">
        <label class="text-muted">{{ 'Address' | translate }}</label>
        <div class="input-group with-focus">
            <input formControlName="address" class="form-control" type="text" placeholder="{{ 'Enter address' | translate }}" autocomplete="off" />
        </div>
        <mat-error field-error [control]="addressCtrl" [messages]="validationMessages"></mat-error>
    </div>

    <div class="form-group">
        <label class="text-muted">{{ 'City' | translate }}</label>
        <div class="input-group with-focus">
            <input formControlName="city" class="form-control" type="text" placeholder="{{ 'Enter city' | translate }}" autocomplete="off" />
        </div>
        <mat-error field-error [control]="cityCtrl" [messages]="validationMessages"></mat-error>
    </div>

    <div class="form-group">
        <label class="text-muted">{{ 'Postal Code' | translate }}</label>
        <div class="input-group with-focus">
            <input formControlName="postCode" class="form-control" type="text" placeholder="{{ 'Enter postal code' | translate }}" autocomplete="off" />
        </div>
        <mat-error field-error [control]="postCodeCtrl" [messages]="validationMessages"></mat-error>
    </div>

    <div class="form-group">
        <div class="recaptcha-block">
            <re-captcha formControlName="recaptcha"></re-captcha>
            <mat-error field-error [control]="recaptchaCtrl" [messages]="validationMessages"></mat-error>
        </div>
    </div>

    <hr class="mt-5">

    <button [disabled]="loading" class="btn btn-primary btn-block" type="submit">{{ 'Next step' | translate }}</button>
</form>