import { NGRXError } from '@appRoot/core/ngrx-store/models/NGRXError';
import { Action } from '@ngrx/store';
import {
    ISelfSignupRequestStoreCustomerParams,
    ISelfSignupRequestStoreAccountParams,
    ISelfSignupRequestStorePaymentParams,
    SelfSignupPackages,
    IUserCustomersResponse
} from '../../models';


export enum ActionTypes {
    LOAD = '[SelfSignup] load',
    LOAD_SUCCESS = '[SelfSignup] load success',
    LOAD_FAILED = '[SelfSignup] load failed',

    GET_USER_CUSTOMERS = '[SelfSignup] get user Customers',
    GET_USER_CUSTOMERS_SUCCESS = '[SelfSignup] get user Customers success',
    GET_USER_CUSTOMERS_FAILED = '[SelfSignup] get user Customers failed',

    CREATE = '[SelfSignup] create',
    CREATE_SUCCESS = '[SelfSignup] create success',
    CREATE_FAILED = '[SelfSignup] create failed',

    STEP_REGISTER = '[SelfSignup] activate step register',
    STEP_SERVICE = '[SelfSignup] activate step service',
    STEP_PAYMENT = '[SelfSignup] activate step payment',
    STEP_COMPLETION = '[SelfSignup] activate step completion',

    ERROR = '[SelfSignup] error',
    RESET = '[SelfSignup] reset',
}

export class Load implements Action {
    readonly type = ActionTypes.LOAD
}
export class LoadSuccess implements Action {
    readonly type = ActionTypes.LOAD_SUCCESS;
    constructor(readonly payload: SelfSignupPackages[]) {}
}
export class LoadFailed implements Action {
    readonly type = ActionTypes.LOAD_FAILED;
}

export class GetUserCustomers implements Action {
    readonly type = ActionTypes.GET_USER_CUSTOMERS;
    constructor(readonly email: string) { }
}
export class GetUserCustomersSuccess implements Action {
    readonly type = ActionTypes.GET_USER_CUSTOMERS_SUCCESS;
    constructor(readonly payload: IUserCustomersResponse[]) {}
}
export class GetUserCustomersFailed implements Action {
    readonly type = ActionTypes.GET_USER_CUSTOMERS_FAILED;
}

export class Create implements Action {
    readonly type = ActionTypes.CREATE;
    constructor(readonly payload: {
        customers?: ISelfSignupRequestStoreCustomerParams,
        account?: ISelfSignupRequestStoreAccountParams,
        payment?: ISelfSignupRequestStorePaymentParams,
        customer_number: string,
        uid?: number
    }) { }
}
export class CreateSuccess implements Action {
    readonly type = ActionTypes.CREATE_SUCCESS;
}
export class CreateFailed implements Action {
    readonly type = ActionTypes.CREATE_FAILED;
}

export class StepRegister implements Action {
    readonly type = ActionTypes.STEP_REGISTER;
}
export class StepService implements Action {
    readonly type = ActionTypes.STEP_SERVICE;
}
export class StepPayment implements Action {
    readonly type = ActionTypes.STEP_PAYMENT;
}
export class StepCompletion implements Action {
    readonly type = ActionTypes.STEP_COMPLETION;
}

export class Error implements Action {
    readonly type = ActionTypes.ERROR;
    constructor(readonly payload: NGRXError<Actions>) { }
}

export class Reset implements Action {
    readonly type = ActionTypes.RESET;
}


export type Actions =
    | Load
    | LoadSuccess
    | LoadFailed
    | GetUserCustomers
    | GetUserCustomersSuccess
    | GetUserCustomersFailed
    | Create
    | CreateSuccess
    | CreateFailed
    | StepRegister
    | StepService
    | StepPayment
    | StepCompletion
    | Error
    | Reset
    ;