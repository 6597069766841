import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { AuthenticationModule } from '@appRoot/core/authentication/authentication.module';
import { ErrorCatcherModule } from '../../error-catcher/error-catcher.module';
import { SharedModule } from '../../shared/shared.module';
import { LoginPageComponent } from './login/login.component';
import { SelfSignupComponent } from './self-signup/self-signup.component';
import { RegisterPageComponent } from './register/register.component';
import { VerifyPageComponent } from './verify/verify.component';
import { RecoverPageComponent } from './recover/recover.component';
import { PasswordResetPageComponent } from './password-reset/password-reset.component';
import { LockComponent } from './lock/lock.component';
import { MaintenanceComponent } from './maintenance/maintenance.component';
import { Error404Component } from './error404/error404.component';
import { Error500Component } from './error500/error500.component';
import { SelfSignupModule } from "@appRoot/lazy-modules/self-signup/self-signup.module";

/* Use this routes definition in case you want to make them lazy-loaded */
/*const routes: Routes = [
    { path: 'login', component: LoginComponent },
    { path: 'register', component: RegisterComponent },
    { path: 'recover', component: RecoverComponent },
    { path: 'lock', component: LockComponent },
    { path: 'maintenance', component: MaintenanceComponent },
    { path: '404', component: Error404Component },
    { path: '500', component: Error500Component },
];*/

@NgModule({
    imports: [
        SharedModule,
        AuthenticationModule,
        ErrorCatcherModule,
        SelfSignupModule,
        // RouterModule.forChild(routes)
    ],
    declarations: [
        LoginPageComponent,
        SelfSignupComponent,
        RegisterPageComponent,
        VerifyPageComponent,
        RecoverPageComponent,
        PasswordResetPageComponent,
        LockComponent,
        MaintenanceComponent,
        Error404Component,
        Error500Component
    ],
    exports: [
        RouterModule,
        LoginPageComponent,
        SelfSignupComponent,
        RegisterPageComponent,
        VerifyPageComponent,
        RecoverPageComponent,
        PasswordResetPageComponent,
        LockComponent,
        MaintenanceComponent,
        Error404Component,
        Error500Component
    ]
})
export class PagesModule { }
