import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { SettingsService } from '@appRoot/core/services/settings.service';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import {
    IMailingCreateParams,
    IMailingDestroyParams,
    IMailingIndexParams,
    IMailingMailablesParams,
    IMailingShowParams,
    IMailingUpdateParams,
    IMailProvider,
    IMailTemplateResponse,
    MailTemplate
} from '../models';


@Injectable({
    providedIn: 'root'
})
export class MailingHttpService {

    public readonly edge: string = 'mailing';

    constructor(
        private http: HttpClient,
        private settings: SettingsService,
    ) { }

    fetch(params: IMailingIndexParams): Observable<MailTemplate[]> {
        let _params = new HttpParams({ fromObject: <any>{ ...params } });

        return this.http.get<{ data: IMailTemplateResponse[] }>(`${this.settings.API_PATH}/${this.edge}`, { params: _params }).pipe(
            map(({ data }) => data.map(e => new MailTemplate(e))),
        );
    }

    create(params: IMailingCreateParams): Observable<MailTemplate> {
        return this.http.post<{ data: IMailTemplateResponse }>(`${this.settings.API_PATH}/${this.edge}`, params).pipe(
            map(({ data }) => new MailTemplate(data))
        );
    }

    show(id: number, params: IMailingShowParams): Observable<MailTemplate> {
        let _params = new HttpParams({ fromObject: <any>{ ...params } });

        return this.http.get<{ data: IMailTemplateResponse }>(`${this.settings.API_PATH}/${this.edge}/${id}`, { params: _params }).pipe(
            map(({ data }) => new MailTemplate(data)),
        );
    }

    update(id: number, params: IMailingUpdateParams): Observable<MailTemplate> {
        return this.http.patch<{ data: IMailTemplateResponse }>(`${this.settings.API_PATH}/${this.edge}/${id}`, params).pipe(
            map(({ data }) => new MailTemplate(data)),
        );
    }

    destroy(id: number, params: IMailingDestroyParams): Observable<boolean> {
        let _params = new HttpParams({ fromObject: <any>{ ...params } });

        return this.http.delete<{ data: boolean }>(`${this.settings.API_PATH}/${this.edge}/${id}`, { params: _params }).pipe(
            map(({ data }) => data),
        );
    }

    getProviders(params: IMailingMailablesParams): Observable<Array<IMailProvider>> {
        let _params = new HttpParams({ fromObject: <any>{ ...params } });

        return this.http.get<{ data: Array<IMailProvider> }>(`${this.settings.API_PATH}/${this.edge}/providers`, { params: _params }).pipe(
            map(({ data }) => data),
        );
    }

}
