import { ICustomerMailTemplateResponse } from '@appRoot/core/customer/models/ICustomerMailTemplatesRequestParams';


export class CustomerMailTemplate {
    id: number;
    subject: string;
    html_template: string;
    text_template: string;
    status: boolean;
    created_at: number;
    updated_at: number;

    constructor(data?: ICustomerMailTemplateResponse){
        if (data) {
            this.id = data.id;
            this.subject = data.subject;
            this.html_template = data.html_template;
            this.text_template = data.text_template;
            this.status = data.status;
            this.created_at = data.created_at;
            this.updated_at = data.updated_at;
        }
    }
}