import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input } from '@angular/core';
import { AbstractControl, FormBuilder, FormControl, Validators } from '@angular/forms';
import { CustomValidators } from 'ngx-custom-validators';
import { merge } from 'rxjs';
import { filter, skip, takeUntil } from 'rxjs/operators';

import { BasicForm } from '@appRoot/core/forms/form-basic.form';
import { ValidatorService } from '@appRoot/core/services/validator.service';
import { ErrorCatcherService } from '@appRoot/error-catcher/error-catcher.service';
import { TranslateService } from '@ngx-translate/core';
import { AuthenticationService } from '../../services/authentication.service';
import * as regAction from '../../ngrx-store/actions/registration.actions';


@Component({
    selector: 'app-register',
    templateUrl: './register.component.html',
    styleUrls: ['./register.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RegisterComponent extends BasicForm {

    public errorMessage = null;
    public errorMessages = null;
    public errorShow = false;
    public validationMessages: Map<AbstractControl, {[key:string]: string}>;
    public registrationComplete: boolean | null;
    public confirmTokenSent: boolean = false;

    @Input('email') sicklyEmail: string;
    @Input('token') token: string;

    constructor(
        private errorCatcherService: ErrorCatcherService,
        private fb: FormBuilder,
        private validatorService: ValidatorService,
        private translate: TranslateService,
        private authService: AuthenticationService,
        private cdr: ChangeDetectorRef,
        ) {
        super();
    }

    ngOnInit(){
        super.ngOnInit();
        this.rebuildForm();

        this.setValidationMsg();
        this.errorSubscriber();
        this.loadingSubscriber();
        this.registrationCompleteSubscriber();
        this.confirmationSentSubscriber();
        this.email.statusChanges.pipe(takeUntil(this.ngUnsubscribe)).subscribe(e => {
            this.cdr.detectChanges();
        });
        if(this.sicklyEmail){
            this.email.setValue(this.sicklyEmail);
            this.email.disable();
        }
    }

    ngOnDestroy(): void {
        super.ngOnDestroy();
    }

    get name() { return this.form.get('name'); }
    get email() { return this.form.get('email'); }
    get password() { return this.form.get('passwordGroup.password') }
    get confirmPassword() { return this.form.get('passwordGroup.confirmPassword') }
    get accountagreed() { return this.form.get('accountagreed'); }

    createForm(){

        let password = new FormControl('', [ Validators.required, Validators.minLength(6) ]);
        let confirmPassword = new FormControl('', [Validators.required, CustomValidators.equalTo(password)]);
        let name = new FormControl(null, [Validators.required, CustomValidators.rangeLength([3,50]), ValidatorService.usernamePattern]);

        let passwordForm = this.fb.group({
            password: password,
            confirmPassword: confirmPassword
        });

        this.form = this.fb.group({
            name: name,
            email: [null, [Validators.required, CustomValidators.email], this.validatorService.checkEmailNotTaken(3)],
            accountagreed: [null, Validators.required],
            passwordGroup: passwordForm
        });
    }

    rebuildForm(): void {
        this.authService.dispatchRegistrationCompleteReset();
        this.authService.dispatchConfirmationReset();
    }

    onSubmit(): void {
        super.onSubmit();

        if(this.form.valid) {
            this.errorShow = false;
            this.registration();
        }
    }

    private registration(){
        let dataToRegister = {
            email: this.email.value,
            name: this.name.value,
            password: this.password.value,
            password_confirmation: this.confirmPassword.value,
            token: this.token? this.token : null,
        };

        this.authService.dispatchRegistration(dataToRegister);
    }

    private errorSubscriber(){
        this.authService.getRegistrationError$()
        .pipe(takeUntil(this.ngUnsubscribe), skip(1), filter(e => e !== null)).subscribe(error => {
            if(error.action instanceof regAction.Registration && this.errorCatcherService.has(error.instance)){
                let cError = this.errorCatcherService.get(error.instance);
                this.errorMessage = cError.message;
                this.errorMessages = cError.errors ? cError.errors: null;
                this.errorShow = true;

                if(cError.errors){
                    Object.keys( cError.errors ).forEach( (field) => {
                        if(field === 'password'){
                            this.password.setErrors({ 'invalid': true });
                            this.confirmPassword.setErrors({ 'invalid': true });
                            return;
                        }
                        if(this.form.get(field)) {
                            this.form.get(field).setErrors({ 'invalid': true });
                        }
                    });
                }

                this.form.setErrors({ 'invalid': true });
                this.cdr.detectChanges();
            }
        });
    }

    private loadingSubscriber() {
        merge(this.authService.getRegistrationLoading$(), this.authService.getConfirmationSending$())
        .pipe(takeUntil(this.ngUnsubscribe)).subscribe(e => {
            this.loading = e;
            this.cdr.detectChanges();
        });
    }

    private registrationCompleteSubscriber() {
        this.authService.getRegistrationComplete$().pipe(takeUntil(this.ngUnsubscribe)).subscribe(e => {
            this.registrationComplete = e;
            this.cdr.detectChanges();
        });
    }

    private confirmationSentSubscriber() {
        this.authService.getConfirmationSent$().pipe(takeUntil(this.ngUnsubscribe)).subscribe(e => {
            this.confirmTokenSent = e;
            this.cdr.detectChanges();
        });
    }

    private setValidationMsg() {
        this.validationMessages = new Map([
            [this.name, {
                'required': 'Username is required.',
                'rangeLength': 'Username must be between 3 to 50 characters long',
                'usernamePattern': 'Username contains an illegal character',
            }],
            [this.email, {
                'required': 'Email is required',
                'email': 'Email must be a valid email address.',
                "emailIsTaken": 'Email address has already been taken.',
            }],
            [this.password, {
                'required': 'Password is required',
                'minLength': 'The field must be at least "6" characters long.',
            }],
            [this.confirmPassword, {
                'required': 'Confirm password is required',
                'equalTo': 'Password does not match',
            }],
            [this.accountagreed, {
                'required': 'You must agree the terms',
            }],
        ]);
    }

}
