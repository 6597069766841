import { ErrorCatcherMessageService } from "./message/error-catcher-message.service";
import { ErrorResponse, ListMessages } from "./error-catcher.types";
import { isObject, isString } from 'util';


export class ErrorCatcherNotice {

	public success: boolean;
	public message: string = null;
	public errors: ListMessages = {};
	public code: number = null;

	constructor( data: ErrorResponse, public messageService: ErrorCatcherMessageService ){
    this.parse(data);
    this.messageService.setType('danger');
	}

	parse(data: ErrorResponse){
		try {
		    if(isObject(data)) {
                this.success = data.success;
                if(data.error && data.error.hasOwnProperty('message')) this.message = data.error.message;
                if(data.error && data.error.hasOwnProperty('code')) this.code = data.error.code;
                if('errors' in data) {
                    this.errors = data.errors;
                }
            }
		    if(isString(data)){
                this.message = data;
            }
		} catch(error){
			console.error( error );
		}

	}

	show(): ErrorCatcherMessageService{
		this.messageService.setMessage(this.message);
		this.messageService.setMessages(this.errors);
		this.messageService.show();
		return this.messageService;
	}

	hide(): ErrorCatcherMessageService{
		this.messageService.clear();
		this.messageService.hide();
		return this.messageService;
	}

	showError(): ErrorCatcherMessageService{
		this.messageService.setMessage(this.message);
		this.messageService.show();
		return this.messageService;
	}

	showErrors(): ErrorCatcherMessageService{
		this.messageService.setMessages(this.errors);
		this.messageService.show();
		return this.messageService;
	}



}

