import { Action } from '@ngrx/store';

export enum ActionTypes {
	GET_LANG = '[translator] get',
	SET_LANG = '[translator] set',
}

export class GetLanguage implements Action {
    readonly type = ActionTypes.GET_LANG;
}

export class SetLanguage implements Action {
    readonly type = ActionTypes.SET_LANG;
    constructor(readonly payload: string) { }
}

export type Actions =
    | GetLanguage
    | SetLanguage
    ;