import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { NgUnsubscribe } from '@appRoot/core/interfaces';
import { Customer } from "@appRoot/core/customer/models";
import * as customerSelectors from '@appRoot/core/customer/ngrx-store/selectors/customer.selectors';
import { CustomerInfoService } from '@appRoot/shared/components/customer-info/customer-info.service';
import { select, Store } from '@ngrx/store';
import { BehaviorSubject, Subject } from 'rxjs';
import { filter, switchMap, takeUntil, tap } from 'rxjs/operators';


@Component({
    selector: 'app-customer-render-info',
    templateUrl: './customer-render-info.component.html',
    styleUrls: ['./customer-render-info.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class CustomerRenderInfoComponent implements OnInit, NgUnsubscribe {

    readonly ngUnsubscribe = new Subject<any>();
    private customerId$ = new BehaviorSubject<number>(null);

    private _customer: Customer;
    get customer() {
        return this._customer;
    }
    set customer(val: Customer) {
        this._customer = val;
        this.cdRef.detectChanges();
    }

    @Input()
    set customerId(customerId: number) {
        this.customerId$.next(customerId);
    };

    @Input() showTooltip = true;

    private _fields: Array<keyof Customer | string> = ['name'];
    @Input() set fields(val: Array<keyof Customer | string>){
        this._fields = val;
        this.cdRef.detectChanges();
    }

    constructor(
        private store: Store<any>,
        private cdRef: ChangeDetectorRef,
        private customerInfoService: CustomerInfoService
    ) {}

    ngOnInit(): void {
        this.loadCustomer();
        this.getCustomer();
    }

    ngOnDestroy(): void {
        this.unsubscribe();
    }

    unsubscribe(): void {
        this.ngUnsubscribe.next();
        this.ngUnsubscribe.complete();
    }

    getCustomerFields() {
        let customerFields = [];
        if (this.customer) {
            customerFields = this._fields.map(field => {
                return this.formatField(field);
            });
        }
        return customerFields.join(' ');
    }

    private loadCustomer() {
        this.customerId$.pipe(
            filter(e => !!e),
            takeUntil(this.ngUnsubscribe),
        ).subscribe(id => this.customerInfoService.loadCustomer(id));
    }

    private getCustomer() {
        this.customerId$.pipe(
            tap(id => this.customer = null),
            switchMap(customerId => this.store.pipe(select(customerSelectors.getCustomerById(customerId)))),
            takeUntil(this.ngUnsubscribe),
        ).subscribe((customer: Customer) => {
            this.customer = customer;
        });
    }

    private formatField(field: string): string {
        let keys = field.split('/');
        for (let key of keys) {
            if (this.customer[key]) {
                return this.customer[key];
            }
        }
        return '';
    }

}
