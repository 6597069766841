import { HttpHandler, HttpInterceptor, HttpRequest, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { tap } from 'rxjs/operators';
import { AddMetadata } from '@appRoot/lazy-modules/tac-services/ngrx-store/actions/service-metadata.actions';


@Injectable()
export class ServiceMetadataInterceptor implements HttpInterceptor {

    constructor(
        private store: Store<any>,
    ) {}

    intercept(req: HttpRequest<any>, next: HttpHandler) {
        return next.handle(req).pipe(
            tap(response => {
                if(response instanceof HttpResponse
                    && response.body.success === true
                    && response.body['meta-data']
                    && Array.isArray(response.body['meta-data']['services_info'])
                    && response.body['meta-data']['services_info'].length
                ){
                    let metadata = response.body['meta-data'];
                    this.store.dispatch( new AddMetadata(metadata) )
                }
            })
        );
    }
}
