import { createSelector } from '@ngrx/store';
import { ModuleStore } from '../reducers';
import { adapter, selectId } from '../reducers/notification.reducer';


export const getState = createSelector(
    ModuleStore,
    (store) => store.notification
);

export let getLoaded = createSelector(
    getState,
    (state) => state.loaded
);

export const getLoading = createSelector(
    getState,
    (state) => state.loading
);

export const getUpdating = createSelector(
    getState,
    (state) => state.updating
);

const entitySelectors = adapter.getSelectors();

export const getNotificationsArray = createSelector(
    getState,
    entitySelectors.selectAll
);

export const getNotificationsDictionary = createSelector(
    getState,
    entitySelectors.selectEntities
);

export const getNotificationByMailable = (mailable: string) => createSelector(
    getNotificationsArray,
    (notification) => notification.find((n) => n.mail_key === mailable)
);

export const getNotification = (id: ReturnType<typeof selectId>) => createSelector(
    getNotificationsDictionary,
    (notification) => notification[id]
);

export const getNotificationSettings = (id: ReturnType<typeof selectId>) => createSelector(
    getNotification(id),
    (notification) => notification ? notification.settings : null
);

