import { Actions, ActionTypes } from '../actions/translator.actions';

export interface State {
  	lang: string;
}

export const initialState: State = {
	lang: 'en_US'
};


export function reducer(state = initialState, action: Actions): State {
	switch (action.type) {
		case ActionTypes.GET_LANG:
			return {
				...state,
			};
		case ActionTypes.SET_LANG:
			return {
				...state,
				lang: action.payload
			};

		default:
			return state;
	}
}