import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { ROUTER_NAVIGATED, RouterNavigatedAction } from '@ngrx/router-store';
import { Action, select, Store } from '@ngrx/store';

import { Observable } from 'rxjs';
import { map, tap, withLatestFrom } from 'rxjs/operators';
import * as navigationActions from '../actions/navigation.actions';
import { State } from '../reducers/navigation.reducer';
import * as navigationSelectors from '../selectors/navigation.selectors';

@Injectable()
export class NavigationEffects {

	constructor(
		private actions$: Actions, 
		private router: Router, 
		private store: Store<State>, 
		) {}

	@Effect()
	navigated$: Observable<Action> = this.actions$.pipe(
		ofType(ROUTER_NAVIGATED),
		map((action: RouterNavigatedAction) => {
			let urlAfterRedirects = action.payload.event.urlAfterRedirects ? action.payload.event.urlAfterRedirects : action.payload.event.url;
			return new navigationActions.Add({url: urlAfterRedirects})
		})
	);

	@Effect({ dispatch: false })
	back$forward$ = this.actions$.pipe(
		ofType(navigationActions.ActionTypes.BACK, navigationActions.ActionTypes.FORWARD),
		withLatestFrom(this.store.pipe(select(navigationSelectors.getCurrentUrl))),
		tap(([action, url]) => {
			if(url && !this.router.isActive(url, true)){
				this.router.navigateByUrl(url);
			}
		}),
	);

	@Effect({dispatch: false})
	up$ = this.actions$.pipe(
		ofType(navigationActions.ActionTypes.UP),
		tap(action => {
			let route = this.router.routerState.root;
			while(route.firstChild){
				route = route.firstChild;
			}
			if(route.parent){
				this.router.navigate(['.'], {relativeTo: route.parent})
			}
		}),
	);

	@Effect({ dispatch: false })
	navigate$ = this.actions$.pipe(
		ofType(navigationActions.ActionTypes.GO),
		map((action: navigationActions.Go) => action.payload),
		tap(({ path, query: queryParams, extras }) => {
			this.router.navigate(path, { queryParams, ...extras });
		})
	);

}