import { ChangeDetectionStrategy, ChangeDetectorRef, Component } from '@angular/core';
import { AbstractControl, FormControl, FormGroup, Validators } from '@angular/forms';
import { CustomValidators } from 'ngx-custom-validators';
import { filter, skip, takeUntil } from 'rxjs/operators';
import { ErrorCatcherService } from '@appRoot/error-catcher/error-catcher.service';

import { BasicForm } from '@appRoot/core/forms/form-basic.form';
import { AuthenticationService } from '../../services/authentication.service';
import * as confAction from '../../ngrx-store/actions/confirmation.actions';


@Component({
	selector: 'app-confirm-form',
	templateUrl: './confirm-form.component.html',
	styleUrls: ['./confirm-form.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ConfirmFormComponent extends BasicForm {

	public sent: boolean = false;
	public errorShow: boolean = false;
	public errorMessage: string = null;
	public errorMessages = null;
	public validationMessages: Map<AbstractControl, {[key:string]: string}>;

	constructor(
		private authService: AuthenticationService,
		private errorCatcherService: ErrorCatcherService,
		private cdr: ChangeDetectorRef,
		) {
		super();
	}

	ngOnInit() {
		super.ngOnInit();
		this.loadingSubscriber();
		this.errorSubscriber();
		this.confirmationSentSubscriber();

		this.validationMessages = new Map([
            [this.email, {
                'required': 'Email is required',
                'email': 'Email must be a valid email address.',
            }],
        ]);
	}

    ngOnDestroy(): void {
        super.ngOnDestroy();
    }

	createForm(){  	
		this.form = new FormGroup({
			email: new FormControl(null, [Validators.required, CustomValidators.email])
		});
	}

	get email() { return this.form.get('email'); }

	onSubmit(): void {
        super.onSubmit();

        if(this.form.valid) {
        	this.errorShow = false;
        	this.authService.dispatchConfirmationSend({email: this.email.value})
        }
    }

    private loadingSubscriber() {
        this.authService.getConfirmationSending$().pipe(takeUntil(this.ngUnsubscribe)).subscribe(loading => {
            this.loading = loading;
            this.cdr.detectChanges();
        });
    }

    private confirmationSentSubscriber() {
        this.authService.getConfirmationSent$().pipe(takeUntil(this.ngUnsubscribe)).subscribe(e => {
            this.sent = e;
            this.cdr.detectChanges();
        });
    }

    private errorSubscriber(){
        this.authService.getConfirmationError$()
        .pipe(takeUntil(this.ngUnsubscribe), skip(1), filter(e => e !== null)).subscribe(error => {
            if(error.action instanceof confAction.Send && this.errorCatcherService.has(error.instance)){
                let cError = this.errorCatcherService.get(error.instance);
                this.errorMessage = cError.message;
                this.errorMessages = cError.errors ? cError.errors: null;
                this.errorShow = true;
                this.form.setErrors({ 'invalid': true });
                this.cdr.detectChanges();
            }
        });
    }



}
