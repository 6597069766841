import { createSelector } from '@ngrx/store';
import { isNil } from 'lodash';
import { Customer } from '../../models';
import { ModuleStore } from '../reducers';
import * as customerStorageReducer from '../reducers/customer-storage.reducer';


export const getCustomerState = createSelector(
    ModuleStore,
    (store) => store.customer
);
export const getCustomerStore = createSelector(
    ModuleStore,
    (store) => store.customer_storage
);

const customerEntitySelectors = customerStorageReducer.adapter.getSelectors();

export const getCustomerArray = createSelector(
    getCustomerStore,
    customerEntitySelectors.selectAll
);

export const getCustomerDictionary = createSelector(
    getCustomerStore,
    customerEntitySelectors.selectEntities
);

export const getSessionCustomerId = createSelector(
    getCustomerState,
    (state) => state.customer
);

export const getActiveCustomerId = createSelector(
    getCustomerState,
    (state) => state.activeCustomer
);

export const getActiveCustomer = createSelector(
    getCustomerDictionary,
    getActiveCustomerId,
    (entities, id) => entities[id]
);

export const getSessionCustomer = createSelector(
    getCustomerDictionary,
    getSessionCustomerId,
    (entities, id) => entities[id]
);

export const getError = createSelector(
    getCustomerState,
    (state) => state.error
);

export const getLoading = createSelector(
    getCustomerState,
    (state) => state.loading
);
export const getComplete = createSelector(
    getCustomerState,
    (state) => state.complete
);
export const getGettingCurrent = createSelector(
    getCustomerState,
    (state) => state.getting_current
);
export const getCreating = createSelector(
    getCustomerState,
    (state) => state.creating
);
export const getGetting = createSelector(
    getCustomerState,
    (state) => state.getting
);
export const getUpdating = createSelector(
    getCustomerState,
    (state) => state.updating
);
export const getDeleting = createSelector(
    getCustomerState,
    (state) => state.deleting
);
export const getRestoring = createSelector(
    getCustomerState,
    (state) => state.restoring
);
export const getPagination = createSelector(
    getCustomerState,
    (state) => state.pagination
);

export const getCustomerById = (customerId: number) => {
    return createSelector(
        getCustomerDictionary,
        (entities) => entities[customerId]
    );
};

export const findCustomersBy = <T extends Customer, K extends keyof Customer>(key: K, value: T[K]) => {
    return createSelector(
        getCustomerArray,
        (entities) => entities.filter(user =>
            user.hasOwnProperty(key) &&
            (user[key] === value || (!isNil(user[key]) && (user[key]).toString().toLowerCase().includes((value + '').toLowerCase())))
        )
    );
};

export const getCustomersByIds = (customerIds: number[]) => {
    return createSelector(
        getCustomerDictionary,
        (dictionary) => customerIds.reduce((acc, key) => dictionary[key] ? [...acc, dictionary[key]] : acc, [])
    );
};

export const getUserAdding = createSelector(
    getCustomerState,
    (state) => state.user_adding
);

export const getUserRemoving = createSelector(
    getCustomerState,
    (state) => state.user_removing
);

export const getChangingUserRole = createSelector(
    getCustomerState,
    (state) => state.changing_user_role
);

export const getResendingNewUserMail= createSelector(
    getCustomerState,
    (state) => state.new_user_mail_resending
);

export const getDkImporting = createSelector(
    getCustomerState,
    (state) => state.dk_importing
);

export const getStateEmailLogs = createSelector(
    getCustomerState,
    (state) => state.email_logs
);

export const getEmailLogsResending = createSelector(
    getCustomerState,
    (state) => state.email_logs_resending
);

export const getStateSubscription = createSelector(
    getCustomerState,
    (state) => state.subscription
);

export const getStateInvoice = createSelector(
    getCustomerState,
    (state) => state.invoice
);

export const getStateInvoicePdf = createSelector(
    getCustomerState,
    (state) => state.invoice_pdf
);

export const getStateInvoiceHtml = createSelector(
    getCustomerState,
    (state) => state.invoice_html
);

export const getStateInvoiceEmail = createSelector(
    getCustomerState,
    (state) => state.invoice_email
);

export const getStateTimeTracking = createSelector(
    getCustomerState,
    (state) => state.timeTracking
);

export const getStatePaymentMode = createSelector(
    getCustomerState,
    (state) => state.paymentMode
);

export const getCreateExpense = createSelector(
    getCustomerState,
    (state) => state.create_expense
);

export const getSubscriptionShowTax = createSelector(
    getCustomerState,
    (state) => state.subscription_show_tax
);

export const getEmailLogsByCustomer = (customer: Customer) => {
    return createSelector(
        getCustomerArray,
        (entities) => {
            return entities.find(e => e.id === customer.id).emailLogs;
        }
    );
};

export const getSubscriptionByUser = (customer: Customer) => {
    return createSelector(
        getCustomerArray,
        (entities) => {
            return entities.find(e => e.id === customer.id).subscription;
        }
    );
};

export const getInvoiceByCustomer = (customer: Customer) => {
    return createSelector(
        getCustomerArray,
        (entities) => {
            return entities.find(e => e.id === customer.id).invoice;
        }
    );
};

export const getOpenExpensesByCustomer = (customer: Customer) => {
    return createSelector(
        getCustomerArray,
        (entities) => {
            return entities.find(e => e.id === customer.id).openExpenses;
        }
    );
};

export const getPaymentModeByCustomer = (customer: Customer) => {
    return createSelector(
        getCustomerArray,
        (entities) => {
            return entities.find(e => e.id === customer.id).paymentMode;
        }
    );
};

export const getTimeTrackingByCustomer = (customer: Customer) => {
    return createSelector(
        getCustomerArray,
        (entities) => {
            return entities.find(e => e.id === customer.id).timeTracking;
        }
    );
};