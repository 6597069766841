import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { State } from '@appRoot/core/ngrx-store/reducers/navigation.reducer';
import { Back, Forward, Up } from '@appRoot/core/ngrx-store/actions/navigation.actions';
import { Reload } from '@appRoot/core/ngrx-store/actions/core.actions';


export type navigationBtnType = 'up' | 'back' | 'forward' | 'reload' | '';

@Component({
    selector: 'app-navigation-btn',
    templateUrl: './navigation-btn.component.html',
    styleUrls: ['./navigation-btn.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    inputs: ['type', 'btnClass', 'additionalClass', 'showTooltip'],
})
export class NavigationBtnComponent implements OnInit {

    public type: navigationBtnType = 'up';
    public btnClass = 'btn btn-secondary';
    public additionalClass = '';
    public showTooltip = true;
    public tooltip = '';

    constructor(
        private store: Store<State>,
    ) { }

    ngOnInit() {
        switch (this.type) {
            case "up":
                this.onClick = this.up;
                this.tooltip = 'Upper level';
                break;

            case "back":
                this.onClick = this.back;
                this.tooltip = 'Back';
                break;

            case "forward":
                this.onClick = this.forward;
                this.tooltip = 'Forward';
                break;

            case "reload":
                this.onClick = this.reload;
                this.tooltip = 'Reload';
                break;

            default:
                this.onClick = this.up;
                this.tooltip = 'Upper level';
                break;
        }
    }

    onClick() {}

    back() {
        this.store.dispatch(new Back);
    }

    up() {
        this.store.dispatch(new Up);
    }

    forward() {
        this.store.dispatch(new Forward);
    }

    reload() {
        this.store.dispatch(new Reload);
    }

}
