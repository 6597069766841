import { Injectable } from '@angular/core';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { Action } from '@ngrx/store';
import { Observable } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';
import { NGRXError } from '../../../ngrx-store/models/NGRXError';
import { AuthenticationHttpService } from '../../services/authentication-http.service';
import * as resetActions from '../actions/reset-password.actions';


@Injectable()
export class ResetPasswordEffects {

	constructor(
		private actions$: Actions,
		private authHttpService: AuthenticationHttpService,
	) {}

	@Effect()
	create$: Observable<Action> = this.actions$.pipe(
		ofType(resetActions.ActionTypes.CREATE),
		switchMap((action: resetActions.Create) =>
			this.authHttpService.resetPasswordCreate(action.payload.email)
			.pipe(
				map(response => new resetActions.CreateSuccess()),
				catchError(error => [
					new resetActions.Error(new NGRXError(action, error))
				]),
			),
		),
	);

	@Effect()
	check$: Observable<Action> = this.actions$.pipe(
		ofType(resetActions.ActionTypes.CHECK),
		switchMap((action: resetActions.Check) =>
			this.authHttpService.resetPasswordCheck(action.payload.token)
			.pipe(
				map(response => new resetActions.CheckSuccess()),
				catchError(error => [
					new resetActions.Error(new NGRXError(action, error))
				]),
			),
		),
	);

	@Effect()
	reset$: Observable<Action> = this.actions$.pipe(
		ofType(resetActions.ActionTypes.RESET_PASSWORD),
		switchMap((action: resetActions.ResetPassword) =>
			this.authHttpService.resetPassword(action.payload)
			.pipe(
				map(response => new resetActions.ResetPasswordSuccess()),
				catchError(error => [
					new resetActions.Error(new NGRXError(action, error))
				]),
			),
		),
	);

}