import { Actions, ActionTypes } from '../actions/customer-groups-change-notifications.actions';
import { NGRXError } from '@appRoot/core/ngrx-store/models/NGRXError';

export interface State {
    loading: boolean;
    loaded: boolean;
    updating: boolean;
    error: NGRXError<Actions>;
}

export const initialState: State = {
    loading: false,
    loaded: false,
    updating: false,
    error: null,
};



export function reducer(state = initialState, action: Actions): State {
    switch (action.type) {

        case ActionTypes.GET:
            return {
                ...state,
                loading: true,
            };
        case ActionTypes.GET_SUCCESS:
            return {
                ...state,
                loading: false,
                loaded: true,
            };
        case ActionTypes.GET_FAILED:
            return {
                ...state,
                loading: false,
                loaded: false,
            };

        case ActionTypes.ERROR:
            return {
                ...state,
                loading: false,
                error: action.payload,
            };

        case ActionTypes.UPDATE:
            return {
                ...state,
                updating: true,
            };
        case ActionTypes.UPDATE_SUCCESS:
        case ActionTypes.UPDATE_FAILED:
            return {
                ...state,
                updating: false,
            };

        default:
            return state;
    }
}
