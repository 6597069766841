import { NGRXError } from '@appRoot/core/ngrx-store/models/NGRXError';
import { createEntityAdapter, EntityAdapter, EntityState, } from '@ngrx/entity';
import { IPermissionGroup } from '../../models';
import { Actions, ActionTypes } from '../actions/permission-group.actions';

function selectId(entity: IPermissionGroup): number {
    //In this case this would be optional since primary key is id
    return entity.id;
}

export const adapter: EntityAdapter<IPermissionGroup> = createEntityAdapter({
    selectId: selectId,
    sortComparer: false,
});

export interface State extends EntityState<IPermissionGroup> {
    loaded: boolean;
    loading: boolean;
    error: NGRXError<Actions>;
}

export const initialState: State = adapter.getInitialState({
    loaded: false,
    loading: false,
    error: null,
});

export const PermissionEntitySelectors = adapter.getSelectors();

export function reducer(state = initialState, action: Actions): State {
    switch (action.type) {
        case ActionTypes.LOAD:
            return {
                ...state,
                loading: true,
                loaded: false,
            };

        case ActionTypes.LOAD_SUCCESS:
            return adapter.upsertMany(
                action.payload.permissionGroups,
                {
                    ...state,
                    loading: false,
                    loaded: true,
                }
            );

        case ActionTypes.LOAD_FAILED:
            return {
                ...state,
                loading: false,
                loaded: false,
            };

        case ActionTypes.CREATE:
        case ActionTypes.REMOVE:
        case ActionTypes.UPDATE:
            return {
                ...state,
                loading: true,
            };

        case ActionTypes.REMOVE_SUCCESS:
            return adapter.removeOne(
                selectId(action.payload.permissionGroup),
                {
                    ...state,
                    loading: false,
                }
            );

        case ActionTypes.CREATE_SUCCESS:
            return adapter.addOne(
                action.payload.permissionGroup,
                {
                    ...state,
                    loading: false,
                }
            );

        case ActionTypes.UPDATE_SUCCESS:
            return adapter.upsertOne(
                action.payload.permissionGroup,
                {
                    ...state,
                    loading: false,
                }
            );

        case ActionTypes.ERROR:
            return {
                ...state,
                loading: false,
                error: action.payload,
            };

        case ActionTypes.RESET:
            return {
                ...initialState
            };

        default:
            return state;
    }

}
