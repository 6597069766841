import { Location } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { SettingsService } from '@appRoot/core/services/settings.service';
import { IUserResponse, User } from '@appRoot/core/user/models';
import { ROUTE_PATHS } from '@appRoot/routes/routes.names';

import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { AccessToken } from '../models';


@Injectable()
export class AuthenticationHttpService {

	constructor(
		private http: HttpClient,
		private settings: SettingsService,
		) { }

	login(username: string, password: string): Observable<AccessToken> {
		const postBody = {
			username: username,
			password: password,
		};

		return this.http.post<{data: AccessToken}>(this.settings.API_PATH+'/login', postBody).pipe(map(e => e.data));
	}

	logout(): Observable<boolean> {
		return this.http.post<{data: boolean}>(this.settings.API_PATH+'/logout', null).pipe(map(e => e.data));
	}

	refreshToken(): Observable<AccessToken> {
        return this.http.post<{data: AccessToken}>( this.settings.API_PATH+'/login/refresh', null ).pipe(map(e => e.data));
    }

    registration(data: {name: string; email: string; password: string; password_confirmation: string; token?: string}): Observable<User>{
		return this.http.post<{data: IUserResponse}>(this.settings.API_PATH+'/register', data).pipe(
			map(response => new User(response.data)),
		);
	}

    registrationCheckToken(token: string) {
        return this.http.get<{data: {token: string; isValid: boolean; email?: string} }>(this.settings.API_PATH+'/register/'+token).pipe(
            map(response => response.data),
        );
    }

	sendConfirmation(data: {email: string, uid?: number; user_id?: number}): Observable<User> {
		let postBody = {
			...data,
			link: Location.joinWithSlash(this.settings.app.origin, 'verify'),
		};
		return this.http.post<{data: IUserResponse}>(this.settings.API_PATH+'/verify', postBody).pipe(
			map(response => new User(response.data)),
		);
	}

	confirm(token: string): Observable<User> {
		return this.http.get<{data: IUserResponse}>(this.settings.API_PATH+'/verify/'+token).pipe(
			map(response => new User(response.data)),
		);
	}

	resetPasswordCreate(email: string): Observable<boolean> {
		const requestBody = {
			email: email,
			link: Location.joinWithSlash(this.settings.app.origin, ROUTE_PATHS.resetPassword),
		};
		return this.http.post<{data: boolean}>(this.settings.API_PATH+'/reset-password', requestBody).pipe(
			map(response => response.data),
		);
	}

	resetPasswordCheck(token: string): Observable<boolean>{
		return this.http.get<{data: boolean}>(this.settings.API_PATH+'/reset-password/'+token).pipe(
			map(response => response.data)
		);
	}

	resetPassword(data: {password: string, password_confirmation: string, token: string} ): Observable<boolean>{
		return this.http.patch<{data: boolean}>(this.settings.API_PATH+'/reset-password', data).pipe(
			map(response => response.data),
		);
	}

}
