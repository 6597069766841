import { Action } from '@ngrx/store';
import { User } from '@appRoot/core/user/models';


export enum ActionTypes {
    RESET = '[User storage] RESET',
    ADD_ONE = '[User storage] ADD_ONE',
    UPSERT_ONE = '[User storage] UPSERT_ONE',
    UPSERT_MANY = '[User storage] UPSERT_MANY',
    UPDATE_ONE = '[User storage] UPDATE_ONE',
    REMOVE_ONE = '[User storage] REMOVE_ONE',
    REMOVE_MANY = '[User storage] REMOVE_MANY'
}

export class Reset implements Action {
    readonly type = ActionTypes.RESET;
}

export class AddOne implements Action {
    readonly type = ActionTypes.ADD_ONE;

    constructor(readonly payload: User) { }
}

export class UpsertOne implements Action {
    readonly type = ActionTypes.UPSERT_ONE;

    constructor(readonly payload: User) { }
}

export class UpsertMany implements Action {
    readonly type = ActionTypes.UPSERT_MANY;

    constructor(readonly payload: User[]) { }
}

export class UpdateOne implements Action {
    readonly type = ActionTypes.UPDATE_ONE;

    constructor(readonly payload: User) { }
}

export class RemoveOne implements Action {
    readonly type = ActionTypes.REMOVE_ONE;

    constructor(readonly payload: User) { }
}

export class RemoveMany implements Action {
    readonly type = ActionTypes.REMOVE_MANY;

    constructor(readonly payload: User[]) { }
}

export type Actions =
    | Reset
    | AddOne
    | UpsertOne
    | UpsertMany
    | UpdateOne
    | RemoveOne
    | RemoveMany
    ;
