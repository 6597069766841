import { Component, OnInit, ViewChild } from '@angular/core';
import { SwalComponent } from '@sweetalert2/ngx-sweetalert2';
import { ModalService } from './modal.service';

@Component({
	selector: 'app-modal',
	template: `<swal #swalModal></swal>`,
	styleUrls: ['./modal.component.scss']
})
export class ModalComponent implements OnInit {

	@ViewChild('swalModal', {static: true}) private swalModal: SwalComponent;

	constructor(private modalService: ModalService) { }

	ngOnInit() {
		this.modalService.component = this.swalModal;
	}

}
