import { Injectable }       from '@angular/core';
import { CanActivate, CanActivateChild, CanLoad, ActivatedRouteSnapshot, RouterStateSnapshot, Route } from '@angular/router';
import { Store, select } from '@ngrx/store';

import { Observable, of } from 'rxjs';
import { catchError, map, filter, switchMap, take, withLatestFrom } from 'rxjs/operators';

import { AuthenticationService }      from '../services/authentication.service';
import * as routerSelectors  from '@appRoot/core/ngrx-store/selectors/router.selectors';


@Injectable()
export class AuthenticationGuard implements CanActivate, CanActivateChild, CanLoad {

    constructor(private authService: AuthenticationService, private store: Store<any>, ) {}

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
        return this.checkLogin();
    }

    canActivateChild(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
        return this.canActivate(route, state);
    }

    checkLogin(): Observable<boolean> {

        return this.authService.isAuthenticated$().pipe(
            take(1),
            withLatestFrom( this.store.pipe(select(routerSelectors.getRouteState)) ),
            switchMap(([isAuthenticated, routeState]) => {
                if(isAuthenticated) {
                    return of(true)
                }

                // Store the attempted routeState for redirecting
                this.authService.routeState = routeState;

                // Tries to sign in with a token
                this.authService.dispatchAttempt();

                return this.authService.getState$().pipe(
                    filter(state => state.authComplete),
                    take(1),
                    map(state => {
                        return state.isAuthenticated;
                    }),
                );
            }),
            catchError((err) => {
                console.log( err );
                return of(false);
            }),            
        );

    }

    canLoad(route: Route): Observable<boolean> {
        return this.checkLogin();
    }

}