import { FormArray, AbstractControl } from '@angular/forms';

export class FormArrayUtils {

	/**
	 * Remove the control at the given `index` in the array.
	 * 
	 * @param index Index in the array to remove the control
	 */
	static removeAt(formArray: FormArray, index: number, options: {emitEvent?: boolean} = {emitEvent: true}): void {
	 	if (formArray.controls[index]) formArray.controls[index]['_registerOnCollectionChange'](() => {});
	 	formArray.controls.splice(index, 1);
	 	if(options.emitEvent){
		 	formArray.updateValueAndValidity();
	 	}
	}

	static clear(formArray: FormArray, options: {emitEvent?: boolean} = {emitEvent: true}): void {
        if (formArray.controls.length < 1) return;
        formArray['_forEachChild']((control: AbstractControl) => control['_registerOnCollectionChange'](() => {}));
        formArray.controls.splice(0);
        if(options.emitEvent){
		 	formArray.updateValueAndValidity();
	 	}
    }

	/**
	 * Insert a new `AbstractControl` at the end of the array.
	 *
	 * @param control Form control to be inserted
	 */
	static push(formArray: FormArray, control: AbstractControl, options: {emitEvent?: boolean} = {emitEvent: true}): void {
		formArray.controls.push(control);
		formArray['_registerControl'](control);
		if(options.emitEvent){
		 	formArray.updateValueAndValidity();
	 	}
		formArray['_onCollectionChange']();
	}

}