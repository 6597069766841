import { ICustomerGroupsChangeNotificationResponse } from '@appRoot/core/customer/models/ICustomerGroupsChangeNotificationRequestParams';


export class CustomerGroupsChangeNotification {
	id: number;
	group_id: number | null;
	group_name: number;
	from_group_id: number | null;
	from_group_name: number;
	subject: string;
	any: boolean;
	use_main: boolean;
	to_email: string | null;
	cc_emails: string | null;
	html_template: string;
	text_template: string;
	status: boolean;
	created_at: number;
	updated_at: number;

	constructor(data?: ICustomerGroupsChangeNotificationResponse) {
		if (data) {
            this.id = data.id;
            this.group_id = data.group_id;
            this.group_name = data.group_name;
            this.from_group_id = data.from_group_id;
            this.from_group_name = data.from_group_name;
            this.any = data.any;
            this.use_main = data.use_main;
            this.subject = data.subject;
            this.to_email = data.to_email;
            this.cc_emails = data.cc_emails;
            this.html_template = data.html_template;
            this.text_template = data.text_template;
            this.status = data.status;
            this.created_at = data.created_at;
            this.updated_at = data.updated_at;
		}
	}
}