export class CustomerExpense {
    purchase: number;
    purchaseVat: number;
    saleprice: number;
    salepriceVat: number;
    retailprice: number;
    retailpriceVat: number;
    description: string;
    detailedDescription: string;
    reference: string;
}