import { NGRXError } from '@appRoot/core/ngrx-store/models/NGRXError';
import { createEntityAdapter, EntityAdapter, EntityState, } from '@ngrx/entity';
import { Role } from '../../models';
import { Actions, ActionTypes } from '../actions/role.actions';

function selectId(entity: Role): number {
    //In this case this would be optional since primary key is id
    return entity.id;
}

export const adapter: EntityAdapter<Role> = createEntityAdapter({
    selectId: selectId,
    sortComparer: false,
});

export interface State extends EntityState<Role> {
    loaded: boolean;
    loading: boolean;
    error: NGRXError<Actions>;
}

export const initialState: State = adapter.getInitialState({
    loaded: false,
    loading: false,
    error: null,
});

export const RoleEntitySelectors = adapter.getSelectors();

export function reducer(state = initialState, action: Actions): State {
    switch (action.type) {
        case ActionTypes.LOAD:
            return {
                ...state,
                loading: true,
            };

        case ActionTypes.LOAD_SUCCESS:
            return {
                ...state,
                loading: false,
            };

        case ActionTypes.LOAD_FAILED:
            return {
                ...state,
                loading: false,
            };

        case ActionTypes.LOAD_ALL:
            return {
                ...state,
                loading: true,
                loaded: false,
            };

        case ActionTypes.LOAD_ALL_SUCCESS:
            return {
                ...state,
                loading: false,
                loaded: true,
            };

        case ActionTypes.LOAD_ALL_FAILED:
            return {
                ...state,
                loading: false,
                loaded: false,
            };

        case ActionTypes.ADD_MANY:
            return adapter.upsertMany(
                action.payload.roles,
                state,
            );

        case ActionTypes.GET:
        case ActionTypes.CREATE:
        case ActionTypes.REMOVE:
        case ActionTypes.UPDATE:
            return {
                ...state,
                loading: true,
            };

        case ActionTypes.GET_SUCCESS: {
            let role = Object.assign(new Role, action.payload.role);
            role.changed = false;
            return adapter.upsertOne(
                role,
                {
                    ...state,
                    loading: false,
                },
            );
        }

        case ActionTypes.REMOVE_SUCCESS:
            return adapter.removeOne(
                selectId(action.payload.role),
                {
                    ...state,
                    loading: false,
                }
            );

        case ActionTypes.CREATE_SUCCESS:
            return adapter.addOne(
                action.payload.role,
                {
                    ...state,
                    loading: false,
                }
            );

        case ActionTypes.UPDATE_SUCCESS: {
            let role = Object.assign(new Role, action.payload.changed);
            role.changed = false;
            return adapter.upsertOne(role, {
                ...state,
                loading: false,
            });
        }

        case ActionTypes.MARK_AS_CHANGED:
            return adapter.updateMany(
                action.payload.ids.map(e => ( {
                    id: e,
                    changes: {changed: true}
                } )),
                {...state}
            );

        case ActionTypes.ERROR:
            return {
                ...state,
                loading: false,
                error: action.payload,
            };

        case ActionTypes.RESET:
            return {
                ...initialState
            };

        default:
            return state;
    }

}
