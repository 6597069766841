import { NGRXError } from '@appRoot/core/ngrx-store/models/NGRXError';
import { Actions, ActionTypes } from '../actions/reset-password.actions';

export interface State {
	loading: boolean;
	error: NGRXError<Actions>;
	created: boolean;
	valid: boolean;
	complete: boolean;
}

export const initialState: State = {
	loading: false,
	error: null,
	created: false,
	valid: false,
	complete: false,
};

export function reducer(state = initialState, action: Actions): State {
	switch (action.type) {		
		case ActionTypes.CREATE: 
			return {
				...state,
				loading: true,
				created: false,
			};

		case ActionTypes.CREATE_SUCCESS:
			return {
				...state,
				loading: false,
				created: true,
			};

		case ActionTypes.CHECK: 
			return {
				...state,
				loading: true,
				valid: false,
			};

		case ActionTypes.CHECK_SUCCESS: 
			return {
				...state,
				loading: false,
				valid: true,
			};

		case ActionTypes.RESET_PASSWORD: 
			return {
				...state,
				loading: true,
				complete: false,
			};

		case ActionTypes.RESET_PASSWORD_SUCCESS: 
			return {
				...state,
				loading: false,
				complete: true,
			};

		case ActionTypes.RESET:
			return {
				...initialState
			};


		case ActionTypes.ERROR:
			return {
				...state,
				loading: false,
				error: action.payload,
			};

		default:
			return state;
	}
}