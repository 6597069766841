import { Injectable } from '@angular/core';
import { RouterState } from '@appRoot/core/ngrx-store/router';
import { select, Store } from '@ngrx/store';
import { CookieService } from 'ngx-cookie-service';

import { AccessToken } from '../models';
import * as authActions from '../ngrx-store/actions/authentication.actions';
import * as confActions from '../ngrx-store/actions/confirmation.actions';
import * as regActions from '../ngrx-store/actions/registration.actions';
import * as resetActions from '../ngrx-store/actions/reset-password.actions';

import * as authSelectors from '../ngrx-store/selectors/authentication.selectors';
import * as confSelectors from '../ngrx-store/selectors/confirmation.selectors';
import * as regSelectors from '../ngrx-store/selectors/registration.selectors';
import * as resetSelectors from '../ngrx-store/selectors/reset-password.selectors';


@Injectable()
export class AuthenticationService {

	/**
	 * Access token
	 * @type {string}
	 */
	private _token: string = null;
    public readonly TOKEN_KEY: string = 'accessToken';

    private _routeState: RouterState;
    public readonly DEFAULT_ROUTE_STATE: RouterState = { url: '/home', params: {}, queryParams: {}, data: {} };
    public readonly UNAUTHENTICATED_ROUTE_STATE: RouterState = { url: '/login', params: {}, queryParams: {}, data: {} };

	constructor(
		private store: Store<any>,
		private cookieService: CookieService,
		) { }

    get routeState(): RouterState {
        return this._routeState? this._routeState: this.DEFAULT_ROUTE_STATE;
    }

    set routeState(state: RouterState) {
        this._routeState = state;
    }

	setAccessToken(token: AccessToken): void {
        let expires = new Date( Date.now() + token.expires_in*1000 ) ;
        this.cookieService.set(this.TOKEN_KEY, token.access_token, expires, '/', null, false, "Strict");
        this._token = token.access_token;
	}

	getAccessToken(): string|null {
        if( this._token === null && this.cookieService.check(this.TOKEN_KEY) ) this._token = this.cookieService.get(this.TOKEN_KEY);
        return this._token;
    }

    revokeAccessToken(): void {
        this._token = null;
    	this.cookieService.delete(this.TOKEN_KEY, '/');
    }

    /* Get selectors */

    getState$() {
        return this.store.pipe(select(authSelectors.getStore));
    }
    getLoading$(){
        return this.store.pipe(select(authSelectors.getLoading))
    }
    isAuthenticated$() {
        return this.store.pipe(select(authSelectors.getAuthenticate))
    }
    getAuthComplete$() {
        return this.store.pipe(select(authSelectors.getAuthComplete))
    }
    getStoreRefresh$() {
        return this.store.pipe(select(authSelectors.getRefresh))
    }
    getError$(){
        return this.store.pipe(select(authSelectors.getError))
    }

    /* Registration selectors */

    getRegistrationState$(){
        return this.store.pipe(select(regSelectors.getStore))
    }
    getRegistrationLoading$(){
        return this.store.pipe(select(regSelectors.getLoading))
    }
    getRegistrationError$(){
        return this.store.pipe(select(regSelectors.getError))
    }
    getRegistrationComplete$(){
        return this.store.pipe(select(regSelectors.getComplete))
    }

    /* Confirmation selectors */

    getConfirmationState$(){
        return this.store.pipe(select(confSelectors.getStore))
    }
    getConfirmationConfirming$(){
        return this.store.pipe(select(confSelectors.getConfirming))
    }
    getConfirmationSending$(){
        return this.store.pipe(select(confSelectors.getSending))
    }
    getConfirmationError$(){
        return this.store.pipe(select(confSelectors.getError))
    }
    getConfirmationConfirmed$(){
        return this.store.pipe(select(confSelectors.getConfirmed))
    }
    getConfirmationSent$(){
        return this.store.pipe(select(confSelectors.getSent))
    }

    /* ResetPassword selectors */

    getResetPasswordState$(){
        return this.store.pipe(select(resetSelectors.getStore))
    }
    getResetPasswordError$(){
        return this.store.pipe(select(resetSelectors.getError))
    }
    getResetPasswordLoading$(){
        return this.store.pipe(select(resetSelectors.getLoading))
    }
    getResetPasswordCreated$(){
        return this.store.pipe(select(resetSelectors.getCreate))
    }
    getResetPasswordValid$(){
        return this.store.pipe(select(resetSelectors.getValid))
    }
    getResetPasswordComplete$(){
        return this.store.pipe(select(resetSelectors.getComplete))
    }

    /* Dispatch actions */

    dispatchRefreshToken() {
    	this.store.dispatch(new authActions.RefreshToken);
    }
    dispatchAttempt() {
        this.store.dispatch(new authActions.Attempt);
    }
    dispatchUnAuthenticate(){
        this.store.dispatch(new authActions.UnAuthenticate);
    }
    dispatchLogin(username: string, password: string){
        this.store.dispatch( new authActions.Login({username: username, password: password}) );
    }
    dispatchLogout(){
        this.store.dispatch( new authActions.Logout );
    }

    /* Registration actions */

    dispatchRegistration(data: {name: string, email: string, password: string, password_confirmation: string, token?: string}){
        this.store.dispatch( new regActions.Registration(data) );
    }
    dispatchRegistrationCompleteReset(){
        this.store.dispatch( new regActions.CompleteReset );
    }

    /* Confirmation actions */

    dispatchConfirmationSend(params: ConstructorParameters<typeof confActions.Send>[0]) {
        this.store.dispatch( new confActions.Send(params)  );
    }
    dispatchConfirmationConfirm(token: string) {
        this.store.dispatch( new confActions.Confirm({token: token})  );
    }
    dispatchConfirmationReset() {
        this.store.dispatch( new confActions.Reset );
    }

    /* ResetPassword actions */

    dispatchResetPasswordCreate(email: string){
        this.store.dispatch( new resetActions.Create({email: email})  );
    }
    dispatchResetPasswordCheck(token: string){
        this.store.dispatch( new resetActions.Check({token: token})  );
    }
    dispatchResetPassword(data: {password: string, password_confirmation: string, token: string}){
        this.store.dispatch( new resetActions.ResetPassword(data) );
    }
    dispatchResetPasswordResetState(){
        this.store.dispatch( new resetActions.Reset );
    }

}
