import { ICustomerContactsResponse, ICustomerContactsRequestUpdate } from '@appRoot/core/customer/models';


export class CustomerContacts {
    id: number;
    customer_id: number;
    name: string;
    address: string;
    phone: string;
    note: string;
    contact_email: string;
    city: string;
    postal_code: string;
    billing: boolean;
    purchaser: boolean;
    primary: boolean;

    constructor(data?: ICustomerContactsResponse){
        if(data){
            this.createFromResponse(data);
        }
    }

    createFromResponse(data: ICustomerContactsResponse){
        this.id = data.id;
        this.customer_id = data.customer_id;
        this.name = data.name;
        this.address = data.address;
        this.phone = data.phone;
        this.note = data.note;
        this.contact_email = data.contact_email;
        this.city = data.city;
        this.postal_code = data.postal_code;
        this.billing = data.billing;
        this.purchaser = data.purchaser;
        this.primary = data.primary;
    }
}

export function getCustomerContactsRequest(data: CustomerContacts): ICustomerContactsRequestUpdate {
    let output: ICustomerContactsRequestUpdate = {};

    output.address = data.address;
    output.phone = data.phone;
    output.contact_email = data.contact_email;
    output.city = data.city;
    output.postal_code = data.postal_code;
    output.primary = data.primary;
    output.billing = data.billing;

    return output;
}