import { NGRXError } from '@appRoot/core/ngrx-store/models/NGRXError';
import { Action } from '@ngrx/store';
import { INotificationSettings, Notification } from '../../models';


export enum ActionTypes {
    LOAD = '[Notification] load',
    LOAD_SUCCESS = '[Notification] load success',
    LOAD_FAILED = '[Notification] load failed',

    UPDATE = '[Notification] update',
    UPDATE_SUCCESS = '[Notification] update success',
    UPDATE_FAILED = '[Notification] update failed',

    ERROR = '[Notification] error',
    RESET = '[Notification] reset',
}

export class Error implements Action {
    readonly type = ActionTypes.ERROR;
    constructor(readonly payload: NGRXError<Actions>) { }
}

export class Reset implements Action {
    readonly type = ActionTypes.RESET;
}

export class Load implements Action {
    readonly type = ActionTypes.LOAD
}
export class LoadSuccess implements Action {
    readonly type = ActionTypes.LOAD_SUCCESS;
    constructor(readonly payload: Notification[]) {}
}
export class LoadFailed implements Action {
    readonly type = ActionTypes.LOAD_FAILED;
}


export class Update implements Action {
    readonly type = ActionTypes.UPDATE;
    constructor(readonly payload: { entity: Notification, settings: INotificationSettings }) {}
}
export class UpdateSuccess implements Action {
    readonly type = ActionTypes.UPDATE_SUCCESS;
    constructor(readonly payload: Notification) {}
}

export class UpdateFailed implements Action {
    readonly type = ActionTypes.UPDATE_FAILED;
}


export type Actions =
    | Load
    | LoadSuccess
    | LoadFailed
    | Update
    | UpdateSuccess
    | UpdateFailed
    | Error
    | Reset
    ;