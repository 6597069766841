import { Action } from '@ngrx/store';
import { ICustomerGetHistoryParams, CustomerActionHistory } from '../../models';
import { OmitUID } from '../../../interfaces';
import { NGRXError } from '../../../ngrx-store/models/NGRXError';


export enum ActionTypes {
    UPSERT_MANY = '[Customer history] UPSERT_MANY',
    GET_HISTORY = '[Customer history] GET_HISTORY',
    GET_HISTORY_SUCCESS = '[Customer history] GET_HISTORY_SUCCESS',
    GET_HISTORY_FAILED = '[Customer history] GET_HISTORY_FAILED',
    ERROR = '[Customer history] ERROR',
    SET_DATE_FROM = '[Customer history] SET_DATE_FROM',
    SET_DATE_UNTIL = '[Customer history] SET_DATE_UNTIL',
}

export class UpsertMany implements Action {
    readonly type = ActionTypes.UPSERT_MANY;

    constructor(readonly payload: CustomerActionHistory[]) { }
}

export class GetHistory implements Action {
    readonly type = ActionTypes.GET_HISTORY;

    constructor(readonly payload: OmitUID<ICustomerGetHistoryParams>) { }
}

export class GetHistorySuccess implements Action {
    readonly type = ActionTypes.GET_HISTORY_SUCCESS;
}

export class GetHistoryFailed implements Action {
    readonly type = ActionTypes.GET_HISTORY_FAILED;
}

export class Error implements Action {
    readonly type = ActionTypes.ERROR;

    constructor(readonly payload: NGRXError<Actions>) { }
}

export class SetDateFrom implements Action {
    readonly type = ActionTypes.SET_DATE_FROM;

    constructor(readonly payload: number) { }
}

export class SetDateUntil implements Action {
    readonly type = ActionTypes.SET_DATE_UNTIL;

    constructor(readonly payload: number) { }
}

export type Actions =
    | UpsertMany
    | GetHistory
    | GetHistorySuccess
    | GetHistoryFailed
    | Error
    | SetDateFrom
    | SetDateUntil
    ;