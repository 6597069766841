import { Action } from '@ngrx/store';

export enum ActionTypes {
    LOGIN = '[Authentication] login',
    LOGIN_SUCCESS = '[Authentication] login success',
    LOGIN_FAILED = '[Authentication] login failed',
    LOGOUT = '[Authentication] logout',
    LOGOUT_SUCCESS = '[Authentication] logout success',
    ERROR = '[Authentication] error',
    REFRESH_TOKEN = '[Authentication] refresh token',
    REFRESH_TOKEN_COMPLETE = '[Authentication] refresh token complete',
    ATTEMPT = '[Authentication] attempt',
    ATTEMPT_COMPLETE = '[Authentication] attempt complete',
    AUTHENTICATE = '[Authentication] authenticate',
    UNAUTHENTICATE = '[Authentication] unauthenticate',
}

export class Login implements Action {
    readonly type = ActionTypes.LOGIN;
    constructor(readonly payload: {username: string, password: string}) { }
}
export class LoginSuccess implements Action {
    readonly type = ActionTypes.LOGIN_SUCCESS;
}
export class LoginFailed implements Action {
    readonly type = ActionTypes.LOGIN_FAILED;
}

export class Logout implements Action {
    readonly type = ActionTypes.LOGOUT;
}
export class LogoutSuccess implements Action {
    readonly type = ActionTypes.LOGOUT_SUCCESS;
}

export class Authenticate implements Action {
    readonly type = ActionTypes.AUTHENTICATE;
}
export class UnAuthenticate implements Action {
    readonly type = ActionTypes.UNAUTHENTICATE;
}

export class Error implements Action {
    readonly type = ActionTypes.ERROR;
    constructor(readonly payload: {error: any, action: Actions}) { }
}

export class RefreshToken implements Action {
    readonly type = ActionTypes.REFRESH_TOKEN;
}

export class RefreshTokenComplete implements Action {
    readonly type = ActionTypes.REFRESH_TOKEN_COMPLETE;
    constructor(readonly payload: boolean ) { }
}

export class Attempt implements Action {
    readonly type = ActionTypes.ATTEMPT;
}

export class AttemptComplete implements Action {
    readonly type = ActionTypes.ATTEMPT_COMPLETE;
}


export type Actions =
    | Login
    | LoginSuccess
    | LoginFailed
    | Attempt
    | AttemptComplete
    | Logout
    | LogoutSuccess
    | Error
    | RefreshToken
    | RefreshTokenComplete
    | Authenticate
    | UnAuthenticate
    ;