<div class="text-center">

    <ng-container *ngIf="(activeUser$ | async) as activeUser">

        <app-bs4-alert
                *ngIf="!activeUser.verified"
                class="d-inline-block p-15"
                message="Your email has not been verified. <br>Please follow <a href='/user-settings/account/confirmation' data-link='/user-settings/account/confirmation'>this link</a> to confirm your email."
                type="warning"></app-bs4-alert>
    </ng-container>

</div>
