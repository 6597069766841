import { createFeatureSelector, createSelector } from '@ngrx/store';

import { State } from '../reducers/authentication.reducer';

export const getStore = createFeatureSelector<State>('authentication');

export const getLoading = createSelector(
  getStore,
  (state) => state.loading
);

export const getAuthenticate = createSelector(
  getStore,
  (state) => state.isAuthenticated
);

export const getAuthComplete = createSelector(
  getStore,
  (state) => state.authComplete
);

export const getRefresh = createSelector(
  getStore,
  (state) => state.refresh
);

export const getError = createSelector(
  getStore,
  (state) => state.error
);