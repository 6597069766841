import { Action, ActionReducer } from '@ngrx/store';
import * as authActions from "src/app/core/authentication/ngrx-store/actions/authentication.actions";

export function clearState<State extends {}>(reducer: ActionReducer<State>): ActionReducer<State> {
    return function (state: State, action: Action) {

        if (action.type === authActions.ActionTypes.LOGOUT) {
            state = {} as State;
        }

        return reducer(state, action);
    };
}