<div class="container-fluid text-color">
    <div class="d-flex justify-content-between">
        <fa-icon [icon]="['fas', 'globe']" size="3x" class="{{ (loadingStepService || loadingStepRegister || loadingStepPayment || loadingStepCompletion) ? 'text-color-progress' : ''}}"></fa-icon>
        <fa-icon [icon]="['fas', 'chevron-right']" size="3x" class="{{ (loadingStepService || loadingStepRegister || loadingStepPayment || loadingStepCompletion) ? 'text-color-progress' : ''}}"></fa-icon>

        <fa-icon [icon]="['fas', 'key']" size="3x" class="{{ (loadingStepRegister || loadingStepPayment || loadingStepCompletion) ? 'text-color-progress' : ''}}"></fa-icon>
        <fa-icon [icon]="['fas', 'chevron-right']" size="3x" class="{{ (loadingStepRegister || loadingStepPayment || loadingStepCompletion) ? 'text-color-progress' : ''}}"></fa-icon>

        <fa-icon [icon]="['fas', 'credit-card']" size="3x" class="{{ (loadingStepPayment || loadingStepCompletion) ? 'text-color-progress' : ''}}"></fa-icon>
        <fa-icon [icon]="['fas', 'chevron-right']" size="3x" class="{{ (loadingStepPayment || loadingStepCompletion) ? 'text-color-progress' : ''}}"></fa-icon>

        <fa-icon [icon]="['fas', 'check']" size="3x" class="{{ (loadingStepCompletion) ? 'text-color-progress' : ''}}"></fa-icon>
    </div>
</div>

<hr class="mb-5">