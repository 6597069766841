import { createSelector } from '@ngrx/store';
import { ModuleStore } from '../reducers';
import * as customerContactsStorageReducer from '../reducers/customer-contacts-storage.reducer';
import * as customerContactsReducer from "@appRoot/core/customer/ngrx-store/reducers/customer-contacts.reducer";


export const getCustomerContactsState = createSelector(
    ModuleStore,
    (store) => store.customer_contacts
);
export const getCustomerContactsStore = createSelector(
    ModuleStore,
    (store) => store.customer_contacts_storage
);

const customerEntitySelectors = customerContactsStorageReducer.adapter.getSelectors();

export const getCustomerContactsArray = createSelector(
    getCustomerContactsStore,
    customerEntitySelectors.selectAll
);

export const selectEntities = createSelector(
    getCustomerContactsStore,
    customerContactsReducer.entitySelectors.selectEntities
);

export const getCustomerContactsDictionary = createSelector(
    getCustomerContactsStore,
    customerEntitySelectors.selectEntities
);

export const getError = createSelector(
    getCustomerContactsState,
    (state) => state.error
);

export const getLoading = createSelector(
    getCustomerContactsState,
    (state) => state.loading
);
export const getComplete = createSelector(
    getCustomerContactsState,
    (state) => state.complete
);
export const getCreating = createSelector(
    getCustomerContactsState,
    (state) => state.creating
);
export const getGetting = createSelector(
    getCustomerContactsState,
    (state) => state.getting
);
export const getUpdating = createSelector(
    getCustomerContactsState,
    (state) => state.updating
);
export const getDeleting = createSelector(
    getCustomerContactsState,
    (state) => state.deleting
);
export const getRestoring = createSelector(
    getCustomerContactsState,
    (state) => state.restoring
);
export const getPagination = createSelector(
    getCustomerContactsState,
    (state) => state.pagination
);

export const getContactById = (contactId: number) => {
    return createSelector(
        getCustomerContactsDictionary,
        (entities) => entities[contactId]
    );
};

export const getContactsByIds = (customerGroupsIds: number[]) => {
    return createSelector(
        getCustomerContactsDictionary,
        (dictionary) => customerGroupsIds.reduce((acc, key) => dictionary[key] ? [...acc, dictionary[key]] : acc, [])
    );
};

export const getEntityById = (id: number) => createSelector(
    selectEntities,
    (entities) => entities[id]
);

export const getCustomersByCustomerIds = (customerIds: number[]) => {
    return createSelector(
        getCustomerContactsArray,
        (entities) => entities.filter(customer => customerIds.indexOf(customer.customer_id) != -1)
    );
};

export const getCustomerPrimaryContacts = (customerId: number) => {
    return createSelector(
        getCustomerContactsArray,
        (entities) => entities.find(i => i.customer_id === customerId && i.primary)
    );
};

export const getCustomerBillingContacts = (customerId: number) => {
    return createSelector(
        getCustomerContactsArray,
        (entities) => entities.filter(i => i.customer_id === customerId && i.billing)
    );
};