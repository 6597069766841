import { Directive, ElementRef, HostListener } from '@angular/core';


@Directive({
  selector: '[appThousandSeparator]'
})
export class ThousandSeparatorDirective {

  constructor(private el: ElementRef) { }

  @HostListener('input', ['$event']) onInputChange(event: any) {
    const initialValue = event.target.value.replace(/[^0-9]/g, '');

    const formattedValue = this.formatNumberWithCommas(initialValue);

    event.target.value = formattedValue;
  }

  formatNumberWithCommas(number: string): string {
    return number.replace(/\B(?=(\d{3})+(?!\d))/g, '.');
  }
}