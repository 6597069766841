import { Action } from '@ngrx/store';
import {
    ISelfSignupRequestStoreCustomerParams,
    ISelfSignupRequestStorePaymentParams
} from "@appRoot/lazy-modules/self-signup/models";


export enum ActionTypes {
    SET_PACKAGE_ID = '[SelfSignupAccounts] set package id',
    SET_DOMAIN = '[SelfSignupAccounts] set domain',
    SET_CUSTOMER = '[SelfSignupAccounts] set Customer',
    SET_CUSTOMER_NUMBER = '[SelfSignupAccounts] set Customer number',
    SET_CUSTOMER_ID = '[SelfSignupAccounts] set Customer id',
    SET_CUSTOMER_CARD = '[SelfSignupAccounts] set Customer card',
    RESET = '[SelfSignupAccounts] reset',
}

export class SetPackageId implements Action {
    readonly type = ActionTypes.SET_PACKAGE_ID;
    constructor(readonly package_id: number) {}
}

export class SetDomain implements Action {
    readonly type = ActionTypes.SET_DOMAIN;
    constructor(readonly domain: string) {}
}

export class SetCustomer implements Action {
    readonly type = ActionTypes.SET_CUSTOMER;
    constructor(readonly customer: ISelfSignupRequestStoreCustomerParams) {}
}

export class SetCustomerNumber implements Action {
    readonly type = ActionTypes.SET_CUSTOMER_NUMBER;
    constructor(readonly customer_number: string) {}
}

export class SetCustomerId implements Action {
    readonly type = ActionTypes.SET_CUSTOMER_ID;
    constructor(readonly id: number) {}
}

export class SetCustomerCard implements Action {
    readonly type = ActionTypes.SET_CUSTOMER_CARD;
    constructor(readonly card: ISelfSignupRequestStorePaymentParams) {}
}

export class Reset implements Action {
    readonly type = ActionTypes.RESET;
}

export type Actions =
    | SetPackageId
    | SetDomain
    | SetCustomer
    | SetCustomerNumber
    | SetCustomerId
    | SetCustomerCard
    | Reset
    ;