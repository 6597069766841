import { createEntityAdapter, EntityAdapter, EntityState, } from '@ngrx/entity';

import { Actions, ActionTypes } from '../actions/authorization.actions';
import { UserAccess } from '../../models';
import { NGRXError } from '@appRoot/core/ngrx-store/models/NGRXError';


function selectId(entity: UserAccess): number {
    //In this case this would be optional since primary key is id
    return entity.user_id;
}

export const adapter: EntityAdapter<UserAccess> = createEntityAdapter({
    selectId: selectId,
    sortComparer: false,
});

export interface State extends EntityState<UserAccess> {
    loading: boolean;
    error: NGRXError<Actions>;
    isAuthorized: boolean;
}

export const initialState: State = adapter.getInitialState({
    loading: false,
    error: null,
    isAuthorized: false,
});

export const EntitySelectors = adapter.getSelectors();

export function reducer(state = initialState, action: Actions): State {
    switch (action.type) {
        case ActionTypes.GET_USER_PERMISSIONS:
        case ActionTypes.UPDATE_USER_PERMISSIONS:
        case ActionTypes.UPDATE_USER_ROLES:
            return {
                ...state,
                loading: true,
            };

        case ActionTypes.GET_USER_PERMISSIONS_SUCCESS:
        case ActionTypes.UPDATE_USER_PERMISSIONS_SUCCESS:
            return adapter.upsertOne({
                user_id: action.payload.user.id,
                permission_ids: action.payload.permissions.map(item => item.id)
            },{
                ...state,
                loading: false,
            });

        case ActionTypes.UPDATE_USER_ROLES_SUCCESS:
            return {
                ...state,
                loading: false,
            };

        case ActionTypes.GET_ACTIVE_USER_PERMISSIONS:
            return {
                ...state,
                loading: true,
                isAuthorized: false,
            };

        case ActionTypes.GET_ACTIVE_USER_PERMISSIONS_SUCCESS:
            return adapter.upsertOne({
            	user_id: action.payload.user.id,
            	permission_ids: action.payload.permissions.map(item => item.id)
            },{
            	...state,
            	loading: false,
                isAuthorized: true,
            });

        case ActionTypes.ERROR:
            return {
                ...state,
                loading: false,
                error: new NGRXError(action.payload.action, action.payload.error)
            };

        case ActionTypes.ERROR_RESET:
            return {
                ...state,
                error: null,
            };

        case ActionTypes.RESET:
            return {
                ...initialState
            };

        case ActionTypes.UPSERT_ONE:
            return adapter.upsertOne(action.payload,{
                ...state,
            });

        case ActionTypes.UPSERT_MANY:
            return adapter.upsertMany(action.payload,{
                ...state,
            });

        case ActionTypes.REMOVE_MANY:
            return adapter.removeMany(action.payload,{
                ...state,
            });

        default:
            return state;
    }
}
