import { isNil } from 'lodash';
import { TAC_SERVICES_TYPES } from '@appRoot/lazy-modules/tac-services/models';


export interface IWhmAccountResponse {
    id?: number,
    source_id?: number,
    domain: string;
    username: string;
    package_id?: number;
    created_at?: string;
    deleted_at?: string;
    updated_at?: string;
    server_id?: number;
    customer_id?: number;
    customer_name?: string;
    description?: string;
    scheduled_suspension_date?: string;
    scheduled_deletion_date?: string;
    action_notice?: string;
    internal_notice?: string;
    contactemail?: string;
    service_type?: TAC_SERVICES_TYPES.whm_dev_service | TAC_SERVICES_TYPES.whm_prod_service;

    action_history: IWhmAccountActionHistoryResponse[];
    password?: string;

    shared?: IWhmAccountShared[];
    subdomains?: IWhmAccountSubdomains[];
    aliases?: IWhmAccountAliases[];
}

export interface IWhmAccountActionHistoryResponse {
    id: number,
    whm_account_id: number,
    customer_id: number,
    user_id: number,
    user: {
        name: string
    },
    action: string,
    created_at: string,
    notice: string,
    account_data: {
        origin: Partial<IWhmAccountResponse>,
        changes: Partial<IWhmAccountResponse>, 
        service?: TAC_SERVICES_TYPES.whm_dev_service | TAC_SERVICES_TYPES.whm_prod_service
    };
}

export interface IWhmAccountStatisticsResponse {
    error: string | null,
    usage: number,
    id: string,
    formatter: string,
    maximum: string | number | null,
    description: string,
    url: string | null
}

export class WhmAccount {
    id: number;
    source_id: number;
    domain: string;
    username: string;
    password: string;
    email: string;
    package_id: number;
    server_id: number;
    customer_id: number;
    customer_name: string;
    description: string;
    scheduled_suspension_date: string;
    scheduled_deletion_date: string;
    action_notice: string;
    internal_notice: string;
    created: Date = null;
    updated: Date = null;
    deleted: Date = null;
    service_type?: TAC_SERVICES_TYPES.whm_dev_service | TAC_SERVICES_TYPES.whm_prod_service;
    action_history: Partial<IWhmAccountActionHistoryResponse>[] = [];
    statistics?: Partial<IWhmAccountStatisticsResponse>[] = [];
    shared: IWhmAccountShared[] = [];
    subdomains: IWhmAccountSubdomains[] = [];
    aliases: IWhmAccountAliases[] = [];

    constructor(data?: IWhmAccountResponse) {
        if(data) {
            this.createFromResponse(data);
        }
    }

    createFromResponse(data: IWhmAccountResponse) {
        this.source_id = data.source_id;
        this.domain = data.domain;
        this.username = data.username;

        this.email = data.contactemail;
        this.package_id = data.package_id;
        this.description = data.description;
        this.scheduled_suspension_date = data.scheduled_suspension_date;
        this.scheduled_deletion_date = data.scheduled_deletion_date;
        this.action_notice = data.action_notice;
        this.internal_notice = data.internal_notice;

        this.id = !isNil(data.id) ? +(data.id) : null;
        this.server_id = !isNil(data.server_id) ? +(data.server_id) : null;
        this.customer_id = !isNil(data.customer_id) ? +(data.customer_id) : null;
        this.customer_name = !isNil(data.customer_name) ? data.customer_name : null;
        this.service_type = !isNil(data.service_type) ? data.service_type : null;

        this.created = !isNil(data.created_at) ? new Date(data.created_at) : null;
        this.updated = !isNil(data.updated_at) ? new Date(data.updated_at) : null;
        this.deleted = !isNil(data.deleted_at) ? new Date(data.deleted_at) : null;

        this.action_history = data.action_history.map(item => ({
            user_id: item.user_id,
            action: item.action,
            created: !isNil(item.created_at) ? new Date(item.created_at) : null,
            notice: item.notice,
        }));

        if (data.password) {
            this.password = data.password;
        }

        if (data.shared) {
            this.shared = data.shared.map(item => {
                return {
                    ...item,
                    created_at: !isNil(item.created_at) ? new Date(item.created_at) : null,
                    updated_at: !isNil(item.updated_at) ? new Date(item.updated_at) : null,
                };
            });
        }

        if (data.subdomains) {
            this.subdomains = data.subdomains.map(item => {
                return {
                    ...item,
                    created_at: !isNil(item.created_at) ? new Date(item.created_at) : null,
                    updated_at: !isNil(item.updated_at) ? new Date(item.updated_at) : null,
                };
            });
        }

        if (data.aliases) {
            this.aliases = data.aliases.map(item => {
                return {
                    ...item,
                    created_at: !isNil(item.created_at) ? new Date(item.created_at) : null,
                    updated_at: !isNil(item.updated_at) ? new Date(item.updated_at) : null,
                };
            });
        }
    }
}

export interface IWhmAccountImportFailed {
    account: WhmAccount;
    exception: string;
    reason: string;
    error?: {
        code: number,
        message: string,
        type: string,
    };
    errors?: { [key: string]: string[] };
}

export interface SyncWhmAccount {
    id: WhmAccount['id'],
    remote: WhmAccount,
}

export interface IWhmAccountGetSyncResponse {
    local: IWhmAccountResponse;
    remote: IWhmAccountResponse | null;
}

export interface IWhmAccountGetSync {
    local: WhmAccount;
    remote: WhmAccount | null;
}

export interface IWhmAccountSyncResponse {
    updated: IWhmAccountResponse[];
    deleted: IWhmAccountResponse[];
}

export interface IWhmAccountShared {
    id: number
    user_id_from: number;
    customer_id_to?: number;
    user_id_to?: number;
    email_to?: string;
    whm_account_id?: number;
    created_at?: string | Date;
    updated_at?: string | Date;
    permission_ids: Array<number>;
}

export interface IWhmAccountSubdomains {
    id: number
    whm_account_id?: number;
    subdomain: string;
    created_at?: string | Date;
    updated_at?: string | Date;
}

export interface IWhmAccountAliases {
    id: number
    whm_account_id?: number;
    alias: string;
    created_at?: string | Date;
    updated_at?: string | Date;
}