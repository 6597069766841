import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { tap } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { Location } from '@angular/common';
import { mapValues } from 'lodash';
import { environment } from 'src/environments/environment';


export interface IAppConfiguration {
    apiPath: string;
    application: {
        name: string;
        description: string;
        logo: {
            default: string;
            single: string;
            small?: string;
            large?: string;
        }
        year?: number;
        origin?: string;
    },
    settings: {
        registrationMethod: 'public' | 'token' | 'disable';
    }
}

@Injectable({providedIn: "root"})
export class SettingsService {

    public layout: any;
    private _layout: any;

    private configuration: IAppConfiguration;
    readonly ASSETS_PATH = "/assets";
    public registrationMethod: IAppConfiguration['settings']['registrationMethod'] = 'token';

    constructor(private http: HttpClient) {

        // Layout Settings
        // -----------------------------------

        this.layout = {
            isFixed: true,
            isCollapsed: false,
            isBoxed: false,
            isRTL: false,
            horizontal: false,
            isFloat: false,
            asideHover: false,
            theme: null,
            asideScrollbar: false,
            isCollapsedText: false,
            useFullLayout: false,
            hiddenFooter: false,
            offsidebarOpen: false,
            asideToggled: false,
            viewAnimation: 'ng-fadeInUp',
        }

    }

    loadConfig(): Observable<IAppConfiguration>{
        return this.http.get<IAppConfiguration>(`${this.ASSETS_PATH}/config.${environment.configFile}.json`).pipe(
            tap(config => {
                this.configuration = config;
                this.configuration.application.year = new Date().getFullYear();
                this.configuration.application.logo = <any>mapValues(config.application.logo, (value) => Location.joinWithSlash(this.ASSETS_PATH, value) );
                this.configuration.application.origin = document.location.origin;

                if(config.settings){
                    this.registrationMethod = config.settings.registrationMethod ? config.settings.registrationMethod : this.registrationMethod;
                }

            }),
        );
    }

    private _api_path: IAppConfiguration['apiPath'] = null;
    get API_PATH(){
        if(this._api_path === null){
            this._api_path = this.configuration.apiPath;
        }
        return this._api_path;
    }

    private _app: IAppConfiguration['application'] = null;
    get app(){
        if(this._app === null){
            this._app = { ...this.configuration.application };
        }
        return this._app;
    }

    getLayoutSetting(name) {
        return name ? this.layout[name] : this.layout;
    }
    setLayoutSetting(name, value) {
        if (typeof this.layout[name] !== 'undefined') {
            return this.layout[name] = value;
        }
    }

    toggleLayoutSetting(name) {
        return this.setLayoutSetting(name, !this.getLayoutSetting(name));
    }

}
