<div class="wrapper">
    <div class="block-center mt-4 wd-xl">
        <!-- START card-->
        <div class="card card-flat">
            <div class="card-header text-center bg-dark">
                <a href="#">
                    <img class="block-center rounded" src="{{settings.app.logo.default}}" alt="Image" />
                </a>
            </div>
            <div class="card-body">
                <p class="text-center py-2">VERIFY ACCOUNT TO CONTINUE.</p>

                <app-confirm [hidden]="!token" [token]="token" #verify></app-confirm>
                <app-confirm-form *ngIf="!token"></app-confirm-form>
                <app-login *ngIf="verify.confirmed && token"></app-login>

                <br><br>
                <a *ngIf="settings.registrationMethod === 'public'" [hidden]="verify.confirmed" class="btn btn-block btn-secondary" [routerLink]="'/register'">Sign Up</a>
                <a [hidden]="verify.confirmed" class="btn btn-block btn-secondary" [routerLink]="'/login'">Sign In</a>

                <a *ngIf="(!verify.confirmed && token)" class="btn btn-block btn-secondary" [routerLink]="'/verify'">Send activation link</a>
            </div>
        </div>

    </div>
</div>
