import { createFeatureSelector, createSelector } from '@ngrx/store';
import * as fromReducer from '../reducers/role.reducer';


export const getStore = createFeatureSelector<fromReducer.State>('role');


export const getLoading = createSelector(
    getStore,
    (state) => state.loading
);

export const getLoaded = createSelector(
    getStore,
    (state) => state.loaded
);

export const getStatuses = createSelector(
    getStore,
    (store: fromReducer.State) => ( {
        loaded: store.loaded,
        loading: store.loading,
    } )
);

export const getError = createSelector(
    getStore,
    (state) => state.error
);

export const getArray = createSelector(
    getStore,
    fromReducer.RoleEntitySelectors.selectAll
);

export const getDictionary = createSelector(
    getStore,
    fromReducer.RoleEntitySelectors.selectEntities
);

export const getEntityById = (id: number) => {
    return createSelector(
        getDictionary,
        (entities) => entities[id]
    );
};

export const getEntitiesByIds = (ids: number[]) => {
    return createSelector(
        getDictionary,
        (dictionary) => {
            if ( !Array.isArray(ids)) return [];
            return ids.reduce((acc, key) => dictionary[key] ? ( acc.push(dictionary[key]) && acc ) : acc, []);
        }
    );
};

export const getEntityByName = (name: string) => {
    return createSelector(
        getArray,
        (entities) => entities.find(i => i.name.toLowerCase() === name.toLowerCase())
    );
};



