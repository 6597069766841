import * as permSelectors from '@appRoot/core/roles-permissions/ngrx-store/selectors/permission.selectors';
import * as roleSelectors from '@appRoot/core/roles-permissions/ngrx-store/selectors/role.selectors';
import { createSelector } from '@ngrx/store';
import { isNil } from 'lodash';
import { User } from '../../models';
import { ModuleStore } from '../reducers';
import * as userStorageReducer from '../reducers/user-storage.reducer';
import * as auzSelectors from './authorization.selectors';


export const getUserState = createSelector(
    ModuleStore,
    (store) => store.user
);
export const getUserStore = createSelector(
    ModuleStore,
    (store) => store.user_storage
);

const userEntitySelectors = userStorageReducer.adapter.getSelectors();

export const getUserArray = createSelector(
    getUserStore,
    userEntitySelectors.selectAll
);

export const getUserDictionary = createSelector(
    getUserStore,
    userEntitySelectors.selectEntities
);

export const getSessionUserId = createSelector(
    getUserState,
    (state) => state.user
);

export const getSessionUser = createSelector(
    getUserDictionary,
    getSessionUserId,
    (entities, id) => entities[id]
);

export const getActiveUserId = createSelector(
    getUserState,
    (state) => state.activeUser
);

export const getActiveUser = createSelector(
    getUserDictionary,
    getActiveUserId,
    (entities, id) => entities[id]
);

export const getError = createSelector(
    getUserState,
    (state) => state.error
);

export const getLoading = createSelector(
    getUserState,
    (state) => state.loading
);
export const getComplete = createSelector(
    getUserState,
    (state) => state.complete
);
export const getGettingCurrent = createSelector(
    getUserState,
    (state) => state.getting_current
);
export const getCreating = createSelector(
    getUserState,
    (state) => state.creating
);
export const getGetting = createSelector(
    getUserState,
    (state) => state.getting
);
export const getUpdating = createSelector(
    getUserState,
    (state) => state.updating
);
export const getDeleting = createSelector(
    getUserState,
    (state) => state.deleting
);
export const getRestoring = createSelector(
    getUserState,
    (state) => state.restoring
);
export const getPagination = createSelector(
    getUserState,
    (state) => state.pagination
);

export const getUserById = (userId: number) => {
    return createSelector(
        getUserDictionary,
        (entities) => entities[userId]
    );
};

export const getUserByEmail = (email: string) => {
    return createSelector(
        getUserArray,
        (entities) => entities.find(i => i.email === email)
    );
};

export const findUsersByEmail = (email: string) => {
    return createSelector(
        getUserArray,
        (entities) => entities.filter(user => user.email && user.email.toLowerCase().includes((email+'').toLowerCase()))
    );
};

export const findUsersByNameOrEmail = (needle: string) => {
    return createSelector(
        getUserArray,
        (entities) => {
            return entities.filter(user => {
                return (user.name + user.email).toLowerCase().includes((needle+'').toLowerCase());
            })
        }
    );
};

export const findUsersBy = <T extends User, K extends keyof User>(key: K, value: T[K]) => {
    return createSelector(
        getUserArray,
        (entities) => entities.filter(user =>
            user.hasOwnProperty(key) &&
            (user[key] === value || (!isNil(user[key]) && (user[key]).toString().toLowerCase().includes((value + '').toLowerCase())))
        )
    );
};

export const getUsersByIds = (userIds: number[]) => {
    return createSelector(
        getUserDictionary,
        (dictionary) => userIds.reduce((acc, key) => dictionary[key] ? [...acc, dictionary[key]] : acc, [])
    );
};

export const getUserPermissions = (userId: number) => {
    return createSelector(
        getUserPermissionIds(userId),
        (store) => store,
        (ids, store) => {
            return permSelectors.getEntitiesByIds(ids)(store);
        }
    );
};

export const getUserPermissionIds = (userId: number) => {
    return createSelector(
        auzSelectors.getEntityById(userId),
        (auzEntity) => {
            return auzEntity && auzEntity.permission_ids ? auzEntity.permission_ids : [];
        }
    );
};

export const getUserRoles = (userId: number) => {
    return createSelector(
        getUserRoleIds(userId),
        (store) => store,
        (ids, store) => {
            return roleSelectors.getEntitiesByIds(ids)(store);
        }
    );
};

export const getUserRoleIds = (userId: number) => {
    return createSelector(
        getUserById(userId),
        (user) => user?  user.roleIds: []
    );
};