import { Injectable } from '@angular/core';
import { NGRXError } from '@appRoot/core/ngrx-store/models/NGRXError';
import * as auzActions from '@appRoot/core/user/ngrx-store/actions/authorization.actions';
import { UserService } from '@appRoot/core/user/services/user.service';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { Action, select, Store } from '@ngrx/store';
import { intersection, xor } from 'lodash';
import { Observable } from 'rxjs';
import { catchError, filter, map, mergeMap, switchMap, take, withLatestFrom } from 'rxjs/operators';
import { User } from '../../../user/models';
import { IPermissionRequestUpdateParams, Role } from '../../models';
import { PermissionHttpService } from '../../services/permission-http.service';
import * as permActions from '../actions/permission.actions';
import * as roleActions from '../actions/role.actions';
import * as roleSelectors from '../selectors/role.selectors';


@Injectable()
export class PermissionEffects {

	constructor(
		private store: Store<any>,
		private actions$: Actions,
		private httpService: PermissionHttpService,
		private userService: UserService,
		) {}

	@Effect()
	load$: Observable<Action> = this.actions$.pipe(
		ofType(permActions.ActionTypes.LOAD),
		withLatestFrom(this.userService.activeUser$),
		filter(([action, user]: [permActions.Load, User]) => !!user),
		switchMap(([action, user]) =>
			this.httpService.fetch({
				...action.payload,
				uid: user.id,
			})
			.pipe(
				switchMap(response => {
					return [
						new permActions.AddMany({permissions: response}),
						new permActions.LoadSuccess({permissions: response}),
					];
				}),
				catchError(error => [
					new permActions.Error(new NGRXError(action, error)),
					new permActions.LoadFailed,
				]),
			)),
	);

	@Effect()
	loadAll$: Observable<Action> = this.actions$.pipe(
		ofType(permActions.ActionTypes.LOAD_ALL),
		withLatestFrom(this.userService.activeUser$),
		filter(([action, user]: [permActions.LoadAll, User]) => !!user),
		switchMap(([action, user]) =>
			this.httpService.fetch({
				...action.payload,
				uid: user.id,
				size: 'all',
			})
			.pipe(
				switchMap(response => {
					return [
						new permActions.AddMany({permissions: response}),
						new permActions.LoadAllSuccess({permissions: response}),
					];
				}),
				catchError(error => [
					new permActions.Error(new NGRXError(action, error)),
					new permActions.LoadAllFailed,
				]),
			)),
	);

	@Effect()
	get$: Observable<Action> = this.actions$.pipe(
		ofType(permActions.ActionTypes.GET),
		withLatestFrom(this.userService.activeUser$),
		filter(([action, user]: [permActions.Get, User]) => !!user),
		switchMap(([action, user]) =>
			this.httpService.show(action.payload.id, {uid: user.id})
			.pipe(
				switchMap(response => {
					return [
						new permActions.GetSuccess({permission: response}),
					];
				}),
				catchError(error => [
					new permActions.Error(new NGRXError(action, error)),
				]),
			)),
	);

	@Effect()
	update$: Observable<Action> = this.actions$.pipe(
		ofType(permActions.ActionTypes.UPDATE),
		withLatestFrom(this.userService.activeUser$),
		filter(([action, user]: [permActions.Update, User]) => !!user),
		switchMap(([action, user]) => this.store.pipe(
			select(roleSelectors.getEntitiesByIds(action.payload.changed.role_ids)),
			map(roles => [action,user,roles] as [permActions.Update, User, Role[]]),
			take(1),
		)),
		mergeMap(([action, user, roles]) => {
			let request: IPermissionRequestUpdateParams = {
				uid: user.id,
				label: action.payload.changed.label,
				description: action.payload.changed.description,
				group_id: action.payload.changed.group_id,
				roles: roles.map(role => role.name),
			};
			return this.httpService.update(action.payload.origin.id, request).pipe(
				map(response => new permActions.UpdateSuccess({origin: action.payload.origin, changed: response})),
				catchError(error => [
					new permActions.Error(new NGRXError(action, error)),
				]),
			);
		}),
	);

	@Effect()
	updateSuccess$: Observable<Action> = this.actions$.pipe(
		ofType(permActions.ActionTypes.UPDATE_SUCCESS),
		withLatestFrom(this.userService.activeUser$),
		switchMap(([action, user]: [permActions.Update, User]) => {
			let actions: Action[] = [];
			let changed_rds = xor(action.payload.origin.role_ids, action.payload.changed.role_ids);

			if (changed_rds.length) {
				// mark roles which permissions was changed
				actions.push(new roleActions.MarkAsChanged({ids: changed_rds}));
				// Active User has one of these roles.
				if (intersection(user.roleIds, changed_rds).length) {
					actions.push(new auzActions.GetActiveUserPermissions);
				}
			}
			return actions;
		}),
	);

}
