import { isNil } from 'lodash';
import { ICustomerResponse } from './ICustomerRequestParams';
import { ICustomerActionHistoryResponse } from './ICustomerHistoryRequestParams';


export class CustomerActionHistory {
    id: number;
    customer_id: number;
    user_id: number;
    username: string;
    action: string;
    created: number; // timestamp

    origin: Partial<ICustomerResponse>;
    changes: Partial<ICustomerResponse>;

    constructor(data?: ICustomerActionHistoryResponse) {
        if (data) {
            this.createFromResponse(data);
        }
    }

    createFromResponse(data: ICustomerActionHistoryResponse) {
        this.id = data.id;
        this.customer_id = data.customer_id;
        this.user_id = data.user_id;
        this.username = data.user?.name;
        this.action = data.action;
        this.created = !isNil(data.created_at) ? (new Date(data.created_at)).getTime() : null;

        this.origin = data.customer_data && data.customer_data.origin ? data.customer_data.origin : null;
        this.changes = data.customer_data && data.customer_data.changes ? data.customer_data.changes : null;
    }
}
