import { Injectable } from "@angular/core";
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent, HttpResponse, HttpErrorResponse } from "@angular/common/http";
import { Observable, throwError } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { ErrorCatcherService } from './error-catcher.service';
import { ErrorResponse } from "./error-catcher.types";

@Injectable()
export class ErrorCatcherInterceptor implements HttpInterceptor {

	constructor(private errorCatcherService: ErrorCatcherService){}

	intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

		return next.handle(req).pipe(
			catchError( response => this.handleHttpErrorResponse( response ) ),
			map( response => this.handleHttpResponse( response ) ),
      // tap( response => this.handle( response ), response => this.handle( response ) ),
		);
	}

	handleHttpResponse<T>( response ): HttpEvent<T> {
		if(response instanceof HttpResponse && response.body && response.body.success === false){
			let error = new HttpErrorResponse({
				error: response.body,
				headers: response.headers,
				status: response.status,
				statusText: response.statusText,
				url: response.url,
			});
			this.process(error, error.error);
			throw error;			
		}
		return response;
	}

	handleHttpErrorResponse( response ): Observable<HttpEvent<any>> {
		this.process(response, response.error);
		return throwError(response);
	}

	process<T>(response: HttpResponse<T> | HttpErrorResponse, data: ErrorResponse): void {
		if( !this.errorCatcherService.has(response) ) this.errorCatcherService.add(response, data);		
	}

}
