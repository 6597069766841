import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, CanActivateChild, RouterStateSnapshot } from '@angular/router';
import { Observable, of, race } from 'rxjs';
import { select, Store } from '@ngrx/store';
import { State } from '../ngrx-store/reducers/role.reducer';
import { getError, getLoaded, getLoading } from '../ngrx-store/selectors/role.selectors';
import { LoadAll } from '../ngrx-store/actions/role.actions';
import { catchError, filter, map, switchMap, take } from 'rxjs/operators';


@Injectable({
    providedIn: 'root'
})
export class RoleGuard implements CanActivate, CanActivateChild {

    private loading = false;

    constructor(private store: Store<State>) {}

    canActivate(
        next: ActivatedRouteSnapshot,
        state: RouterStateSnapshot): Observable<boolean> {
        return this.loadRoles();
    }

    canActivateChild(
        next: ActivatedRouteSnapshot,
        state: RouterStateSnapshot) {
        return this.canActivate(next, state);
    }

    private loadRoles() {
        return this.store.pipe(
            select(getLoading),
            filter(loading => !loading),
            switchMap(loading => this.store.pipe(select(getLoaded))),
            switchMap(loaded => {
                if(!loaded && !this.loading){
                    this.loading = true;
                    this.store.dispatch(new LoadAll);
                }
                return race(
                    this.store.pipe(select(getLoaded), filter(e => e)),
                    this.store.pipe(select(getError), filter(error => error !== null)),
                );

            }),
            map(result => {
                this.loading = false;
                if(typeof result === "boolean") return result;
                return false;
            }),
            catchError((err) => {
                console.log( err );
                this.loading = false;
                return of(false);
            }),
            take(1),
        );
    }

}
