import { ICustomerExpenseSettingsResponse } from '@appRoot/core/customer/models/ICustomerExpenseSettingsRequestParams';


export class CustomerExpenseSettings {
	use_median: boolean = false;
	max_disc_perc: number = null;
	min_markup_perc: number = null;

	constructor(data?: ICustomerExpenseSettingsResponse) {
		if (data) {
            this.use_median = data.use_median;
            this.max_disc_perc = data.max_disc_perc;
            this.min_markup_perc = data.min_markup_perc;
		}
	}
}