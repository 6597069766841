import { createFeatureSelector, createSelector } from '@ngrx/store';

import * as fromReducers from '../reducers/navigation.reducer';

export const getState = createFeatureSelector<fromReducers.State>('navigation');

export const getHistory = createSelector(
  getState,
  (state) => state.history
);

export const getIndex = createSelector(
  getState,
  (state) => state.currentIndex
);

export const getCurrentUrl = createSelector(
  getHistory,
  getIndex,
  (history, index) => history[index]
);

