import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { SettingsService } from '@appRoot/core/services/settings.service';
import { ActivatedRoute } from '@angular/router';
import { of, Subject } from 'rxjs';
import { catchError, switchMap, takeUntil, tap } from 'rxjs/operators';
import { AuthenticationHttpService } from '@appRoot/core/authentication/services/authentication-http.service';
import { NgUnsubscribe } from '@appRoot/core/interfaces';


@Component({
    selector: 'app-register-page',
    templateUrl: './register.component.html',
    styleUrls: ['./register.component.scss'],
})
export class RegisterPageComponent implements OnInit, NgUnsubscribe {

    readonly ngUnsubscribe = new Subject();
    isRegistrationAllowed: boolean = false;
    tokenEmail: string;
    token: string;
    isCheckingToken: boolean = false;
    private checkRegistrationToken$ = new Subject<string>();

    constructor(
        public settings: SettingsService,
        private route: ActivatedRoute,
        private authHttp: AuthenticationHttpService,
        private cdRef: ChangeDetectorRef,
        ) {}

    ngOnInit() {
        this.checkRegistrationToken();
        if(this.settings.registrationMethod == 'disable') {
            this.isRegistrationAllowed = false;
        }
        if(this.settings.registrationMethod == 'public') {
            this.isRegistrationAllowed = true;
        }
        if(this.settings.registrationMethod == 'token' && this.route.snapshot.paramMap.has('token')) {
            let token = this.route.snapshot.paramMap.get('token');
            this.checkRegistrationToken$.next(token);
        }
    }

    ngOnDestroy() {
        this.unsubscribe();
    }

    unsubscribe(): void {
        this.ngUnsubscribe.next();
        this.ngUnsubscribe.complete();
    }

    private checkRegistrationToken(){
        this.checkRegistrationToken$.pipe(
            tap(token => { this.isCheckingToken = true; }),
            switchMap((token) => {
                return this.authHttp.registrationCheckToken(token).pipe(catchError(error => {
                    console.error(error);
                    return of({isValid: false, email: null, token: null})
                } ));
            }),
            takeUntil(this.ngUnsubscribe),
        ).subscribe(response => {
            if(response.isValid) {
                this.isRegistrationAllowed = true;
                this.tokenEmail = response.email;
                this.token = response.token;
            } else {
                this.isRegistrationAllowed = false;
            }
            this.isCheckingToken = false;
            this.cdRef.detectChanges();
        });
    }
}
