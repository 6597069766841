import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { CustomerGroupsChangeNotificationMain } from '../../models';
import { Actions, ActionTypes } from '../actions/customer-groups-change-notifications-main.actions';
import { NGRXError } from '@appRoot/core/ngrx-store/models/NGRXError';


function selectId(entity: CustomerGroupsChangeNotificationMain): number {
    return entity.id;
}

export const adapter: EntityAdapter<CustomerGroupsChangeNotificationMain> = createEntityAdapter<CustomerGroupsChangeNotificationMain>({
    selectId: selectId,
    sortComparer: false,
});

export interface State extends EntityState<CustomerGroupsChangeNotificationMain> {
    updating: boolean;
    loading: boolean;
    error: NGRXError<Actions>;
}

export const initialState: State = adapter.getInitialState({
    updating: false,
    loading: false,
    error: null,
});

export const entitySelectors = adapter.getSelectors();

export function reducer(state = initialState, action: Actions): State {
    switch (action.type) {

        case ActionTypes.GET_BY_GROUP_ID:
            return {
                ...state,
                loading: true,
            };
        case ActionTypes.GET_BY_GROUP_ID_SUCCESS:
            return adapter.upsertOne(
                action.payload,
                {
                    ...state,
                    loading: false
                }
            );
        case ActionTypes.GET_BY_GROUP_ID_FAILED:
            return {
                ...state,
                loading: false,
            };

        case ActionTypes.UPDATE:
            return {
                ...state,
                updating: true,
            };
        case ActionTypes.UPDATE_SUCCESS:
            return adapter.upsertOne(
                action.payload,
                {
                    ...state,
                    updating: false
                }
            );
        case ActionTypes.UPDATE_FAILED:
            return {
                ...state,
                updating: false,
            };

        case ActionTypes.ERROR:
            return {
                ...state,
                loading: false,
                error: action.payload,
            };

        default:
            return state;
    }
}
