import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { SettingsService } from '@appRoot/core/services/settings.service';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import {
    ICustomerRequestGetMT,
    ICustomerMailTemplates,
    ICustomerMailTemplateRequestUpdateRequiredParams,
    ICustomerMailTemplateRequestUpdate,
    ICustomerMailTemplateRequestCreate,
    ICustomerMailTemplateResponse,
    ICustomerMailTemplateRequestDelete
} from '../models';


@Injectable()
export class CustomerMailTemplatesHttpService {

    public readonly edge: string = 'customer-mail-templates';

    constructor(
        private http: HttpClient,
        private settings: SettingsService,
    ) { }

    fetch(data: ICustomerRequestGetMT): Observable<ICustomerMailTemplates> {
        let params = new HttpParams();
        params = params.append('uid', (data.uid).toString());

        return this.http.get<{ data: ICustomerMailTemplates }>(`${this.settings.API_PATH}/${this.edge}`, { params: params }).pipe(
            map(response => response.data),
        );
    }

    create<T extends ICustomerMailTemplateRequestCreate>(data: T): Observable<ICustomerMailTemplateResponse> {
        return this.http.post<{ data: ICustomerMailTemplateResponse }>(`${this.settings.API_PATH}/${this.edge}`, data).pipe(
            map(({ data }) => data)
        );
    }

    update(data: ICustomerMailTemplateRequestUpdateRequiredParams, changes: ICustomerMailTemplateRequestUpdate): Observable<ICustomerMailTemplateResponse> {
        return this.http.patch<{ data: ICustomerMailTemplateResponse }>(`${this.settings.API_PATH}/${this.edge}/${data.template_id}`, {...data, ...changes}).pipe(
            map(response => (response.data)),
        );
    }

    delete(id: number, requestData: ICustomerMailTemplateRequestDelete): Observable<boolean> {
        let params = new HttpParams({fromObject: <any>{...requestData}});
        return this.http.delete<{ data: boolean }>(`${this.settings.API_PATH}/${this.edge}/${id}`, {params: params}).pipe(
            map(({ data }) => data)
        );
    }
}

