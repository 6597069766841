import { NgModule } from '@angular/core';
import { PermissionHttpService } from './services/permission-http.service';
import { RoleHttpService } from './services/role-http.service';

@NgModule({
	declarations: [],
	imports: [],
	providers: [
		PermissionHttpService,
		RoleHttpService,
	],
})
export class RolesPermissionsModule { }
