import { Action } from '@ngrx/store';
import { User } from '@appRoot/core/user/models';


export enum ActionTypes {
    ERROR = '[Registration] error',
    REGISTRATION = '[Registration] registration',
    REGISTRATION_SUCCESS = '[Registration] registration success',
    REGISTRATION_FAILED = '[Registration] registration failed',
    REGISTRATION_COMPLETE_RESET = '[Registration] complete reset',
}

export class Error implements Action {
    readonly type = ActionTypes.ERROR;
    constructor(readonly payload: {error: any, action: Actions}) { }
}

export class Registration implements Action {
    readonly type = ActionTypes.REGISTRATION;
    constructor(readonly payload: {name: string, email: string, password: string, password_confirmation: string, token?: string} ) { }
}

export class RegistrationSuccess implements Action {
    readonly type = ActionTypes.REGISTRATION_SUCCESS;
    constructor(readonly payload: {user: User} ) { }
}

export class RegistrationFailed implements Action {
    readonly type = ActionTypes.REGISTRATION_FAILED;
}

export class CompleteReset implements Action {
    readonly type = ActionTypes.REGISTRATION_COMPLETE_RESET;
}


export type Actions =
    | Error
    | Registration
    | RegistrationSuccess
    | RegistrationFailed
    | CompleteReset
    ;
