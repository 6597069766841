import { Directive, ElementRef, HostListener } from '@angular/core';
import { Router } from '@angular/router';

@Directive({     
    selector: '[routeLink]'
})

export class routeLinkDirective {

    constructor(private el: ElementRef, private router: Router) { }

    @HostListener('click', ['$event']) onClick($event){
        let goRoute = $event.target.getAttribute('data-link');
        if(goRoute){
            this.router.navigate([goRoute]);
            event.preventDefault();
        }
    }


}