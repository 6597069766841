export interface IPermissionResponse {
    id: number;
    name: string;
    label: string;
    description: string;
    group_id: number;
    role_ids: number[];
    created_at: number;
    updated_at: number;
}

export class Permission {
    id: number;
    name: string;
    label: string;
    description: string;
    group_id: number = null;
    role_ids: number[] = [];
    changed: boolean = false;

    constructor(response?: IPermissionResponse){
        if(response){
            this.id = +response.id;
            this.name = response.name;
            this.label = response.label;
            this.description = response.description;
            this.group_id = response.group_id;
            this.role_ids = response.role_ids;
        }
    }
}
