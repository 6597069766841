import { createSelector } from '@ngrx/store';

import * as fromReducer from '../reducers/authorization.reducer';
import { ModuleStore } from '../reducers';


export const getStore = createSelector(
    ModuleStore,
    (store) => store.authorization
);

export const getLoading = createSelector(
    getStore,
    (state) => state.loading
);

export const getError = createSelector(
    getStore,
    (state) => state.error
);

export const isAuthorized = createSelector(
    getStore,
    (state) => state.isAuthorized
);

export const getArray = createSelector(
    getStore,
    fromReducer.EntitySelectors.selectAll
);
export const getDictionary = createSelector(
    getStore,
    fromReducer.EntitySelectors.selectEntities
);

export const getEntityById = (userId: number) => {
    return createSelector(
        getArray,
        (entities) => entities.find(i => i.user_id === userId)
    );
};

export const getEntitiesByIds = (userIds: number[]) => {
    return createSelector(
        getDictionary,
        (dictionary) => userIds.reduce((acc, key) => dictionary[key] ? [...acc, dictionary[key]] : acc, [])
    );
};
