import {IWhmAccountStatisticsResponse} from './WhmAccount';

export class WhmAccountStatistics {
    error: string | null;
    usage: number;
    id: string;
    formatter: string;
    maximum: string | number | null;
    description: string;
    url: string | null;

    constructor(data: IWhmAccountStatisticsResponse) {
        if(data) {
            this.createFromResponse(data);
        }
    }

    createFromResponse(data: IWhmAccountStatisticsResponse) {
        this.error = data.error;
        this.usage = data.usage;
        this.id = data.id;
        this.formatter = data.formatter;
        this.maximum = data.maximum;
        this.description = data.description;
        this.url = data.url;
    }
}
