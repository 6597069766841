import { IPaginatedResponse } from '@appRoot/core/interfaces';
import { NGRXError } from '@appRoot/core/ngrx-store/models/NGRXError';
import { Actions, ActionTypes } from '../actions/customer-contacts.actions';
import { CustomerContacts } from "@appRoot/core/customer/models";
import { createEntityAdapter, EntityAdapter } from "@ngrx/entity";


export interface State {
    loading: boolean;
    complete: boolean,
    creating: boolean;
    getting: boolean;
    updating: boolean;
    deleting: boolean;
    restoring: boolean;
    error: NGRXError<Actions>;
    pagination: IPaginatedResponse<number[]>;
}

export const initialState: State = {
    loading: false,
    complete: false,
    creating: false,
    getting: false,
    updating: false,
    deleting: false,
    restoring: false,
    error: null,
    pagination: null,
};

function selectId(entity: CustomerContacts): number {
    return entity.id;
}

export const adapter: EntityAdapter<CustomerContacts> = createEntityAdapter<CustomerContacts>({
    selectId: selectId,
    sortComparer: false,
});

export const entitySelectors = adapter.getSelectors();

export function reducer(state = initialState, action: Actions): State {
    switch (action.type) {

        case ActionTypes.ERROR:
            return {
                ...state,
                error: action.payload
            };

        case ActionTypes.RESET:
            return {
                ...initialState
            };

        case ActionTypes.LOAD:
            return {
                ...state,
                loading: true,
            };
        case ActionTypes.LOAD_SUCCESS:
        case ActionTypes.LOAD_FAILED:
            return {
                ...state,
                loading: false,
                complete: true,
            };
        case ActionTypes.RELOAD:
            return {
                ...state,
                complete: false,
            };
        case ActionTypes.PAGINATION_SET:
            return {
                ...state,
                pagination: action.payload,
            };

        case ActionTypes.CREATE:
            return {
                ...state,
                creating: true,
            };
        case ActionTypes.CREATE_SUCCESS:
        case ActionTypes.CREATE_FAILED:
            return {
                ...state,
                creating: false,
            };

        case ActionTypes.GET_BY_ID:
            return {
                ...state,
                getting: true,
            };
        case ActionTypes.GET_BY_ID_SUCCESS:
        case ActionTypes.GET_BY_ID_FAILED:
            return {
                ...state,
                getting: false,
            };

        case ActionTypes.UPDATE:
            return {
                ...state,
                updating: true,
            };
        case ActionTypes.UPDATE_SUCCESS:
        case ActionTypes.UPDATE_FAILED:
            return {
                ...state,
                updating: false,
            };

        case ActionTypes.DELETE:
            return {
                ...state,
                deleting: true,
            };
        case ActionTypes.DELETE_SUCCESS:
        case ActionTypes.DELETE_FAILED:
            return {
                ...state,
                deleting: false,
            };

        case ActionTypes.RESTORE:
            return {
                ...state,
                restoring: true,
            };
        case ActionTypes.RESTORE_SUCCESS:
        case ActionTypes.RESTORE_FAILED:
            return {
                ...state,
                restoring: false,
            };

        default:
            return state;
    }
}
