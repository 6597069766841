import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { SettingsService } from '@appRoot/core/services/settings.service';
import { Subject } from 'rxjs';
import { Router } from "@angular/router";
import { select, Store } from "@ngrx/store";
import * as selfSignupSelectors from "@appRoot/lazy-modules/self-signup/ngrx-store/selectors/self-signup.selectors";
import { takeUntil } from "rxjs/operators";
import { fadeInAnimation } from "@appRoot/lazy-modules/self-signup/_animations";


@Component({
    selector: 'app-step-progress',
    templateUrl: './step-progress.component.html',
    styleUrls: ['./step-progress.component.scss'],
    animations: [ fadeInAnimation ],
    host: {'[@fadeInAnimation]': ''}
})
export class StepProgressContainer implements OnInit {

    readonly ngUnsubscribe = new Subject();

    public loadingStepService: boolean = false;
    public loadingStepRegister: boolean = false;
    public loadingStepPayment: boolean = false;
    public loadingStepCompletion: boolean = false;

    constructor(
        private router: Router,
        public settings: SettingsService,
        private store: Store<any>,
        private cdr: ChangeDetectorRef
    ) { }

    ngOnInit() {
        this.loadingStepService$().subscribe(loading => {
            this.loadingStepService = loading;
            this.cdr.detectChanges();
        });

        this.loadingStepRegister$().subscribe(loading => {
            this.loadingStepRegister = loading;
            this.cdr.detectChanges();
        });

        this.loadingStepPayment$().subscribe(loading => {
            this.loadingStepPayment = loading;
            this.cdr.detectChanges();
        });

        this.loadingStepCompletion$().subscribe(loading => {
            this.loadingStepCompletion = loading;
            this.cdr.detectChanges();
        });
    }

    ngOnDestroy(): void {
        this.unsubscribe();
    }

    unsubscribe() {
        this.ngUnsubscribe.next();
        this.ngUnsubscribe.complete();
    }

    private loadingStepService$() {
        return this.store.pipe(
            select(selfSignupSelectors.getStepService),
            takeUntil(this.ngUnsubscribe)
        );
    }

    private loadingStepRegister$() {
        return this.store.pipe(
            select(selfSignupSelectors.getStepRegister),
            takeUntil(this.ngUnsubscribe)
        );
    }

    private loadingStepPayment$() {
        return this.store.pipe(
            select(selfSignupSelectors.getStepPayment),
            takeUntil(this.ngUnsubscribe)
        );
    }

    private loadingStepCompletion$() {
        return this.store.pipe(
            select(selfSignupSelectors.getStepCompletion),
            takeUntil(this.ngUnsubscribe)
        );
    }
}