import {
	ICustomerResponse,
	ICustomerGetSubscriptionResponse,
	Invoice,
	ICustomerActionHistoryResponse,
	ICustomerContactActionHistoryResponse,
	CustomerEmailLogs,
  OpenExpenses,
	TimeTracking
} from '@appRoot/core/customer/models';
import { User } from "@appRoot/core/user/models";
import { isNil } from 'lodash';

export class Customer {
	id: number = null;
	source_id: number = null;
	number: string = null;
	name: string = null;
	status: string = 'active';
	email: string = null;
	billingEmail: string = null;
	users: User[] = [];
	docs_link: string = null;
	created: Date = null;
	updated: Date = null;
	deleted: Date = null;
	emailLogs?: Partial<CustomerEmailLogs>[]|null = null;
	subscription?: Partial<ICustomerGetSubscriptionResponse>[]|null = null;
	invoice?: Partial<Invoice>[]|null = null;
	openExpenses?: Partial<OpenExpenses>[] | null = null;
	timeTracking?: Partial<TimeTracking>[]|null = null;
	paymentMode?: string = null;
	creditCard?: string = null;
	creditName?: string = null;
	creditExpDate?: string = null;
	action_history: Partial<ICustomerActionHistoryResponse>[] = [];
	action_contact_history: Partial<ICustomerContactActionHistoryResponse>[] = [];

	constructor(data?: ICustomerResponse) {
		if (data) {
            this.id = data.id;
            this.source_id = data.source_id;
            this.number = data.number;
            this.name = data.name;
			this.status = data.status;
			this.email = data.email;
			this.billingEmail = data.billingEmail;
			this.docs_link = data.docs_link;
            this.created = data.created_at? new Date(data.created_at*1000): null;
            this.updated = data.updated_at? new Date(data.updated_at*1000): null;
            this.deleted = data.deleted_at? new Date(data.deleted_at*1000): null;

			data.users.map((user) => {
				this.users.push(new User(user));
			});

			this.action_history = data.action_history.map(item => ({
				user_id: item.user_id,
				action: item.action,
				created: !isNil(item.created_at) ? new Date(item.created_at) : null
			}));

			this.action_contact_history = data.action_contact_history.map(item => ({
				contact_id: item.contact_id,
				user_id: item.user_id,
				action: item.action,
				created: !isNil(item.created_at) ? new Date(item.created_at) : null
			}));
		}
	}
}