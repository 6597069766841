<div class="item user-block" *ngIf="userBlockIsVisible()">
    <!-- User picture-->
    <div class="user-block-picture">
        <div class="user-block-status">
            <img class="img-thumbnail rounded-circle" [src]="userData?.avatar" alt="Avatar" />
            <div class="circle bg-success circle-lg"></div>
        </div>
    </div>
    <!-- Name and Job-->
    <div class="user-block-info">
        <span class="user-block-name">{{ user?.name }}</span>
    </div>
</div>

<app-logout></app-logout>
