import { Component } from '@angular/core';
import { SettingsService } from '@appRoot/core/services/settings.service';


@Component({
    selector: 'app-recover-page',
    templateUrl: './recover.component.html',
    styleUrls: ['./recover.component.scss']
})
export class RecoverPageComponent {
    constructor(public settings: SettingsService) {}
}
