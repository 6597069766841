<div
    containerClass="tooltip-container"
    #pop="bs-tooltip"
    [tooltip]="tooltipTemplate"
    class="user-info-tooltip"
    triggers="">
</div>

<ng-template #tooltipTemplate>

    <div *ngIf="!user" class="fa-1x alert alert-light border mb-0">
        <fa-icon [icon]="faSpinner" [pulse]="true"></fa-icon>
    </div>

    <ng-container *ngIf="user">
        <app-user-short-info [user]="user"></app-user-short-info>
    </ng-container>

</ng-template>
