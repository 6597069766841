import { Action } from '@ngrx/store';
import { NGRXError } from '@appRoot/core/ngrx-store/models/NGRXError';
import { IPermissionGroup } from '../../models';

export enum ActionTypes {
    LOAD = '[PermissionGroup] load',
    LOAD_SUCCESS = '[PermissionGroup] load success',
    LOAD_FAILED = '[PermissionGroup] load failed',
    REMOVE = '[PermissionGroup] remove',
    REMOVE_SUCCESS = '[PermissionGroup] remove success',
    REMOVE_FAILED = '[PermissionGroup] remove failed',
    CREATE = '[PermissionGroup] create',
    CREATE_SUCCESS = '[PermissionGroup] create success',
    UPDATE = '[PermissionGroup] update',
    UPDATE_SUCCESS = '[PermissionGroup] update success',
    ERROR = '[PermissionGroup] error',
    RESET = '[PermissionGroup] reset',
}

export class Load implements Action {
    readonly type = ActionTypes.LOAD;
}

export class LoadSuccess implements Action {
    readonly type = ActionTypes.LOAD_SUCCESS;

    constructor(readonly payload: { permissionGroups: IPermissionGroup[] }) { }
}

export class LoadFailed implements Action {
    readonly type = ActionTypes.LOAD_FAILED;
}

export class Remove implements Action {
    readonly type = ActionTypes.REMOVE;

    constructor(readonly payload: { permissionGroup: IPermissionGroup }) { }
}

export class RemoveSuccess implements Action {
    readonly type = ActionTypes.REMOVE_SUCCESS;

    constructor(readonly payload: { permissionGroup: IPermissionGroup }) { }
}

export class RemoveFailed implements Action {
    readonly type = ActionTypes.REMOVE_FAILED;

    constructor(readonly payload: { permissionGroup: IPermissionGroup }) { }
}

export class Create implements Action {
    readonly type = ActionTypes.CREATE;

    constructor(readonly payload: { permissionGroup: IPermissionGroup }) { }
}

export class CreateSuccess implements Action {
    readonly type = ActionTypes.CREATE_SUCCESS;

    constructor(readonly payload: { permissionGroup: IPermissionGroup }) { }
}

export class Update implements Action {
    readonly type = ActionTypes.UPDATE;

    constructor(readonly payload: { permissionGroup: IPermissionGroup }) { }
}

export class UpdateSuccess implements Action {
    readonly type = ActionTypes.UPDATE_SUCCESS;

    constructor(readonly payload: { permissionGroup: IPermissionGroup }) { }
}

export class Error implements Action {
    readonly type = ActionTypes.ERROR;

    constructor(readonly payload: NGRXError<Actions>) { }
}

export class Reset implements Action {
    readonly type = ActionTypes.RESET;
}

export type Actions =
    | Remove
    | RemoveSuccess
    | RemoveFailed
    | Create
    | CreateSuccess
    | Update
    | UpdateSuccess
    | Error
    | Reset
    | Load
    | LoadSuccess
    | LoadFailed
    ;
