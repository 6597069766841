import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { IPaginatedResponse } from '@appRoot/core/interfaces';
import { IPermissionResponse, IRoleResponse, Permission, Role } from '@appRoot/core/roles-permissions/models/';
import { SettingsService } from '@appRoot/core/services/settings.service';
import { isBoolean, isEmpty, isNumber } from 'lodash';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import {
    IUserRequestDestroyParams,
    IUserRequestDestroySelfParams,
    IUserRequestGetActiveUserPermissionsParams,
    IUserRequestGetByParams,
    IUserRequestGetPermissionsParams,
    IUserRequestGetRolesParams,
    IUserRequestIndexParams,
    IUserRequestRestoreParams,
    IUserRequestRestoreSelfParams,
    IUserRequestShowParams,
    IUserRequestStoreParams,
    IUserRequestUpdatePermissionsParams,
    IUserRequestUpdateRequiredParams,
    IUserRequestUpdateRolesParams,
    IUserRequestUpdateSelf,
    IUserRequestUpdateWithPermission,
    IUserResponse
} from '../models';


@Injectable()
export class UserHttpService {

    public readonly edge: string = 'users';

    constructor(
        private http: HttpClient,
        private settings: SettingsService,
    ) { }

    getCurrent(): Observable<IUserResponse> {
        return this.http.get<{ data: IUserResponse }>(`${this.settings.API_PATH}/${this.edge}/current`).pipe(
            map(response => response.data),
        );
    }

    fetch(data: IUserRequestIndexParams): Observable<IPaginatedResponse<IUserResponse[]>> {
        let params = new HttpParams();

        params = params.append('uid', ''+data.uid);
        params = data.page ? params.append('page[number]', ''+data.page) : params;
        params = data.size ? params.append('page[size]', ''+data.size) : params;
        params = data.sort ? params.append('sort', data.sort) : params;
        params = data.order ? params.append('order',data.order) : params;
        params = data.status ? params.append('status', data.status) : params;

        if (data.search) {
            for (let prop in data.search) {
                // noinspection JSUnfilteredForInLoop
                let value = isBoolean(data.search[prop]) ? +data.search[prop] : data.search[prop];
                params = !isNumber(value) && isEmpty(value) ? params : params.append('search[' + prop + ']', ''+value);
            }
        }
        if (data.roles) {
            data.roles.forEach(role => {
                params = params.append('roles[]', role.name);
            });
        }

        return this.http.get<{ data: IPaginatedResponse<IUserResponse[]> }>(`${this.settings.API_PATH}/${this.edge}`, {params: params}).pipe(
            map(response => response.data),
        );
    }

    create(data: IUserRequestStoreParams): Observable<IUserResponse> {
        return this.http.post<{ data: IUserResponse }>(`${this.settings.API_PATH}/${this.edge}`, data).pipe(
            map(response => response.data),
        );
    }

    show(data: IUserRequestShowParams): Observable<IUserResponse> {
        let params = new HttpParams({ fromObject: <any>{ ...data } });

        return this.http.get<{ data: IUserResponse }>(`${this.settings.API_PATH}/${this.edge}/${data.user_id}`, {params: params}).pipe(
            map(response => response.data),
        );
    }

    update(data: IUserRequestUpdateRequiredParams, changes: IUserRequestUpdateWithPermission | IUserRequestUpdateSelf): Observable<IUserResponse> {
        return this.http.patch<{ data: IUserResponse }>(`${this.settings.API_PATH}/${this.edge}/${data.user_id}`, {...data, ...changes}).pipe(
            map(response => (response.data)),
        );
    }

    destroy(ids: number[], data: IUserRequestDestroyParams): Observable<IUserResponse[]> {
        let idsStr = ids.join(',');
        let params = new HttpParams();
        params = params.append('uid', <any>data.uid);
        if(data.force){
            params = params.append('force', <any>+data.force)
        }
        return this.http.delete<{ data: IUserResponse[] }>(`${this.settings.API_PATH}/${this.edge}/${idsStr}`, {params: params}).pipe(
            map(response => response.data),
        );
    }

    destroySelf(data: IUserRequestDestroySelfParams): Observable<IUserResponse> {
        let params = new HttpParams({ fromObject: <any>{ ...data } });
        return this.http.delete<{ data: IUserResponse }>(`${this.settings.API_PATH}/${this.edge}/delete-self`, {params: params}).pipe(
            map(response => (response.data)),
        );
    }

    restore(ids: number[], data: IUserRequestRestoreParams): Observable<IUserResponse[]> {
        let idsStr = ids.join(',');
        return this.http.patch<{ data: IUserResponse[] }>(`${this.settings.API_PATH}/${this.edge}/${idsStr}/restore`, data).pipe(
            map(response => response.data),
        );
    }

    restoreSelf(data: IUserRequestRestoreSelfParams): Observable<IUserResponse> {
        return this.http.patch<{ data: IUserResponse }>(`${this.settings.API_PATH}/${this.edge}/restore`, data).pipe(
            map(response => response.data),
        );
    }

    fetchBy(data: IUserRequestGetByParams): Observable<IUserResponse[]> {
        return this.http.get<{ data: IUserResponse[] }>(`${this.settings.API_PATH}/${this.edge}/by/?${data.key}=${data.value}`).pipe(
            map(response => response.data),
        );
    }

    //Roles & Permissions
    getUserPermissions(id: number, data: IUserRequestGetPermissionsParams): Observable<Permission[]> {
        let params = new HttpParams({ fromObject: <any>{ ...data } });
        return this.http.get<{ data: IPermissionResponse[] }>(`${this.settings.API_PATH}/${this.edge}/${id}/permissions`, {params: params}).pipe(
            map(response => response.data.map(e => new Permission(e))),
        );
    }

    updateUserPermissions(id: number, data: IUserRequestUpdatePermissionsParams): Observable<Permission[]> {
        const body = {
            uid: data.uid,
            permissions: data.permissions.map(item => item.name),
        };
        return this.http.patch<{ data: IPermissionResponse[] }>(`${this.settings.API_PATH}/${this.edge}/${id}/permissions`, body).pipe(
            map(response => response.data.map(e => new Permission(e))),
        );
    }

    getUserRoles(id: number, data: IUserRequestGetRolesParams): Observable<Role[]> {
        let params = new HttpParams({ fromObject: <any>{ ...data } });
        return this.http.get<{ data: IRoleResponse[] }>(`${this.settings.API_PATH}/${this.edge}/${id}/roles`, {params: params}).pipe(
            map(response => response.data.map(e => new Role(e))),
        );
    }

    updateUserRoles(id: number, data: IUserRequestUpdateRolesParams): Observable<Role[]> {
        const body = {
            uid: data.uid,
            roles: data.roles.map(item => item.name)
        };
        return this.http.patch<{ data: IRoleResponse[] }>(`${this.settings.API_PATH}/${this.edge}/${id}/roles`, body).pipe(
            map(response => response.data.map(e => new Role(e))),
        );
    }

    getActiveUserPermissions(data: IUserRequestGetActiveUserPermissionsParams): Observable<Permission[]> {
        let params = new HttpParams({ fromObject: <any>{ ...data } });
        return this.http.get<{ data: IPermissionResponse[] }>(`${this.settings.API_PATH}/${this.edge}/active-permissions`, {params: params}).pipe(
            map(response => response.data.map(e => new Permission(e))),
        );
    }
}

