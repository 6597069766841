import { Action } from '@ngrx/store';
import { CustomerSystemMessages } from '@appRoot/core/customer/models';
import { NGRXError } from '@appRoot/core/ngrx-store/models/NGRXError';


export enum ActionTypes {
    GET = '[Customer System Messages] get',
    GET_SUCCESS = '[Customer System Messages] get success',

    ERROR = '[Customer System Messages] error',

    UPSERT_MANY = '[Customer System Messages] UPSERT_MANY',

    REMOVE_MANY_BY_CUSTOMER_IDS = '[Customer System Messages] REMOVE_MANY_BY_CUSTOMER_IDS',
}

export class Get implements Action {
    readonly type = ActionTypes.GET;

    constructor(readonly payload: number) {}
}

export class GetSuccess implements Action {
    readonly type = ActionTypes.GET_SUCCESS;

    constructor(readonly payload: CustomerSystemMessages[]) {}
}

export class Error implements Action {
    readonly type = ActionTypes.ERROR;

    constructor(readonly payload: NGRXError<Actions>) { }
}

export class UpsertMany implements Action {
    readonly type = ActionTypes.UPSERT_MANY;

    constructor(readonly payload: CustomerSystemMessages[]) {}
}

export class RemoveManyByCustomerIds implements Action {
    readonly type = ActionTypes.REMOVE_MANY_BY_CUSTOMER_IDS;

    constructor(readonly payload: number[]) {}
}

export type Actions =
    | Get
    | GetSuccess
    | Error
    | UpsertMany
    | RemoveManyByCustomerIds
    ;
