import {
    Component,
    Input,
    Output,
    EventEmitter,
    OnInit,
    OnChanges,
    SimpleChange,
    ElementRef,
    HostListener
} from '@angular/core';

@Component({
    selector: 'app-navsearch',
    templateUrl: './navsearch.component.html',
    styleUrls: ['./navsearch.component.scss']
})
export class NavsearchComponent implements OnInit, OnChanges {

    @Input() visible: boolean;
    @Output() onclose = new EventEmitter<boolean>();
    term: string;

    @HostListener('document:keydown.escape', ['$event']) onEscapeClick(event: KeyboardEvent) {
        this.closeNavSearch();
    }
    @HostListener('document:click', ['$event']) onDocumentClick(event: KeyboardEvent) {
        if (!this.elem.nativeElement.contains(event.target)) {
            this.closeNavSearch();
        }
    }

    constructor(public elem: ElementRef) { }

    ngOnInit() { }

    closeNavSearch() {
        this.visible = false;
        this.onclose.emit();
        console.log('close nav search');
    }

    ngOnChanges(changes: { [propKey: string]: SimpleChange }) {
        if (changes['visible'].currentValue === true) {
            this.elem.nativeElement.querySelector('input').focus();
        }
    }

    handleForm() {
        console.log('Form submit: ' + this.term);
    }
}
