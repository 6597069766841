import { ChangeDetectionStrategy, Component, OnDestroy, OnInit } from '@angular/core';


export type Bs4AlertMessages = {
    [key: string]: string[]
};

export type Bs4AlertType = 'danger' | 'info' | 'success' | 'warning' | 'dark';

@Component({
    selector: 'app-bs4-alert',
    templateUrl: './bs4-alert.component.html',
    styleUrls: ['./bs4-alert.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    inputs: ['message', 'type', 'messages'],
})
export class Bs4AlertComponent implements OnInit, OnDestroy {

    public message: string = null;
    public messages: Bs4AlertMessages = null;
    public type: Bs4AlertType = 'success';

    constructor() {}

    ngOnInit() {
    }

    ngOnDestroy() {
    }

}
