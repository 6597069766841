import { Injectable } from '@angular/core';
import { NGRXError } from '@appRoot/core/ngrx-store/models/NGRXError';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { Action, Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { catchError, switchMap } from 'rxjs/operators';
import { SelfSignupHttpService } from "@appRoot/lazy-modules/self-signup/services/self-signup-http.service";
import * as selfSignupActions from "@appRoot/lazy-modules/self-signup/ngrx-store/actions/self-signup.actions";
import { ISelfSignupRequestCreateParams } from "@appRoot/lazy-modules/self-signup/models";
import * as selfSignupAccountsActions from "@appRoot/lazy-modules/self-signup/ngrx-store/actions/self-signup-accounts.actions";


@Injectable()
export class SelfSignupEffects {

    constructor(
        private actions$: Actions,
        private httpService: SelfSignupHttpService,
        private store: Store<any>
    ) {}

    @Effect()
    load$: Observable<Action> = this.actions$.pipe(
        ofType(selfSignupActions.ActionTypes.LOAD),
        switchMap((action: selfSignupActions.Load) =>
            this.httpService.fetch().pipe(
                switchMap(response => {
                    return [
                        new selfSignupActions.LoadSuccess(response),
                    ];
                }),
                catchError(error => [
                    new selfSignupActions.Error(new NGRXError(action, error)),
                    new selfSignupActions.LoadFailed
                ])
            )
        )
    );

    @Effect()
    getUserCustomers$: Observable<Action> = this.actions$.pipe(
        ofType(selfSignupActions.ActionTypes.GET_USER_CUSTOMERS),
        switchMap((action: selfSignupActions.GetUserCustomers) =>
            this.httpService.getCustomerUsers(action.email).pipe(
                switchMap(response => {
                    return [
                        new selfSignupActions.GetUserCustomersSuccess(response),
                    ];
                }),
                catchError(error => [
                    new selfSignupActions.Error(new NGRXError(action, error)),
                    new selfSignupActions.GetUserCustomersFailed()
                ])
            )
        )
    );

    @Effect()
    create$: Observable<Action> = this.actions$.pipe(
        ofType(selfSignupActions.ActionTypes.CREATE),
        switchMap((action: selfSignupActions.Create) => {
            let params: ISelfSignupRequestCreateParams = {
                customer_number: action.payload.customer_number
            };

            if (action.payload.customers) {
                params.customers = {
                    number: action.payload.customers.number,
                    name: action.payload.customers.name,
                    contact_email: action.payload.customers.contact_email,
                    phone: action.payload.customers.phone,
                    address: action.payload.customers.address,
                    city: action.payload.customers.city,
                    postal_code: action.payload.customers.postal_code
                };
            }

            if (action.payload.account) {
                params.account = {
                    package_id: action.payload.account.package_id,
                    domain: action.payload.account.domain
                };
            }

            if (action.payload.payment) {
                params.payment = {
                    name: action.payload.payment.name,
                    creditCard: action.payload.payment.creditCard,
                    expirationDate: action.payload.payment.expirationDate
                };
            }

            if (action.payload.uid) {
                params.uid = action.payload.uid;
            }


            return this.httpService.create(params).pipe(
                switchMap(response => {
                    return [
                        new selfSignupActions.CreateSuccess,
                        new selfSignupAccountsActions.SetCustomerId(response),
                    ];
                }),
                catchError(error => [
                    new selfSignupActions.Error(new NGRXError(action, error)),
                    new selfSignupActions.CreateFailed
                ])
            );
        })
    );
}
