import { Injectable } from '@angular/core';
import { NavItem } from './NavItem';

@Injectable()
export class NavItemService {

    navItems: NavItem[] = [];

    constructor() {}

    addItems(items: NavItem[]) {
        this.navItems = items;
    }

    addItem(item: NavItem){
        this.navItems.push(item);
    }

    removeItem(index: number){
        this.navItems.splice(index, 1)
    }

    getMenu() {
        return this.navItems;
    }

}
