import { Injectable } from '@angular/core';
import * as permissionActions from '@appRoot/core/roles-permissions/ngrx-store/actions/permission.actions';
import * as roleActions from '@appRoot/core/roles-permissions/ngrx-store/actions/role.actions';
import { genericRetryStrategy } from "@appRoot/core/utils";
import { Actions, Effect, ofType } from '@ngrx/effects';
import { Action, Store } from '@ngrx/store';
import { NgxPermissionsService, NgxRolesService } from 'ngx-permissions';

import { concat, Observable, of as observableOf } from 'rxjs';
import { catchError, delay, filter, map, retryWhen, switchMap, tap, withLatestFrom } from 'rxjs/operators';

import { User } from '../../models';
import { UserHttpService } from '../../services/user-http.service';
import { UserService } from '../../services/user.service';
import * as auzActions from '../actions/authorization.actions';
import * as userStorageActions from '../actions/user-storage.actions';
import * as userSelectors from '../selectors/user.selectors';


@Injectable()
export class AuthorizationEffects {

    constructor(
        private actions$: Actions,
        private httpService: UserHttpService,
        private globalPermissions: NgxPermissionsService,
        private globalRoles: NgxRolesService,
        private userService: UserService,
        private store: Store<any>,
    ) {}

    @Effect()
    getActiveUserPermissions$: Observable<Action> = this.actions$.pipe(
        ofType(auzActions.ActionTypes.GET_ACTIVE_USER_PERMISSIONS),
        withLatestFrom(this.userService.activeUser$),
        filter(([action, user]) => !!user),
        switchMap(([action, user]: [auzActions.GetActiveUserPermissions, User]) => {
            this.globalPermissions.flushPermissions();
            return this.httpService.getActiveUserPermissions({uid: user.id}).pipe(
                retryWhen(genericRetryStrategy({
                    scalingDuration: 300,
                    excludedStatusCodes: [401],
                    maxRetryAttempts: 2,
                })),
                switchMap((permissions) => concat(
                    observableOf(new permissionActions.AddMany({permissions: permissions})),
                    observableOf(new auzActions.GetActiveUserPermissionsSuccess({
                        user: user,
                        permissions: permissions
                    })),
                )),
                catchError(error => observableOf(new auzActions.Error({action: action, error: error}))),
            )}
        )
    );

    @Effect()
    getUserPermissions$: Observable<Action> = this.actions$.pipe(
        ofType(auzActions.ActionTypes.GET_USER_PERMISSIONS),
        withLatestFrom(this.userService.activeUser$),
        filter(([action, user]: [auzActions.GetUserPermissions, User]) => !!user),
        switchMap(([action, user]) =>
            this.httpService.getUserPermissions(action.payload.user.id, {uid: user.id}).pipe(
                retryWhen(genericRetryStrategy({
                    scalingDuration: 300,
                    excludedStatusCodes: [401],
                    maxRetryAttempts: 2,
                })),
                switchMap((permissions) => [
                    new permissionActions.AddMany({permissions: permissions}),
                    new auzActions.GetUserPermissionsSuccess({
                        user: action.payload.user,
                        permissions: permissions
                    }),
                ]),
                catchError(error => observableOf(new auzActions.Error({action: action, error: error}))),
            )
        )
    );

    @Effect()
    updateUserPermissions$: Observable<Action> = this.actions$.pipe(
        ofType(auzActions.ActionTypes.UPDATE_USER_PERMISSIONS),
        withLatestFrom(this.userService.activeUser$),
        filter(([action, user]: [auzActions.UpdateUserPermissions, User]) => !!user),
        switchMap(([action, user]) =>
            this.httpService.updateUserPermissions(action.payload.user.id, {uid: user.id, permissions: action.payload.permissions}).pipe(
                switchMap((permissions) => [
                    new permissionActions.AddMany({permissions: permissions}),
                    new auzActions.UpdateUserPermissionsSuccess({
                        user: action.payload.user,
                        permissions: permissions
                    }),
                ]),
                catchError(error => observableOf(new auzActions.Error({action: action, error: error})))
            )
        )
    );

    @Effect({dispatch: false})
    userPermissionsChanged$ = this.actions$.pipe(
        ofType(auzActions.ActionTypes.GET_USER_PERMISSIONS_SUCCESS, auzActions.ActionTypes.UPDATE_USER_PERMISSIONS_SUCCESS, auzActions.ActionTypes.GET_ACTIVE_USER_PERMISSIONS_SUCCESS),
        withLatestFrom(this.userService.getActiveUserId$),
        tap(([action, id]: [auzActions.GetUserPermissionsSuccess | auzActions.UpdateUserPermissionsSuccess | auzActions.GetActiveUserPermissionsSuccess, number]) => {
            if (action.payload.user.id === id) {
                this.globalPermissions.loadPermissions(action.payload.permissions.map(item => item.name))
            }
        }),
    );

    @Effect()
    updateUserRoles$: Observable<Action> = this.actions$.pipe(
        ofType(auzActions.ActionTypes.UPDATE_USER_ROLES),
        withLatestFrom(this.userService.activeUser$),
        filter(([action, user]: [auzActions.UpdateUserRoles, User]) => !!user),
        switchMap(([action, user]) =>
            this.httpService.updateUserRoles(action.payload.user.id, {
                uid: user.id,
                roles: action.payload.roles
            }).pipe(
                withLatestFrom( this.store.select(userSelectors.getUserById(action.payload.user.id))),
                switchMap(([roles, user]) => {
                    let roleIds = roles.map(e => e.id);
                    return [
                        new roleActions.AddMany({ roles: roles }),
                        new userStorageActions.UpsertOne(Object.assign(new User, user, {roleIds: roleIds})),
                        new auzActions.UpdateUserRolesSuccess({ user: user, roles: roles }),
                    ];
                }),
                catchError(error => observableOf(new auzActions.Error({ action: action, error: error })))
            )
        )
    );

    @Effect({dispatch: false})
    userRolesChanged$ = this.actions$.pipe(
        ofType(auzActions.ActionTypes.UPDATE_USER_ROLES_SUCCESS),
        withLatestFrom(this.userService.getActiveUserId$),
        tap(([action, id]: [auzActions.UpdateUserRolesSuccess, number]) => {
            if (action.payload.user.id === id) {
                let rolesObj = {};
                action.payload.roles.forEach((item) => {
                    rolesObj[item.name] = () => true;
                });
                this.globalRoles.flushRoles();
                this.globalRoles.addRoles(rolesObj);
            }
        }),
    );

    @Effect()
    error$: Observable<Action> = this.actions$.pipe(
        ofType(auzActions.ActionTypes.ERROR),
        delay(100),
        map(action => new auzActions.ErrorReset)
    );

}
