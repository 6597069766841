import { ModuleWithProviders, NgModule } from '@angular/core';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { ErrorCatcherInterceptor } from './error-catcher.interceptor';
import { ErrorCatcherService } from './error-catcher.service';
import { ErrorCatcherMessageComponent } from './message/error-catcher-message.component';
import { ErrorCatcherMessageService } from "./message/error-catcher-message.service";
import { SharedModule } from "../shared/shared.module";


@NgModule({
  imports: [
    SharedModule,
  ],
  providers: [
  ],
  declarations: [
    ErrorCatcherMessageComponent
  ],
  exports: [
    ErrorCatcherMessageComponent
  ]
})
export class ErrorCatcherModule {
  static forRoot(): ModuleWithProviders<ErrorCatcherModule> {
    return {
      ngModule: ErrorCatcherModule,
      providers: [
        ErrorCatcherService,
        ErrorCatcherMessageService,
        { provide: HTTP_INTERCEPTORS, useClass: ErrorCatcherInterceptor, multi: true },
      ]      
    };
  }
}


