import { IMailTemplateResponse } from './MailTemplate';


export interface INotificationIndexParams {
    uid: number;
}

export interface INotificationUpdateParams {
    uid: number;
    notification: string;
    settings?: INotificationSettings;
}

export interface INotificationSettings {
    enabled: boolean;
    to_address?: string;
    cc_address?: Array<string>;
    bcc_address?: Array<string>;
}

export interface INotificationResponse {
    notification: string;
    settings?: INotificationSettings;
    mail_template?: IMailTemplateResponse;
    label: string;
    group_name: string;
}

export class Notification {
    notification: string;
    settings: INotificationSettings;
    mail_key: string;
    label: string;
    group_name: string;
    
    constructor(response?: INotificationResponse) {
        if (response) {
            this.notification = response.notification;
            this.settings = response.settings;
            this.mail_key = response.mail_template ? response.mail_template.mailable : null;
            this.label = response.label;
            this.group_name = response.group_name;
        }
    }
}