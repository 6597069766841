import { Injectable } from '@angular/core';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { Action } from '@ngrx/store';

import { Observable } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';

import { AuthenticationHttpService } from '../../services/authentication-http.service';

import * as confActions from '../actions/confirmation.actions';
import * as userStorageActions from '@appRoot/core/user/ngrx-store/actions/user-storage.actions';


@Injectable()
export class ConfirmationEffects {
	constructor(
		private actions$: Actions, 
		private authHttpService: AuthenticationHttpService, 
		) {}	

	@Effect()
	send$: Observable<Action> = this.actions$.pipe(
		ofType(confActions.ActionTypes.SEND),
		switchMap((action: confActions.Send) => 
			this.authHttpService.sendConfirmation(action.payload)
			.pipe(
				map( response => new confActions.SendSuccess(response) ),
				catchError(error => [
					new confActions.Error({error: error, action: action}),
				]),
			),
		),
	);

	@Effect()
	confirm$: Observable<Action> = this.actions$.pipe(
		ofType(confActions.ActionTypes.CONFIRM),
		switchMap((action: confActions.Confirm) =>
			this.authHttpService.confirm(action.payload.token)
			.pipe(
				map( response => new confActions.ConfirmSuccess(response) ),
				catchError(error => [
					new confActions.ConfirmFailed,
					new confActions.Error({error: error, action: action}),
				]),
			),
		),
	);

	@Effect()
	updateUser$: Observable<Action> = this.actions$.pipe(
		ofType(confActions.ActionTypes.CONFIRM_SUCCESS, confActions.ActionTypes.SEND_SUCCESS),
		map((action: confActions.ConfirmSuccess | confActions.SendSuccess) => new userStorageActions.UpsertOne(action.payload))
	);

}