import { Component, ChangeDetectionStrategy, ChangeDetectorRef } from '@angular/core';
import { FormGroup, FormControl, Validators, AbstractControl } from '@angular/forms';
import { CustomValidators } from 'ngx-custom-validators';
import { BasicForm } from '@appRoot/core/forms/form-basic.form';
import { ErrorCatcherService } from '@appRoot/error-catcher/error-catcher.service';
import { takeUntil, filter, skip } from 'rxjs/operators';

import { AuthenticationService } from '../../services/authentication.service';
import * as resetAction from '../../ngrx-store/actions/reset-password.actions';

@Component({
	selector: 'app-reset-password-send',
	templateUrl: './reset-password-send.component.html',
	styleUrls: ['./reset-password-send.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class ResetPasswordSendComponent extends BasicForm {

	public validationMessages: Map<AbstractControl, {[key:string]: string}>;
	public errorShow: boolean = false;
	public errorMessage: string = null;
	public errorMessages = null;
	public created: boolean = false;

	constructor(
		private errorCatcherService: ErrorCatcherService,
		private authService: AuthenticationService,
		private cdr: ChangeDetectorRef,
		) {
		super();
	}

	ngOnInit() {
		super.ngOnInit();
		this.loadingSubscriber();
		this.errorSubscriber();
		this.createdSubscriber();

		this.validationMessages = new Map([
            [this.email, {
                'required': 'Email is required',
                'email': 'Email must be a valid email address.',
            }],
        ]);
	}

	ngOnDestroy(): void {
		super.ngOnDestroy();
	}

	createForm(){
		this.form = new FormGroup({
			email: new FormControl(null, [Validators.required, CustomValidators.email])
		});
	}

	get email() { return this.form.get('email'); }

	onSubmit(): void {
		super.onSubmit();

		if(this.form.valid) {
			this.errorShow = false;
			this.authService.dispatchResetPasswordCreate(this.email.value)
		}
	}

	private loadingSubscriber() {
		this.authService.getResetPasswordLoading$().pipe(takeUntil(this.ngUnsubscribe)).subscribe(loading => {
			this.loading = loading;
			this.cdr.detectChanges();
		});
	}

	private errorSubscriber(){
		this.authService.getResetPasswordError$()
		.pipe(takeUntil(this.ngUnsubscribe), skip(1), filter(e => e !== null)).subscribe(error => {
			if(error.action instanceof resetAction.Create && this.errorCatcherService.has(error.instance)){
				let cError = this.errorCatcherService.get(error.instance);
				this.errorMessage = cError.message ? 'Error! Something went wrong...' : null;
				this.errorMessages = cError.errors ? cError.errors : null;
				this.errorShow = true;
				this.form.setErrors({ 'invalid': true });
				this.cdr.detectChanges();
			}
		});
	}

	private createdSubscriber() {
		this.authService.getResetPasswordCreated$().pipe(takeUntil(this.ngUnsubscribe)).subscribe(e => {
			this.created = e;
			this.cdr.detectChanges();
		});
	}

}
