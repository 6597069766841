<div
    containerClass="tooltip-container"
    #pop="bs-tooltip"
    [tooltip]="tooltipTemplate"
    class="customer-info-tooltip p-1"
    triggers="">
</div>

<ng-template #tooltipTemplate>

    <div *ngIf="!customer" class="fa-1x alert alert-light border mb-0">
        <fa-icon [icon]="faSpinner" [pulse]="true"></fa-icon>
    </div>

    <ng-container *ngIf="customer">
        <app-customer-short-info [customer]="customer"></app-customer-short-info>
    </ng-container>

</ng-template>
