export interface IWhmPackageResponse {
	name: string;
	QUOTA: string;
	package_id?: number;
}

export class WhmPackage {
	name: string;
	server_id: number;
	QUOTA: string;
	package_id?: number;

	constructor(server_id: number, data?: IWhmPackageResponse) {
		this.server_id = +server_id;
		if(data){
			this.createFromResponse(data);
		}
	}

	createFromResponse(data: IWhmPackageResponse){
		this.name = data.name;
		this.QUOTA = data.QUOTA;
		this.package_id = data.package_id;
	}
}
