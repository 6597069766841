import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { SettingsService } from '@appRoot/core/services/settings.service';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import {
    CustomerExpenseSettings,
    ICustomerExpenseSettingsRequestShowParams,
    ICustomerExpenseSettingsRequestUpdateRequiredParams,
    ICustomerExpenseSettingsRequestUpdate
} from '../models';


@Injectable()
export class CustomerExpenseSettingsHttpService {

    public readonly edge: string = 'customer-expense-settings';

    constructor(
        private http: HttpClient,
        private settings: SettingsService,
    ) { }

    fetch(params: ICustomerExpenseSettingsRequestShowParams): Observable<CustomerExpenseSettings> {
        let _params = new HttpParams({ fromObject: <any>{ ...params } });

        return this.http.get<{ data: CustomerExpenseSettings }>(`${this.settings.API_PATH}/${this.edge}`, { params: _params }).pipe(
            map(({ data }) => data),
        );
    }

    update(data: ICustomerExpenseSettingsRequestUpdateRequiredParams, changes: ICustomerExpenseSettingsRequestUpdate): Observable<CustomerExpenseSettings> {
        return this.http.patch<{ data: CustomerExpenseSettings }>(`${this.settings.API_PATH}/${this.edge}/${data.uid}`, {...data, ...changes}).pipe(
            map(response => (response.data)),
        );
    }
}

