import { CustomerContactActionHistory } from '../../models';
import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { Actions, ActionTypes } from '../actions/customer-contact-history.action';


export function selectId(entity: CustomerContactActionHistory): number {
    return entity.id;
}

export const adapter: EntityAdapter<CustomerContactActionHistory> = createEntityAdapter<CustomerContactActionHistory>({
    selectId: selectId,
    sortComparer: false,
});

export interface State extends EntityState<CustomerContactActionHistory> {
    loading: boolean,
    from: number,
    until: number,
}

export const initialState: State = adapter.getInitialState({
    loading: false,
    from: new Date().setMonth( new Date().getMonth()-1 ),
    until: new Date().getTime(),
});

export function reducer(state = initialState, action: Actions): State {
    switch (action.type) {

        case ActionTypes.UPSERT_MANY:
            return adapter.upsertMany(action.payload, state);

        case ActionTypes.GET_HISTORY:
            return {
                ...state,
                loading: true,
            };

        case ActionTypes.GET_HISTORY_SUCCESS:
        case ActionTypes.GET_HISTORY_FAILED:
            return {
                ...state,
                loading: false,
            };

        case ActionTypes.SET_DATE_FROM:
            return {
                ...state,
                from: action.payload,
            };

        case ActionTypes.SET_DATE_UNTIL:
            return {
                ...state,
                until: action.payload,
            };

        default:
            return state;
    }

}