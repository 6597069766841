import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    Input,
    OnInit,
    TemplateRef
} from '@angular/core';
import { Packages } from '@appRoot/lazy-modules/packages/models';
import { BehaviorSubject, Subject } from 'rxjs';
import { NgUnsubscribe } from '@appRoot/core/interfaces';
import es from '@angular/common/locales/es';
import { registerLocaleData } from "@angular/common";
import { fadeInAnimation } from "@appRoot/lazy-modules/self-signup/_animations";


export type PlanTypes = 'select' | 'no-select';

@Component({
    selector: 'app-plan-select-template',
    templateUrl: './plan-select-template.component.html',
    styleUrls: ['./plan-select-template.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    animations: [ fadeInAnimation ],
    host: {'[@fadeInAnimation]': ''}
})
export class PlanSelectTemplateComponent implements OnInit, NgUnsubscribe {

    readonly ngUnsubscribe = new Subject();

    public package$ = new BehaviorSubject<Packages>(null);
    public type$ = new BehaviorSubject<PlanTypes>(null);
    public footerTemplate$ = new BehaviorSubject<TemplateRef<any>>(null);
    public card_class;
    public header_class;
    public footer_class;

    @Input()
    set package(p: Packages) {
        this.package$.next(p);
    };
    get package() {
        return this.package$.getValue();
    };

    @Input()
    set type(type: PlanTypes) {
        this.type$.next(type);
    };
    get type() {
        return this.type$.getValue();
    };

    @Input()
    set footerTemplate(template: TemplateRef<any>) {
        this.footerTemplate$.next(template);
    };
    get footerTemplate() {
        return this.footerTemplate$.getValue();
    };


    constructor(
        public cdr: ChangeDetectorRef
    ) {}

    ngOnChanges() {
        switch (this.type) {
            case "select":
                this.card_class = 'border-primary';
                this.header_class = 'bg-primary';
                this.footer_class = 'bg-primary';
                break;

            case "no-select":
                this.card_class = 'no-select-border';
                this.header_class = 'no-select-back';
                this.footer_class = 'no-select-back';
                break;

            default:
                this.card_class = '';
                this.header_class = '';
                this.footer_class = '';
        }
    }

    ngOnInit() {
        registerLocaleData( es );
    }

    ngOnDestroy() {
        this.unsubscribe();
    }

    unsubscribe() {
        this.ngUnsubscribe.next();
        this.ngUnsubscribe.complete();
    }
}