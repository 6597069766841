import { ICustomerCommentsResponse } from '@appRoot/core/customer/models/ICustomerRequestParams';


export class CustomerComments {
    id: number;
    customer_id: number;
    user_id: number;
    comment: string;
    created_at: number;

    constructor(data?: ICustomerCommentsResponse){
        if (data) {
            this.id = data.id;
            this.customer_id = data.customer_id;
            this.user_id = data.user_id;
            this.comment = data.comment;
            this.created_at = data.created_at;
        }
    }
}