import { Injectable } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import * as coreActions from '@appRoot/core/ngrx-store/actions/core.actions';
import * as routerSelectors from '@appRoot/core/ngrx-store/selectors/router.selectors';
import { User } from '@appRoot/core/user/models';
import * as userStorageActions from '@appRoot/core/user/ngrx-store/actions/user-storage.actions';
import * as userActions from '@appRoot/core/user/ngrx-store/actions/user.actions'
import { UserHttpService } from '@appRoot/core/user/services/user-http.service';
import { genericRetryStrategy } from '@appRoot/core/utils';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { Action, select, Store } from '@ngrx/store';

import { concat, Observable, of as observableOf } from 'rxjs';
import { catchError, map, retryWhen, switchMap, tap, withLatestFrom } from 'rxjs/operators';
import { AuthenticationHttpService } from '../../services/authentication-http.service';

import { AuthenticationService } from '../../services/authentication.service';

import * as authActions from '../actions/authentication.actions';


@Injectable()
export class AuthenticationEffects {

    constructor(
        private actions$: Actions,
        private authService: AuthenticationService,
        private authHttpService: AuthenticationHttpService,
        private userHttpService: UserHttpService,
        private router: Router,
        private store: Store<any>,
		private route: ActivatedRoute
    ) {}

	@Effect()
	login$: Observable<Action> = this.actions$.pipe(
		ofType(authActions.ActionTypes.LOGIN),
		switchMap((action: authActions.Login) =>
			this.authHttpService
			.login(action.payload.username, action.payload.password)
			.pipe(
				map( token => {
					this.authService.setAccessToken(token);
					return new authActions.LoginSuccess
				}),
				catchError(error => [
					new authActions.LoginFailed,
					new authActions.Error({error: error, action: action}),
				])
			)
		)
	);

    @Effect()
    loginSuccess$: Observable<Action> = this.actions$.pipe(
        ofType(authActions.ActionTypes.LOGIN_SUCCESS),
        map((action: authActions.LoginSuccess) => new authActions.Attempt),
    );

    @Effect()
    loginFailed$: Observable<Action> = this.actions$.pipe(
        ofType(authActions.ActionTypes.LOGIN_FAILED),
        map((action: authActions.LoginFailed) => new authActions.UnAuthenticate)
    );

	@Effect()
	logout$: Observable<Action> = this.actions$.pipe(
		ofType(authActions.ActionTypes.LOGOUT),
		switchMap((action: authActions.Logout) =>
			this.authHttpService
			.logout()
			.pipe(
				map( logout => new authActions.LogoutSuccess ),
				catchError(error => [
					new authActions.LogoutSuccess,
					new authActions.Error({error: error, action: action}),
				])
			)
		)
	);

    @Effect()
    logoutSuccess$: Observable<Action> = this.actions$.pipe(
        ofType(authActions.ActionTypes.LOGOUT_SUCCESS),
        tap(action => {
            this.authService.revokeAccessToken();
            this.authService.routeState = null;
        }),
        switchMap(action => concat(
            observableOf(new coreActions.Reset),
            observableOf(new authActions.UnAuthenticate),
        )),
    );

    @Effect()
	refreshToken$: Observable<Action> = this.actions$.pipe(
		ofType(authActions.ActionTypes.REFRESH_TOKEN),
		switchMap((action: authActions.RefreshToken) =>
			this.authHttpService
			.refreshToken()
			.pipe(
				retryWhen(genericRetryStrategy({
					scalingDuration: 300,
					excludedStatusCodes: [401],
					maxRetryAttempts: 2,
				})),
				withLatestFrom( this.store.pipe(select(routerSelectors.getRouteState)) ),
				switchMap(([token, routerState]) => {
					this.authService.routeState = routerState;
					this.authService.setAccessToken(token);
					return concat(
						observableOf(new authActions.RefreshTokenComplete(true)),
					)
				}),
				catchError(error => [
					new authActions.RefreshTokenComplete(false),
					new authActions.UnAuthenticate,
				]),
			)
		)
	);

	@Effect()
	Attempt$: Observable<Action> = this.actions$.pipe(
		ofType(authActions.ActionTypes.ATTEMPT),
		switchMap((action: authActions.Attempt) =>
			this.userHttpService.getCurrent()
			.pipe(
				retryWhen(genericRetryStrategy({
					scalingDuration: 500,
					excludedStatusCodes: [401],
					maxRetryAttempts: 2,
				})),
				switchMap((response) => {
				    let user = new User(response);
                    return [
                        new userStorageActions.UpsertOne(user),
                        new userActions.GetCurrentSuccess({ user: user }),
                        new userActions.SetActiveUser({ user: user }),
                        new authActions.Authenticate,
                        new authActions.AttemptComplete,
                    ];
                }),
				catchError(error => [
					new authActions.Error({error: error, action: action}),
                    new authActions.UnAuthenticate,
					new authActions.AttemptComplete,
				]),
			)
		)
	);

	@Effect({dispatch: false})
	authenticate$: Observable<Action> = this.actions$.pipe(
		ofType(authActions.ActionTypes.AUTHENTICATE),
		tap((action: authActions.Authenticate) => {
			// Disabling redirect during self sign up registration.
			if ( !this.router.url.includes('self-signup') ) {
				let routeState = this.authService.routeState;
				this.router.navigate([routeState.url], {queryParams: routeState.queryParams} );
			}
		}),
	);

	@Effect({dispatch: false})
	unAuthenticate$: Observable<Action> = this.actions$.pipe(
		ofType(authActions.ActionTypes.UNAUTHENTICATE),
		tap((action: authActions.UnAuthenticate) => {
			// Disabling redirect during self sign up registration.
			if ( !this.router.url.includes('self-signup') ) {
				let routeState = this.authService.UNAUTHENTICATED_ROUTE_STATE;
				this.router.navigate([routeState.url], {queryParams: routeState.queryParams} );
			}
		}),
	);

}
