import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { SettingsService } from '@appRoot/core/services/settings.service';
import {
    IWhmAccountProdCreateParams,
    IWhmAccountProdDeleteParams,
    IWhmAccountProdGetLinkParams,
    IWhmAccountProdIndexParams,
    IWhmAccountProdRestoreParams,
    IWhmAccountProdSettings,
    IWhmAccountProdShowParams,
    IWhmAccountProdUpdateParams,
    IWhmAccountProdCustomerSettings,
    IWhmAccountProdScheduledDeletionParams,
    IWhmAccountProdUpdatePackageParams
} from '../models';
import { User } from "@appRoot/core/user/models";
import { Customer } from "@appRoot/core/customer/models";
import { WhmAccountHttpService } from "./whm-account-http.service";


@Injectable({
    providedIn: 'root'
})
export class WhmAccountProdHttpService extends WhmAccountHttpService {

    public readonly edge: string = 'whm-accounts-prod';

    constructor(
        http: HttpClient,
        settings: SettingsService,
    ) {
        super(http, settings);
    }

    fetch(data: IWhmAccountProdIndexParams) {
        return super.fetch(data);
    }

    getById(id: number, data: IWhmAccountProdShowParams) {
        return super.getById(id, data);
    }

    create(data: IWhmAccountProdCreateParams) {
        return super.create(data);
    }

    update(id: number, changes: IWhmAccountProdUpdateParams) {
        return super.update(id, changes);
    }

    updatePackage(id: number, changes: IWhmAccountProdUpdatePackageParams) {
        return super.updatePackage(id, changes);
    }

    restore(id: number, data: IWhmAccountProdRestoreParams) {
        return super.restore(id, data);
    }

    scheduledDeletion(id: number, data: IWhmAccountProdScheduledDeletionParams) {
        return super.scheduledDeletion(id, data);
    }

    delete(id: number, data: IWhmAccountProdDeleteParams) {
        return super.delete(id, data);
    }

    getLink(id: number, data: IWhmAccountProdGetLinkParams) {
        return super.getLink(id, data);
    }

    getSettings<T = IWhmAccountProdSettings>(user: User): Observable<T> {
        let params = new HttpParams();
        params = params.set('uid', (user.id).toString());

        return this.http.get<{ data: T }>(`${this.settings.API_PATH}/${this.edge}/settings`, { params: params }).pipe(
            map(({ data }) => data)
        );
    }

    setSettings<T = IWhmAccountProdSettings>(user: User, data: T): Observable<T> {
        const body = { uid: (user.id), ...data };

        return this.http.patch<{ data: T }>(`${this.settings.API_PATH}/${this.edge}/settings`, body).pipe(
            map(({ data }) => data)
        );
    }

    resetSettings<T = IWhmAccountProdSettings>(user: User, customer: Customer): Observable<T> {
        let params = new HttpParams();
        params = params.set('uid', (user.id).toString());
        params = params.set('customer_id', (customer.id).toString());

        return this.http.delete<{ data: T }>(`${this.settings.API_PATH}/${this.edge}/settings`, { params: params }).pipe(
            map(({ data }) => data)
        );
    }

    getCustomerSettings(active_user: User, customer_id: number): Observable<IWhmAccountProdCustomerSettings> {
        let params = new HttpParams();
        params = params.append('uid', (active_user.id).toString());
        params = params.append('customer_id', (customer_id).toString());

        return this.http.get<{ data: IWhmAccountProdCustomerSettings }>(`${this.settings.API_PATH}/${this.edge}/customer-settings`, { params: params }).pipe(
            map(({ data }) => data)
        );
    }

    setCustomerSettings(active_user: User, data: IWhmAccountProdCustomerSettings): Observable<IWhmAccountProdCustomerSettings | null> {
        const body = { uid: active_user.id, ...data };

        return this.http.patch<{ data: IWhmAccountProdCustomerSettings | null }>(`${this.settings.API_PATH}/${this.edge}/customer-settings`, body).pipe(
            map(({ data }) => data)
        );
    }

    resetCustomerSettings(active_user: User, customer_id: number): Observable<IWhmAccountProdCustomerSettings> {
        let params = new HttpParams();
        params = params.append('uid', (active_user.id).toString());
        params = params.append('customer_id', (customer_id).toString());

        return this.http.delete<{ data: IWhmAccountProdCustomerSettings }>(`${this.settings.API_PATH}/${this.edge}/customer-settings`, { params: params }).pipe(
            map(({ data }) => data)
        );
    }

    domainCheck(domain: string, customerId: number): Observable<boolean>  {
        let params = new HttpParams();
        params = params.append('domain', (domain).toString());
        params = params.append('customer_id', (customerId).toString());

        return this.http.get<{ data: boolean }>(`${this.settings.API_PATH}/${this.edge}/check-domain`, {params: params}).pipe(
            map(response => response.data),
        );
    }

    domainSelfSignupCheck(domain: string): Observable<boolean>  {
        let params = new HttpParams();
        params = params.append('domain', (domain).toString());

        return this.http.get<{ data: boolean }>(`${this.settings.API_PATH}/${this.edge}/check-self-signup-domain`, {params: params}).pipe(
            map(response => response.data),
        );
    }
}