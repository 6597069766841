import { Injectable } from '@angular/core';
import { AbstractControl, AsyncValidatorFn, FormControl, ValidationErrors, ValidatorFn } from '@angular/forms';
import { IUserResponse } from '@appRoot/core/user/models';
import { UserHttpService } from '@appRoot/core/user/services/user-http.service';
import { CustomerHttpService } from '@appRoot/core/customer/services/customer-http.service';
import { genericRetryStrategy } from '@appRoot/core/utils';
import { isValid as isKennitalaValid } from 'kennitala-utility';
import { Observable, of, timer } from 'rxjs';
import { catchError, map, mapTo, retryWhen, switchMap, tap } from 'rxjs/operators';
import { WhmAccountProdHttpService } from '@appRoot/lazy-modules/whm-module/services/whm-account-prod-http.service';
import { WhmAccountDevHttpService } from '@appRoot/lazy-modules/whm-module/services/whm-account-dev-http.service';


function isEmptyInputValue(value: any): boolean {
    // we don't check for string here so it also works with arrays
    return value == null || value.length === 0;
}

@Injectable({providedIn: "root"})
export class ValidatorService {

    constructor(
        private userHttp: UserHttpService,
        private customerHttp: CustomerHttpService,
        private whmProdHttp: WhmAccountProdHttpService,
        private whmDevHttp: WhmAccountDevHttpService
    ) {}

    static domain(control: FormControl): ValidationErrors {
        const regexp = /^(?!-)(?:[a-zA-Z\d\-]{0,62}[a-zA-Z\d]\.){1,126}(?!\d+)[a-zA-Z\d]{1,63}$/m;

        if (isEmptyInputValue(control.value)) {
            return null;  // don't validate empty values to allow optional controls
        }

        return regexp.test(control.value) ? null : {
            domain: {
                valid: false
            }
        };
    }

    static domainSuffix(control: FormControl): ValidationErrors {
        const regexp = /^\.?(?!\d+)[a-zA-Z\d-]{1,63}(\.[a-zA-Z\d\-]{1,63}){0,3}$/;

        if (isEmptyInputValue(control.value)) {
            return null;  // don't validate empty values to allow optional controls
        }

        return regexp.test(control.value) ? null : {
            domainSuffix: {
                valid: false
            }
        };
    }

    static ip(control: FormControl): ValidationErrors {
        // noinspection Annotator
        const regexp = /((^\s*((([0-9]|[1-9][0-9]|1[0-9]{2}|2[0-4][0-9]|25[0-5])\.){3}([0-9]|[1-9][0-9]|1[0-9]{2}|2[0-4][0-9]|25[0-5]))\s*$)|(^\s*((([0-9A-Fa-f]{1,4}:){7}([0-9A-Fa-f]{1,4}|:))|(([0-9A-Fa-f]{1,4}:){6}(:[0-9A-Fa-f]{1,4}|((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3})|:))|(([0-9A-Fa-f]{1,4}:){5}(((:[0-9A-Fa-f]{1,4}){1,2})|:((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3})|:))|(([0-9A-Fa-f]{1,4}:){4}(((:[0-9A-Fa-f]{1,4}){1,3})|((:[0-9A-Fa-f]{1,4})?:((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3}))|:))|(([0-9A-Fa-f]{1,4}:){3}(((:[0-9A-Fa-f]{1,4}){1,4})|((:[0-9A-Fa-f]{1,4}){0,2}:((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3}))|:))|(([0-9A-Fa-f]{1,4}:){2}(((:[0-9A-Fa-f]{1,4}){1,5})|((:[0-9A-Fa-f]{1,4}){0,3}:((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3}))|:))|(([0-9A-Fa-f]{1,4}:){1}(((:[0-9A-Fa-f]{1,4}){1,6})|((:[0-9A-Fa-f]{1,4}){0,4}:((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3}))|:))|(:(((:[0-9A-Fa-f]{1,4}){1,7})|((:[0-9A-Fa-f]{1,4}){0,5}:((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3}))|:)))(%.+)?\s*$))/;

        if (isEmptyInputValue(control.value)) {
            return null;  // don't validate empty values to allow optional controls
        }

        return regexp.test(control.value) ? null : {
            ip: {
                valid: false
            }
        };
    }

    checkEmailNotTaken(maxAttempts: number = 1, debounceDelay: number = 500): AsyncValidatorFn {
        let prevValue = null;
        let prevResult: IUserResponse[] = [];

        return (control: AbstractControl): Observable<ValidationErrors | null> => {
            return timer(debounceDelay).pipe(
                mapTo(control.value),
                switchMap(currentValue => {
                    if( isEmptyInputValue(currentValue) ){
                        return of([]);
                    }

                    if(prevValue !== currentValue ) {
                        prevValue = currentValue;
                        return this.userHttp.fetchBy({key: 'email', value: currentValue}).pipe(retryWhen(genericRetryStrategy({
                            excludedStatusCodes: [200, 401],
                            scalingDuration: 300,
                            maxRetryAttempts: maxAttempts,
                        })),);
                    } else {
                        return of(prevResult);
                    }
                }),
                tap(e => prevResult = e),
                map(users => users.filter(user => user.email === control.value)),
                map(users => users.length ? ({ 'emailIsTaken': 'The email is already taken.' }) : null),
            );
        };
    }

    isSSIDExist(maxAttempts: number = 1, debounceDelay: number = 500): AsyncValidatorFn {
        let prevValue = null;
        let prevResult: any = true;

        return (control: AbstractControl): Observable<ValidationErrors | null> => {
            return timer(debounceDelay).pipe(
                mapTo(control.value),
                switchMap(currentValue => {
                    if( isEmptyInputValue(currentValue) ){
                        return of(true);
                    }
                    if(prevValue === currentValue ) {
                        return of(prevResult);
                    } else {
                        prevValue = currentValue;
                        return this.customerHttp.customerIdExist(currentValue).pipe(
                            retryWhen(genericRetryStrategy({
                                excludedStatusCodes: [401, 422],
                                scalingDuration: 300,
                                maxRetryAttempts: maxAttempts,
                            })),
                            catchError(error => {
                                return of({'SSIDExists': 'Something went wrong'});
                            }),
                        );
                    }
                }),
                tap(e => prevResult = e),
                map(exists => (typeof exists === 'boolean' && exists) ? ({ 'SSIDExists': 'The customer ID already exists.' }) : null)
            )
        }
    }

    requiredWith(requiredControl: AbstractControl): ValidatorFn {
        let subscribe = false;

        return (control: AbstractControl): ValidationErrors => {
            if (!subscribe) {
                subscribe = true;
                requiredControl.valueChanges.subscribe(() => {
                    control.updateValueAndValidity();
                });
            }

            const v = control.value;

            return (requiredControl.value == null || requiredControl.value === 0 || requiredControl.value == "") && !(v == null || v === 0 || v == "") ? {
                requiredWith: {
                    control: requiredControl,
                    value: requiredControl.value
                }
            } : null;
        };
    };

    static usernamePattern(control: FormControl): ValidationErrors {
        const regexp = /^[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF\x80-\xF7 a-zA-Z0-9@+_.\\'-]+?$/;

        if (isEmptyInputValue(control.value)) {
            return null;  // don't validate empty values to allow optional controls
        }

        return regexp.test(control.value) ? null : {
            usernamePattern: {
                valid: false
            }
        };
    }

    static isSSIDValid(control: FormControl): ValidationErrors {
        if (isEmptyInputValue(control.value)) {
            return null;  // don't validate empty values to allow optional controls
        }
        return isKennitalaValid(control.value) ? null : {ssid: {valid: false}};
    }

    domainProdCheck(customerId: number, maxAttempts: number = 1, debounceDelay: number = 500): AsyncValidatorFn {
        let prevValue = null;
        let prevResult: any = true;

        return (control: AbstractControl): Observable<ValidationErrors | null> => {
            return timer(debounceDelay).pipe(
                mapTo(control.value),
                switchMap(currentValue => {
                    if( isEmptyInputValue(currentValue) ){
                        return of(true);
                    }
                    if (prevValue === currentValue) {
                        return of(prevResult);
                    } else {
                        prevValue = currentValue;
                        return this.whmProdHttp.domainCheck(currentValue, customerId).pipe(
                            retryWhen(genericRetryStrategy({
                                excludedStatusCodes: [401, 422],
                                scalingDuration: 300,
                                maxRetryAttempts: maxAttempts,
                            })),
                            catchError(error => {
                                return of({'domain-valid': 'Something went wrong'});
                            }),
                        );
                    }
                }),
                tap(e => prevResult = e),
                map(exists => (typeof exists === 'boolean' && exists) ? ({ 'domain-valid': 'You cannot use this domain.' }) : null)
            )
        }
    }

    domainSelfSignupProdCheck(maxAttempts: number = 1, debounceDelay: number = 500): AsyncValidatorFn {
        let prevValue = null;
        let prevResult: any = true;

        return (control: AbstractControl): Observable<ValidationErrors | null> => {
            return timer(debounceDelay).pipe(
                mapTo(control.value),
                switchMap(currentValue => {
                    if( isEmptyInputValue(currentValue) ){
                        return of(true);
                    }
                    if (prevValue === currentValue) {
                        return of(prevResult);
                    } else {
                        prevValue = currentValue;
                        return this.whmProdHttp.domainSelfSignupCheck(currentValue).pipe(
                            retryWhen(genericRetryStrategy({
                                excludedStatusCodes: [401, 422],
                                scalingDuration: 300,
                                maxRetryAttempts: maxAttempts,
                            })),
                            catchError(error => {
                                return of({'domain-valid': 'Something went wrong'});
                            }),
                        );
                    }
                }),
                tap(e => prevResult = e),
                map(exists => (typeof exists === 'boolean' && exists) ? ({ 'domain-valid': 'You cannot use this domain.' }) : null)
            )
        }
    }

    domainDevCheck(suffix: string, customerId: number, maxAttempts: number = 1, debounceDelay: number = 500): AsyncValidatorFn {
        let prevValue = null;
        let prevResult: any = true;

        return (control: AbstractControl): Observable<ValidationErrors | null> => {
            return timer(debounceDelay).pipe(
                mapTo(control.value),
                switchMap(currentValue => {
                    if( isEmptyInputValue(currentValue) ){
                        return of(true);
                    }
                    if (prevValue === currentValue) {
                        return of(prevResult);
                    } else {
                        prevValue = currentValue;
                        return this.whmDevHttp.domainCheck(currentValue + suffix, customerId).pipe(
                            retryWhen(genericRetryStrategy({
                                excludedStatusCodes: [401, 422],
                                scalingDuration: 300,
                                maxRetryAttempts: maxAttempts,
                            })),
                            catchError(error => {
                                return of({'domain-valid': 'Something went wrong'});
                            }),
                        );
                    }
                }),
                tap(e => prevResult = e),
                map(exists => (typeof exists === 'boolean' && exists) ? ({ 'domain-valid': 'You cannot use this domain.' }) : null)
            )
        }
    }

    numberDifference(min: number): ValidatorFn {
        return (control: AbstractControl): {[key: string]: any} | null => {
            const value = control.value ? +(control.value.toString().replace(/\D/g, '')) : 0;

            if (value < min) {
                return { 'min': true };
            }

            return null;
        };
    }
}
