import { Action } from '@ngrx/store';
import { NGRXError } from '@appRoot/core/ngrx-store/models/NGRXError';


export enum ActionTypes {
    RELOAD = '[CORE] reload',
    RELOAD_SUCCESS = '[CORE] reload success',
    SHOW_ERROR = '[CORE] show error',
    RESET_ERROR = '[CORE] reset error',
    RESET = '[CORE] reset',
    RESET_COMPLETE = '[CORE] reset complete',
}

export class Reload implements Action {
    readonly type = ActionTypes.RELOAD;
}
export class ReloadSuccess implements Action {
    readonly type = ActionTypes.RELOAD_SUCCESS;
}

export class ShowError implements Action {
    readonly type = ActionTypes.SHOW_ERROR;
    constructor(public payload: NGRXError) {}
}
export class ErrorReset implements Action {
    readonly type = ActionTypes.RESET_ERROR;
}

export class Reset implements Action {
    readonly type = ActionTypes.RESET;
}
export class ResetComplete implements Action {
    readonly type = ActionTypes.RESET_COMPLETE;
}


export type Actions =
    | Reload
    | ReloadSuccess
    | ShowError
    | ErrorReset
    | Reset
    | ResetComplete
    ;
