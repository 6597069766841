import { Injectable } from '@angular/core';
import {
    CanActivate,
    CanActivateChild,
    ActivatedRouteSnapshot,
    RouterStateSnapshot,
    UrlTree
}  from '@angular/router';
import { select, Store } from '@ngrx/store';
import { Observable, of, race } from 'rxjs';
import { catchError, filter, map, switchMap, take } from 'rxjs/operators';
import { State } from '../../ngrx-store/reducers';
import { LoadAll } from '../ngrx-store/actions/permission.actions';
import { getLoading, getLoaded, getError } from '../ngrx-store/selectors/permission.selectors';

@Injectable({
    providedIn: 'root'
})
export class PermissionGuard implements CanActivate, CanActivateChild {

    private loading: boolean = false;

    constructor(private store: Store<State>) {}

    canActivate(
        next: ActivatedRouteSnapshot,
        state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
        return this.loadPermissions();
    }

    canActivateChild(
        next: ActivatedRouteSnapshot,
        state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
        return this.canActivate(next, state);
    }

    private loadPermissions() {
        return this.store.pipe(
            select(getLoading),
            filter(loading => !loading),
            switchMap(loading => this.store.pipe(select(getLoaded))),
            switchMap(loaded => {
                if (!loaded && !this.loading) {
                    this.loading = true;
                    this.store.dispatch(new LoadAll);
                }
                return race(
                    this.store.pipe(select(getLoaded), filter(e => !!e)),
                    this.store.pipe(select(getError), filter(error => error !== null))
                );
            }),
            map(loaded => {
                this.loading = false;
                if (typeof loaded == 'boolean') return loaded;
                return false;
            }),
            catchError(error => {
                console.log(error);
                this.loading = false;
                return of(false);
            }),
            take(1)
        );
    }
}
