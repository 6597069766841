<div class="wrapper">
    <div class="block-center mt-4 wd-xl">
        <!-- START card-->
        <div class="card card-flat">
            <div class="card-header text-center bg-dark">
                <a href="#">
                    <img class="block-center rounded" src="{{settings.app.logo.default}}" alt="Image" />
                </a>
            </div>
            <div class="card-body">

                <div *ngIf="isCheckingToken" class="preloader-box position-relative">
                    <app-preloader type="sk-fading-circle" [overlay]="false"></app-preloader>
                </div>

                <ng-container *ngIf="isRegistrationAllowed; then registrationAllowed; else registrationForbidden"></ng-container>

                <ng-template #registrationAllowed>
                    <p class="text-center py-2 text-uppercase">{{ 'Signup to get instant access.' | translate}}</p>
                    <app-register [email]="tokenEmail" [token]="token"></app-register>
                </ng-template>

                <ng-template #registrationForbidden>
                    <app-bs4-alert *ngIf="!isCheckingToken"  type="info" message="Registration is not allowed"></app-bs4-alert>
                </ng-template>

                <a class="btn btn-block btn-secondary" [routerLink]="'/login'">{{ 'Sign In' | translate }}</a>
            </div>
        </div>
    </div>
</div>
