import {createFeatureSelector, createSelector} from '@ngrx/store';
import * as fromReducer from "@appRoot/core/available-data/ngrx-store/reducers/available-data.reducer";


export const getStore = createFeatureSelector<fromReducer.State>('availableData');


export let getLoaded = createSelector(
    getStore,
    (state) => state.loaded
);

export const getLoading = createSelector(
    getStore,
    (state) => state.loading
);

export const getData = createSelector(
    getStore,
    (state) => state.data
);