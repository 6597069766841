import { Actions, ActionTypes } from '../actions/core.actions';
import { NGRXError } from '../models/NGRXError';


export interface State {
    reloading: boolean;
    error: NGRXError;
    reset: boolean;
}

export const initialState: State = {
    reloading: false,
    error: null,
    reset: false,
};

export function reducer(state = initialState, action: Actions): State {
    switch (action.type) {
        case ActionTypes.RELOAD:
            return {
                ...state,
                reloading: true,
            };
        case ActionTypes.RELOAD_SUCCESS:
            return {
                ...state,
                reloading: false,
            };

        case ActionTypes.SHOW_ERROR:
            return {
                ...state,
                error: action.payload,
                reloading: false,
            };
        case ActionTypes.RESET_ERROR:
            return {
                ...state,
                error: null,
            };

        case ActionTypes.RESET:
            return {
                ...state,
                reset: true,
            };
        case ActionTypes.RESET_COMPLETE:
            return {
                ...state,
                reset: false,
            };

        default:
            return state;
    }
}
