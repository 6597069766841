import { ICustomerGroupsResponse } from '@appRoot/core/customer/models/ICustomerGroupsRequestParams';


export class CustomerGroups {
	id: number = null;
	number: string = null;
	description: string = null;
	type: string = null;
	message: string = null;
	status: boolean;

	constructor(data?: ICustomerGroupsResponse) {
		if (data) {
            this.id = data.id;
            this.number = data.number;
            this.description = data.description;
            this.type = data.type;
            this.message = data.message;
            this.message = data.message;
            this.status = data.status;
		}
	}
}