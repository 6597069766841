import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Action } from '@ngrx/store';
import { Actions, Effect, ofType } from '@ngrx/effects';

import { Observable, of as observableOf, concat } from 'rxjs';
import { switchMap, map, tap, catchError } from 'rxjs/operators';

import { AuthenticationHttpService } from '../../services/authentication-http.service';

import * as regActions from '../actions/registration.actions';
import * as confActions from '../actions/confirmation.actions';


@Injectable()
export class RegistrationEffects {
	constructor(
		private actions$: Actions, 
		private authHttpService: AuthenticationHttpService, 
		) {}	

	@Effect()
	registration$: Observable<Action> = this.actions$.pipe(
		ofType(regActions.ActionTypes.REGISTRATION),
		switchMap((action: regActions.Registration) => 
			this.authHttpService.registration(action.payload)
			.pipe(
				map( user => new regActions.RegistrationSuccess({user: user}) ),
				catchError(error => [
					new regActions.RegistrationFailed,
					new regActions.Error({error: error, action: action}),
				]),
			),
		),
	);

	@Effect()
	registrationSuccess$: Observable<Action> = this.actions$.pipe(
		ofType(regActions.ActionTypes.REGISTRATION_SUCCESS),
		map((action: regActions.RegistrationSuccess) => new confActions.Send({email: action.payload.user.email})),
	);

}