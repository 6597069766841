import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { Permission } from '@appRoot/core/roles-permissions/models';


@Component({
    selector: 'app-permissions-item',
    templateUrl: './permissions-item.component.html',
    styleUrls: ['./permissions-item.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class PermissionsItemComponent implements OnInit {

    @Input() permission: Permission;

    constructor() { }

    ngOnInit() {
    }

}
