import { IPaginatedResponse } from '@appRoot/core/interfaces';
import { NGRXError } from '@appRoot/core/ngrx-store/models/NGRXError';
import { Actions, ActionTypes } from '../actions/customer.actions';


export interface State {
    loading: boolean;
    complete: boolean,
    getting_current: boolean;
    creating: boolean;
    getting: boolean;
    updating: boolean;
    deleting: boolean;
    restoring: boolean;
    customer: number; // session customer id
    activeCustomer: number; // active customer id
    error: NGRXError<Actions>;
    pagination: IPaginatedResponse<number[]>;
    user_adding: boolean;
    user_removing: boolean;
    changing_user_role: boolean;
    new_user_mail_resending: boolean;
    dk_importing: boolean;
    email_logs: boolean;
    email_logs_resending: boolean;
    subscription: boolean;
    subscription_show_tax: boolean;
    invoice: boolean;
    invoice_pdf: boolean;
    invoice_html: boolean;
    invoice_email: boolean;
    openExpenses: boolean;
    paymentMode: boolean;
    send_emails: boolean;
    create_expense: boolean;
    timeTracking: boolean;
}

export const initialState: State = {
    loading: false,
    complete: false,
    getting_current: false,
    creating: false,
    getting: false,
    updating: false,
    deleting: false,
    restoring: false,
    customer: null,
    activeCustomer: null,
    error: null,
    pagination: null,
    user_adding: false,
    user_removing: false,
    changing_user_role: false,
    new_user_mail_resending: false,
    dk_importing: false,
    email_logs: false,
    email_logs_resending: false,
    subscription: false,
    subscription_show_tax: false,
    invoice: false,
    invoice_pdf: false,
    invoice_html: false,
    invoice_email: false,
    openExpenses: false,
    paymentMode: false,
    send_emails: false,
    create_expense: false,
    timeTracking: false
};

export function reducer(state = initialState, action: Actions): State {
    switch (action.type) {

        case ActionTypes.ERROR:
            return {
                ...state,
                error: action.payload
            };

        case ActionTypes.RESET:
            return {
                ...initialState
            };

        case ActionTypes.LOAD:
            return {
                ...state,
                loading: true,
            };
        case ActionTypes.LOAD_SUCCESS:
        case ActionTypes.LOAD_FAILED:
            return {
                ...state,
                loading: false,
                complete: true,
            };
        case ActionTypes.RELOAD:
            return {
                ...state,
                complete: false,
            };
        case ActionTypes.PAGINATION_SET:
            return {
                ...state,
                pagination: action.payload,
            };

        case ActionTypes.GET_CURRENT:
            return {
                ...state,
                getting_current: true,
            };
        case ActionTypes.GET_CURRENT_SUCCESS:
            return {
                ...state,
                customer: action.payload.customer.id,
                getting_current: false,
            };
        case ActionTypes.GET_CURRENT_FAILED:
            return {
                ...state,
                getting_current: false,
            };

        case ActionTypes.SET_ACTIVE_CUSTOMER:
            return {
                ...state,
                activeCustomer: action.payload.customer.id
            };

        case ActionTypes.CREATE:
            return {
                ...state,
                creating: true,
            };
        case ActionTypes.CREATE_SUCCESS:
        case ActionTypes.CREATE_FAILED:
            return {
                ...state,
                creating: false,
            };

        case ActionTypes.GET_BY_ID:
            return {
                ...state,
                getting: true,
            };
        case ActionTypes.GET_BY_ID_SUCCESS:
        case ActionTypes.GET_BY_ID_FAILED:
            return {
                ...state,
                getting: false,
            };

        case ActionTypes.UPDATE:
            return {
                ...state,
                updating: true,
            };
        case ActionTypes.UPDATE_SUCCESS:
        case ActionTypes.UPDATE_FAILED:
            return {
                ...state,
                updating: false,
            };

        case ActionTypes.DELETE:
        case ActionTypes.DELETE_SELF:
            return {
                ...state,
                deleting: true,
            };
        case ActionTypes.DELETE_SUCCESS:
        case ActionTypes.DELETE_FAILED:
        case ActionTypes.DELETE_SELF_SUCCESS:
        case ActionTypes.DELETE_SELF_FAILED:
            return {
                ...state,
                deleting: false,
            };

        case ActionTypes.RESTORE:
            return {
                ...state,
                restoring: true,
            };
        case ActionTypes.RESTORE_SUCCESS:
        case ActionTypes.RESTORE_FAILED:
            return {
                ...state,
                restoring: false,
            };

        case ActionTypes.ADD_USER_TO_CUSTOMER:
            return {
                ...state,
                user_adding: true,
            };
        case ActionTypes.ADD_USER_TO_CUSTOMER_SUCCESS:
        case ActionTypes.ADD_USER_TO_CUSTOMER_FAILED:
            return {
                ...state,
                user_adding: false,
            };

        case ActionTypes.REMOVE_USER_FROM_CUSTOMER:
            return {
                ...state,
                user_removing: true,
            };
        case ActionTypes.REMOVE_USER_FROM_CUSTOMER_SUCCESS:
        case ActionTypes.REMOVE_USER_FROM_CUSTOMER_FAILED:
            return {
                ...state,
                user_removing: false,
            };

        case ActionTypes.CHANGE_USER_CUSTOMER_ROLE:
            return {
                ...state,
                changing_user_role: true,
            };
        case ActionTypes.CHANGE_USER_CUSTOMER_ROLE_SUCCESS:
        case ActionTypes.CHANGE_USER_CUSTOMER_ROLE_FAILED:
            return {
                ...state,
                changing_user_role: false,
            };

        case ActionTypes.RESEND_NEW_USER_MAIL:
            return {
                ...state,
                new_user_mail_resending: true,
            };
        case ActionTypes.RESEND_NEW_USER_MAIL_SUCCESS:
        case ActionTypes.RESEND_NEW_USER_MAIL_FAILED:
            return {
                ...state,
                new_user_mail_resending: false,
            };

        case ActionTypes.IMPORT_FROM_DK:
            return {
                ...state,
                dk_importing: true,
            };
        case ActionTypes.IMPORT_FROM_DK_SUCCESS:
        case ActionTypes.IMPORT_FROM_DK_FAILED:
            return {
                ...state,
                dk_importing: false,
            };

        case ActionTypes.GET_EMAIL_LOGS:
            return {
                ...state,
                email_logs: true,
            };
        case ActionTypes.GET_EMAIL_LOGS_SUCCESS:
        case ActionTypes.GET_EMAIL_LOGS_FAILED:
            return {
                ...state,
                email_logs: false,
            };

        case ActionTypes.RESEND_EMAIL_LOGS:
            return {
                ...state,
                email_logs_resending: true,
            };
        case ActionTypes.RESEND_EMAIL_LOGS_SUCCESS:
        case ActionTypes.RESEND_EMAIL_LOGS_FAILED:
            return {
                ...state,
                email_logs_resending: false,
            };

        case ActionTypes.GET_SUBSCRIPTION:
            return {
                ...state,
                subscription: true,
            };
        case ActionTypes.GET_SUBSCRIPTION_SUCCESS:
        case ActionTypes.GET_SUBSCRIPTION_FAILED:
            return {
                ...state,
                subscription: false,
            };

        case ActionTypes.SUBSCRIPTION_SET_TAX:
            return {
                ...state,
                subscription_show_tax: !state.subscription_show_tax,
            };

        case ActionTypes.GET_INVOICE:
            return {
                ...state,
                invoice: true,
            };
        case ActionTypes.GET_INVOICE_SUCCESS:
        case ActionTypes.GET_INVOICE_FAILED:
            return {
                ...state,
                invoice: false,
            };

        case ActionTypes.GET_INVOICE_PDF:
            return {
                ...state,
                invoice_pdf: true,
            };
        case ActionTypes.GET_INVOICE_PDF_SUCCESS:
        case ActionTypes.GET_INVOICE_PDF_FAILED:
            return {
                ...state,
                invoice_pdf: false,
            };

        case ActionTypes.GET_INVOICE_HTML:
            return {
                ...state,
                invoice_html: true,
            };
        case ActionTypes.GET_INVOICE_HTML_SUCCESS:
        case ActionTypes.GET_INVOICE_HTML_FAILED:
            return {
                ...state,
                invoice_html: false,
            };

        case ActionTypes.INVOICE_EMAIL:
            return {
                ...state,
                invoice_email: true,
            };
        case ActionTypes.INVOICE_EMAIL_SUCCESS:
        case ActionTypes.INVOICE_EMAIL_FAILED:
            return {
                ...state,
                invoice_email: false,
            };

        case ActionTypes.GET_OPEN_EXPENSES:
            return {
                ...state,
                openExpenses: true,
            };
        case ActionTypes.GET_OPEN_EXPENSES_SUCCESS:
        case ActionTypes.GET_OPEN_EXPENSES_FAILED:
            return {
                ...state,
                openExpenses: false,
            };

        case ActionTypes.GET_PAYMENT_MODE:
            return {
                ...state,
                paymentMode: true,
            };
        case ActionTypes.GET_PAYMENT_MODE_SUCCESS:
        case ActionTypes.GET_PAYMENT_MODE_FAILED:
            return {
                ...state,
                paymentMode: false,
            };

        case ActionTypes.SEND_EMAIL:
            return {
                ...state,
                send_emails: true,
            };
        case ActionTypes.SEND_EMAIL_SUCCESS:
        case ActionTypes.SEND_EMAIL_FAILED:
            return {
                ...state,
                send_emails: false,
            };

        case ActionTypes.CREATE_EXPENSE:
            return {
                ...state,
                create_expense: true,
            };
        case ActionTypes.CREATE_EXPENSE_SUCCESS:
        case ActionTypes.CREATE_EXPENSE_FAILED:
            return {
                ...state,
                create_expense: false,
            };

        case ActionTypes.GET_TIME_TRACKING:
            return {
                ...state,
                timeTracking: true,
            };
        case ActionTypes.GET_TIME_TRACKING_SUCCESS:
        case ActionTypes.GET_TIME_TRACKING_FAILED:
            return {
                ...state,
                timeTracking: false,
            };

        default:
            return state;
    }
}
