<app-bs4-alert *ngIf="errorShow" [message]="errorMessage" [messages]="errorMessages" type="danger"></app-bs4-alert>
<app-bs4-alert *ngIf="created" message="Password reset link has been sent to your email! Please check your mailbox." type="success"></app-bs4-alert>

<form [formGroup]="form" (ngSubmit)="onSubmit()" class="form-validate" role="form" name="recoverForm" novalidate="">
    
    <p class="text-center">You will be sent an email providing instructions to reset your password.</p>
    
    <div class="form-group">
        <label class="text-muted">{{ 'Email address' | translate }}</label>
        <div class="input-group with-focus">
            <input formControlName="email" class="form-control border-right-0" type="email" name="email" placeholder="{{ 'Enter' | translate }} email" autocomplete="off" />
            <div class="input-group-append">
                <span class="input-group-text text-muted bg-transparent border-left-0">
                    <fa-icon [icon]="['fas', 'envelope']"></fa-icon>
                </span>
            </div>
        </div>
        <mat-error field-error [control]="email" [messages]="validationMessages"></mat-error>
    </div>

    <button [disabled]="loading" class="btn btn-danger btn-block" type="submit">{{ 'Reset' | translate }}</button>
</form>