import { createSelector } from '@ngrx/store';
import { ModuleStore } from '../reducers';


export const getStore = createSelector(
    ModuleStore,
    (store) => store.self_signup_accounts
);


export const getPackageId = createSelector(
    getStore,
    (state) => state.package_id
);

export const getDomain = createSelector(
    getStore,
    (state) => state.domain
);

export const getCustomer = createSelector(
    getStore,
    (state) => state.customer
);

export const getCustomerNumber = createSelector(
    getStore,
    (state) => state.customer_number
);

export const getCustomerId = createSelector(
    getStore,
    (state) => state.customer_id
);

export const getCustomerCard = createSelector(
    getStore,
    (state) => state.customer_card
);