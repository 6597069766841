import { Injectable } from '@angular/core';
import { NGRXError } from '@appRoot/core/ngrx-store/models/NGRXError';
import { User } from '@appRoot/core/user/models';
import { UserService } from '@appRoot/core/user/services/user.service';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { Action, Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { catchError, filter, mergeMap, switchMap, withLatestFrom } from 'rxjs/operators';
import { IMailProvider, MailTemplate } from '../../models';
import { MailingHttpService } from '../../services/mailing-http.service';
import * as mailingActions from '../actions/mailing.actions';
import * as mailingSelectors from '../selectors/mailing.selectors';


@Injectable()
export class MailingEffects {

    constructor(
        private actions$: Actions,
        private httpService: MailingHttpService,
        private userService: UserService,
        private store: Store<any>,
    ) {}

    @Effect()
    load$: Observable<Action> = this.actions$.pipe(
        ofType(mailingActions.ActionTypes.LOAD),
        withLatestFrom(
            this.userService.activeUser$,
            this.store.select(mailingSelectors.getLoaded),
            this.store.select(mailingSelectors.getTemplatesArray)
        ),
        filter(([action, user, loaded, entities]: [mailingActions.Load, User, boolean, MailTemplate[]]) => !!user),
        switchMap(([action, user, loaded, entities]) => {
            if(loaded){
                return [
                    new mailingActions.LoadSuccess(entities),
                ];
            }

            return this.httpService.fetch({ uid: user.id }).pipe(
                switchMap(response => {
                    return [
                        new mailingActions.LoadSuccess(response),
                    ];
                }),
                catchError(error => [
                    new mailingActions.Error(new NGRXError(action, error)),
                    new mailingActions.LoadFailed,
                ]),
            );
        })
    );

    @Effect()
    create$: Observable<Action> = this.actions$.pipe(
        ofType(mailingActions.ActionTypes.CREATE),
        withLatestFrom(this.userService.activeUser$),
        filter(([action, user]: [mailingActions.Create, User]) => !!user),
        switchMap(([action, user]) => {
            return this.httpService.create({uid: user.id, ...action.payload}).pipe(
                switchMap(response => {
                    return [
                        new mailingActions.CreateSuccess(response),
                    ];
                }),
                catchError(error => [
                    new mailingActions.Error(new NGRXError(action, error)),
                    new mailingActions.CreateFailed(),
                ]),
            );
        }),
    );

    @Effect()
    getById$: Observable<Action> = this.actions$.pipe(
        ofType(mailingActions.ActionTypes.GET_BY_ID),
        withLatestFrom(this.userService.activeUser$),
        filter(([action, user]: [mailingActions.GetById, User]) => !!user),
        mergeMap(([action, user]) => {
            return this.httpService.show( action.payload,{uid: user.id}).pipe(
                switchMap(response => {
                    return [
                        new mailingActions.GetByIdSuccess(response),
                    ];
                }),
                catchError(error => [
                    new mailingActions.Error(new NGRXError(action, error)),
                    new mailingActions.GetByIdFailed,
                ]),
            );
        }),
    );

    @Effect()
    update$: Observable<Action> = this.actions$.pipe(
        ofType(mailingActions.ActionTypes.UPDATE),
        withLatestFrom(this.userService.activeUser$),
        filter(([action, user]: [mailingActions.Update, User]) => !!user),
        mergeMap(([action, user]) => {
            return this.httpService.update( action.payload.entity.id,{uid: user.id, ...action.payload.changes}).pipe(
                switchMap(response => {
                    return [
                        new mailingActions.UpdateSuccess(response),
                    ];
                }),
                catchError(error => [
                    new mailingActions.Error(new NGRXError(action, error)),
                    new mailingActions.UpdateFailed,
                ]),
            );
        }),
    );

    @Effect()
    delete$: Observable<Action> = this.actions$.pipe(
        ofType(mailingActions.ActionTypes.DELETE),
        withLatestFrom(this.userService.activeUser$),
        filter(([action, user]: [mailingActions.Delete, User]) => !!user),
        mergeMap(([action, user]) => {
            return this.httpService.destroy( action.payload.id,{uid: user.id}).pipe(
                switchMap(response => {
                    return [
                        new mailingActions.DeleteSuccess(action.payload),
                    ];
                }),
                catchError(error => [
                    new mailingActions.Error(new NGRXError(action, error)),
                    new mailingActions.DeleteFailed,
                ]),
            );
        }),
    );

    @Effect()
    getProviders$: Observable<Action> = this.actions$.pipe(
        ofType(mailingActions.ActionTypes.GET_PROVIDERS),
        withLatestFrom(this.userService.activeUser$),
        filter(([action, user]: [mailingActions.GetProviders, User]) => !!user),
        withLatestFrom(this.store.select(mailingSelectors.getProviders),
            ([action, user], providers) => [action, user, providers] as [mailingActions.GetProviders, User, IMailProvider[]]),
        mergeMap(([action, user, providers]) => {
            if(providers){
                return [
                    new mailingActions.GetProvidersSuccess(providers),
                ];
            }

            return this.httpService.getProviders( {uid: user.id}).pipe(
                switchMap(response => {
                    return [
                        new mailingActions.GetProvidersSuccess(response),
                    ];
                }),
                catchError(error => [
                    new mailingActions.Error(new NGRXError(action, error)),
                    new mailingActions.GetProvidersFailed,
                ]),
            );
        }),
    );

}
