import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import {
    IWhmAccountDevCreateParams,
    IWhmAccountDevDeleteParams,
    IWhmAccountDevGetLinkParams,
    IWhmAccountDevIndexParams,
    IWhmAccountDevRestoreParams,
    IWhmAccountDevSettings,
    IWhmAccountDevShowParams,
    IWhmAccountDevUpdateParams,
    IWhmAccountDevCustomerSettings,
    IWhmAccountDevScheduledDeletionParams,
    IWhmAccountDevUpdatePackageParams
} from '../models';
import { User } from "@appRoot/core/user/models";
import { Customer } from "@appRoot/core/customer/models";
import { WhmAccountHttpService } from "./whm-account-http.service";
import { HttpClient, HttpParams } from "@angular/common/http";
import { map } from "rxjs/operators";
import { SettingsService } from '@appRoot/core/services/settings.service';


@Injectable({
    providedIn: 'root'
})
export class WhmAccountDevHttpService extends WhmAccountHttpService {

    public readonly edge: string = 'whm-accounts-dev';

    constructor(
        http: HttpClient,
        settings: SettingsService,
    ) {
        super(http, settings);
    }

    fetch(data: IWhmAccountDevIndexParams) {
        return super.fetch(data);
    }

    getById(id: number, data: IWhmAccountDevShowParams) {
        return super.getById(id, data);
    }

    create(data: IWhmAccountDevCreateParams) {
        return super.create(data);
    }

    update(id: number, changes: IWhmAccountDevUpdateParams) {
        return super.update(id, changes);
    }

    updatePackage(id: number, changes: IWhmAccountDevUpdatePackageParams) {
        return super.updatePackage(id, changes);
    }

    restore(id: number, data: IWhmAccountDevRestoreParams) {
        return super.restore(id, data);
    }

    scheduledDeletion(id: number, data: IWhmAccountDevScheduledDeletionParams) {
        return super.scheduledDeletion(id, data);
    }

    delete(id: number, data: IWhmAccountDevDeleteParams) {
        return super.delete(id, data);
    }

    getLink(id: number, data: IWhmAccountDevGetLinkParams) {
        return super.getLink(id, data);
    }

    getSettings<T = IWhmAccountDevSettings>(user: User): Observable<T> {
        let params = new HttpParams();
        params = params.set('uid', (user.id).toString());

        return this.http.get<{ data: T }>(`${this.settings.API_PATH}/${this.edge}/settings`, { params: params }).pipe(
            map(({ data }) => data)
        );
    }

    setSettings<T = IWhmAccountDevSettings>(user: User, data: T): Observable<T> {
        const body = { uid: (user.id), ...data };

        return this.http.patch<{ data: T }>(`${this.settings.API_PATH}/${this.edge}/settings`, body).pipe(
            map(({ data }) => data)
        );
    }

    resetSettings<T = IWhmAccountDevSettings>(user: User, customer: Customer): Observable<T> {
        let params = new HttpParams();
        params = params.set('uid', (user.id).toString());
        params = params.set('customer_id', (customer.id).toString());

        return this.http.delete<{ data: T }>(`${this.settings.API_PATH}/${this.edge}/settings`, { params: params }).pipe(
            map(({ data }) => data)
        );
    }

    getCustomerSettings(active_user: User, customer_id: number): Observable<IWhmAccountDevCustomerSettings> {
        let params = new HttpParams();
        params = params.append('uid', (active_user.id).toString());
        params = params.append('customer_id', (customer_id).toString());

        return this.http.get<{ data: IWhmAccountDevCustomerSettings }>(`${this.settings.API_PATH}/${this.edge}/customer-settings`, { params: params }).pipe(
            map(({ data }) => data)
        );
    }

    setCustomerSettings(active_user: User, data: IWhmAccountDevCustomerSettings): Observable<IWhmAccountDevCustomerSettings | null> {
        const body = { uid: active_user.id, ...data };

        return this.http.patch<{ data: IWhmAccountDevCustomerSettings | null }>(`${this.settings.API_PATH}/${this.edge}/customer-settings`, body).pipe(
            map(({ data }) => data)
        );
    }

    resetCustomerSettings(active_user: User, customer_id: number): Observable<IWhmAccountDevCustomerSettings> {
        let params = new HttpParams();
        params = params.append('uid', (active_user.id).toString());
        params = params.append('customer_id', (customer_id).toString());

        return this.http.delete<{ data: IWhmAccountDevCustomerSettings }>(`${this.settings.API_PATH}/${this.edge}/customer-settings`, { params: params }).pipe(
            map(({ data }) => data)
        );
    }

    domainCheck(domain: string, customerId: number): Observable<boolean>  {
        let params = new HttpParams();
        params = params.append('domain', (domain).toString());
        params = params.append('customer_id', (customerId).toString());

        return this.http.get<{ data: boolean }>(`${this.settings.API_PATH}/${this.edge}/check-domain`, {params: params}).pipe(
            map(response => response.data),
        );
    }
}