import { Actions, ActionTypes } from '../actions/registration.actions';
import { NGRXError } from '@appRoot/core/ngrx-store/models/NGRXError';

export interface State {
	loading: boolean;
	error: NGRXError<Actions>
	complete: boolean;
}

export const initialState: State = {
	loading: false,
	error: null,
	complete: null,
};

export function reducer(state = initialState, action: Actions): State {
	switch (action.type) {		
		case ActionTypes.REGISTRATION: 
			return {
				...state,
				loading: true,
				complete: null,
			};

		case ActionTypes.REGISTRATION_SUCCESS:
			return {
				...state,
				loading: false,
				complete: true,
			};

		case ActionTypes.REGISTRATION_FAILED:
			return {
				...state,
				loading: false,
				complete: false,
			};

		case ActionTypes.REGISTRATION_COMPLETE_RESET:
			return {
				...state,
				complete: null,
			};

		case ActionTypes.ERROR:
			return {
				...state,
				loading: false,
				error: new NGRXError(action.payload.action, action.payload.error),
			};

		default:
			return state;
	}
}