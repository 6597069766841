<app-preloader type="sk-fading-circle" *ngIf="loading"></app-preloader>

<div class="row mb-3">
    <div class="col-12 text-center">
        Everything is almost ready... You can now create an account.
    </div>
</div>

<div class="row">
    <div class="col-12 text-center">
        Your selected plan:
    </div>
</div>

<div class="row">
    <div class="col-3"></div>
    <div class="col-6">
        <div class="card border plan-card bg-primary">
            <div class="card-header text-white text-center d-flex justify-content-center align-items-center bg-primary">
                {{selectedPlan?.name}}
            </div>

            <div class="card-body bg-light text-dark">
                <div [innerHTML]="selectedPlan?.description | safeHtml"></div>
            </div>

            <div class="card-footer text-white text-center d-flex justify-content-center align-items-center bg-primary">
                Price: {{ (selectedPlan?.price | number : '1.0-0':'es') + ' kr/month' }}
            </div>
        </div>
    </div>
    <div class="col-3"></div>
</div>

<app-bs4-alert *ngIf="errorShow" [message]="errorMessage" type="danger"></app-bs4-alert>

<hr class="mt-5">

<button class="btn btn-primary btn-block" type="button" (click)="create()">{{ 'Create account' | translate }}</button>