import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { SettingsService } from '@appRoot/core/services/settings.service';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { INotificationIndexParams, INotificationResponse, INotificationUpdateParams } from '../models';


@Injectable({
    providedIn: 'root'
})
export class NotificationHttpService {

    public readonly edge: string = 'notification';

    constructor(
        private http: HttpClient,
        private settings: SettingsService,
    ) { }

    fetch(params: INotificationIndexParams): Observable<INotificationResponse[]> {
        let _params = new HttpParams({ fromObject: <any>{ ...params } });

        return this.http.get<{ data: INotificationResponse[] }>(`${this.settings.API_PATH}/${this.edge}`, { params: _params }).pipe(
            map(({ data }) => data),
        );
    }

    update(params: INotificationUpdateParams): Observable<INotificationResponse> {
        return this.http.patch<{ data: INotificationResponse }>(`${this.settings.API_PATH}/${this.edge}/1`, params).pipe(
            map(({ data }) => data),
        );
    }
}
