import { ActionReducerMap, createFeatureSelector } from '@ngrx/store';
import * as selfSignupReducer from './reducers/self-signup.reducer';
import * as selfSignupAccountsReducer from './reducers/self-signup-accounts.reducer';


export interface State {
    self_signup: selfSignupReducer.State,
    self_signup_accounts: selfSignupAccountsReducer.State,
}

export const reducers: ActionReducerMap<State> = {
    self_signup: selfSignupReducer.reducer,
    self_signup_accounts: selfSignupAccountsReducer.reducer
};

export const ModuleStore = createFeatureSelector<State>('SelfSignupModule');