import {
    ChangeDetectionStrategy,
    Component,
    ElementRef,
    OnInit,
    ViewChild
} from '@angular/core';
import { AbstractControl, FormControl, FormGroup, FormGroupDirective, Validators } from '@angular/forms';
import { BasicForm } from '@appRoot/core/forms/form-basic.form';
import { Store } from "@ngrx/store";
import * as selfSignupActions from "../../../ngrx-store/actions/self-signup.actions";
import { ISelfSignupRequestStorePaymentParams } from "../../../models";
import { fadeInAnimation } from '../../../_animations';
import { CreditCardValidators } from "angular-cc-library";
import * as selfSignupAccountsActions from "@appRoot/lazy-modules/self-signup/ngrx-store/actions/self-signup-accounts.actions";


@Component({
    selector: 'app-payment-form',
    templateUrl: './payment-form.component.html',
    styleUrls: ['./payment-form.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    animations: [ fadeInAnimation ],
    host: {'[@fadeInAnimation]': ''}
})
export class PaymentFormComponent extends BasicForm implements OnInit {

    @ViewChild(FormGroupDirective, { static: true }) private formGroupDirective: FormGroupDirective;
    @ViewChild(FormControl) ccNumber: FormControl;
    @ViewChild("ccNumber") ccNumberField: ElementRef;
    @ViewChild("ccExp") ccDateField: ElementRef;

    public validationMessages: Map<AbstractControl, {[key:string]: string}>;

    constructor(
        private store: Store<any>
    ) {
        super();
    }

    get nameCtrl() { return this.form.get('name') as FormControl; }
    get creditCardCtrl() { return this.form.get('creditCard') as FormControl; }
    get expirationDateCtrl() { return this.form.get('expirationDate') as FormControl; }

    ngOnInit() {
        super.ngOnInit();
        this.createValidationMessages();
    }

    createForm() {
        let name =           new FormControl("", [Validators.required]);
        let creditCard =     new FormControl("", [Validators.required, CreditCardValidators.validateCCNumber]);
        let expirationDate = new FormControl("", [CreditCardValidators.validateExpDate]);

        this.form = new FormGroup({
            name: name,
            creditCard: creditCard,
            expirationDate: expirationDate
        });
    }

    onSubmit() {
        super.onSubmit();

        if (this.form.valid) {
            this.loading = true;

            let card_number = this.cleanCardNumber(this.creditCardCtrl.value);
            let newPayment: ISelfSignupRequestStorePaymentParams = {
                name: this.nameCtrl.value,
                creditCard: card_number,
                expirationDate: this.expirationDateCtrl.value,
            };

            this.store.dispatch(new selfSignupActions.StepCompletion());
            this.store.dispatch(new selfSignupAccountsActions.SetCustomerCard(newPayment));
        }
    }

    private createValidationMessages(){
        this.validationMessages = new Map([
            [this.nameCtrl, {
                'required': 'Name is required.',
            }],
            [this.creditCardCtrl, {
                'required': 'Credit Card Number is required.',
                'ccNumber': 'Incorrect card number',
                'coincidence': '!!!'
            }]
        ]);
    }

    cardNumberSpacing() {
        const input = this.ccNumberField.nativeElement;
        const {selectionStart} = input;
        const {creditCard} = this.form.controls;

        let trimmedCardNum = creditCard.value.replace(/\s+/g, '');

        if (trimmedCardNum.length > 16) {
            trimmedCardNum = trimmedCardNum.substr(0, 16);
        }

        /* Handle American Express 4-6-5 spacing format */
        const partitions = trimmedCardNum.startsWith('34') || trimmedCardNum.startsWith('37')
            ? [4, 6, 5]
            : [4, 4, 4, 4];

        const numbers = [];
        let position = 0;
        partitions.forEach(partition => {
            const part = trimmedCardNum.substr(position, partition);
            if (part) numbers.push(part);
            position += partition;
        });

        creditCard.setValue(numbers.join(' '));

        /* Handle caret position if user edits the number later */
        if (selectionStart < creditCard.value.length - 1) {
            input.setSelectionRange(selectionStart, selectionStart, 'none');
        }
    }

    dateMask() {
        const input = this.ccDateField.nativeElement;

        const trimmed = input.value.replace(/\s+/g, '').slice(0, input.value.indexOf('/')==-1?4:5);
        if (trimmed.length > 2) {
            return (input.value = `${trimmed.slice(0, 2)}/${trimmed.slice(trimmed.indexOf('/')==-1?2:3)}`);
        }
    }

    cardMask(number: string) {
        return number.slice(0, -4).replace(/./g, '*') + number.slice(-4)
    }

    cleanCardNumber(number: string) {
        if (number) {
            return number.replace(/[\s-]/g, "");
        }
    }
}
