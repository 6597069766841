import { Action } from '@ngrx/store';
import { CustomerGroupsChangeNotification } from '@appRoot/core/customer/models';
import { NGRXError } from '@appRoot/core/ngrx-store/models/NGRXError';


export enum ActionTypes {
    GET = '[Customer Groups Change Notifications] get',
    GET_SUCCESS = '[Customer Groups Change Notifications] get success',
    GET_FAILED = '[Customer Groups Change Notifications] get failed',

    UPDATE = '[Customer Groups Change Notifications] update',
    UPDATE_SUCCESS = '[Customer Groups Change Notifications] update success',
    UPDATE_FAILED = '[Customer Groups Change Notifications] update failed',

    ERROR = '[Customer Groups Change Notifications] error',
}

export class Get implements Action {
    readonly type = ActionTypes.GET;
    constructor(readonly payload: number) { }
}
export class GetSuccess implements Action {
    readonly type = ActionTypes.GET_SUCCESS;
}
export class GetFailed implements Action {
    readonly type = ActionTypes.GET_FAILED;
}

export class Update implements Action {
    readonly type = ActionTypes.UPDATE;
    constructor(readonly payload: {
        notification: CustomerGroupsChangeNotification;
        changes: {notification: CustomerGroupsChangeNotification;};
    }) { }
}
export class UpdateSuccess implements Action {
    readonly type = ActionTypes.UPDATE_SUCCESS;
    constructor(readonly payload: {notification: CustomerGroupsChangeNotification}) { }
}
export class UpdateFailed implements Action {
    readonly type = ActionTypes.UPDATE_FAILED;
}

export class Error implements Action {
    readonly type = ActionTypes.ERROR;

    constructor(readonly payload: NGRXError<Actions>) { }
}


export type Actions =
    | Get
    | GetSuccess
    | GetFailed
    | Update
    | UpdateSuccess
    | UpdateFailed
    | Error
    ;
