import { ICustomerGetOpenExpensesResponse  } from './ICustomerRequestParams';

export class OpenExpenses {
	Number: number;
    Processed: number;
    Status: number;
    DeliveryStatus: number;
    Tax: number;
    LocalAmount: number;
    Amount: number;
    Discount: number;
    Date: string;

	constructor(data?: ICustomerGetOpenExpensesResponse){
		if (data) {
            this.Number = data.Number;
			this.Processed = data.Processed;
			this.Status = data.Status;
			this.DeliveryStatus = data.DeliveryStatus;
			this.Tax = data.Tax;
			this.LocalAmount = data.LocalAmount;
			this.Amount = data.Amount;
			this.Discount = data.Discount;
			this.Date = data.Date;
		}
	}
}