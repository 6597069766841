import { ChangeDetectorRef, Component, Input, OnInit, ViewChild } from '@angular/core';
import { SettingsService } from '@appRoot/core/services/settings.service';
import { Subject } from 'rxjs';
import { tuple } from "@appRoot/core/utils";
import {ActivatedRoute, Router} from "@angular/router";
import { ActionsSubject, select, Store } from "@ngrx/store";
import { filter, take, takeUntil } from "rxjs/operators";
import * as selfSignupSelectors from "@appRoot/lazy-modules/self-signup/ngrx-store/selectors/self-signup.selectors";
import * as selfSignupActions from "@appRoot/lazy-modules/self-signup/ngrx-store/actions/self-signup.actions";
import * as selfSignupAccountsActions from "@appRoot/lazy-modules/self-signup/ngrx-store/actions/self-signup-accounts.actions";
import { SelfSignupPackages } from "@appRoot/lazy-modules/self-signup/models";
import { BsModalRef, BsModalService } from "ngx-bootstrap/modal";
import { AuthenticationService } from "@appRoot/core/authentication/services/authentication.service";
import { ofType } from "@ngrx/effects";
import { ToastMessageService } from "@appRoot/shared/components/toast-message/toast-message.service";
import * as selfSignupAccountsSelectors from "@appRoot/lazy-modules/self-signup/ngrx-store/selectors/self-signup-accounts.selectors";


const Services = tuple(
    "accounts",
    "dev-accounts"
);
type Services = typeof Services[number];

@Component({
    selector: 'app-self-signup-container',
    templateUrl: './self-signup-container.component.html',
    styleUrls: ['./self-signup-container.component.scss']
})
export class SelfSignupContainer implements OnInit {

    readonly ngUnsubscribe = new Subject();

    private customerId: number = null;

    public services = Services;
    public packages: SelfSignupPackages[] = null;
    public selectedPlan: SelfSignupPackages = null;
    public loadingPackages: boolean = false;
    public loadingStepRegister: boolean = false;
    public loadingStepService: boolean = false;
    public loadingStepPayment: boolean = false;
    public loadingStepCompletion: boolean = false;
    public loading: boolean = false;

    @Input() public service: string;

    @ViewChild("modalTemplate", {static: true}) modalTemplate;
    modalRef: BsModalRef;

    constructor(
        private router: Router,
        public settings: SettingsService,
        private store: Store<any>,
        private cdr: ChangeDetectorRef,
        private bsModalService: BsModalService,
        private authService: AuthenticationService,
        private actionsSubject: ActionsSubject,
        private toastService: ToastMessageService,
        private route: ActivatedRoute
    ) { }

    ngOnInit() {
        if ( !Services.find(item => item === this.service) ) {
            this.loginPageNavigate();
        }

        this.loadData();
        this.getPackages();

        this.loadingPackages$().subscribe(loading => {
            this.loadingPackages = loading;
            this.cdr.detectChanges();
        });

        this.bsModalService.onHide.subscribe(() => {
            this.selectedPlan = null;
        });

        this.loadingStepRegister$().subscribe(loading => {
            this.loadingStepRegister = loading;
            this.cdr.detectChanges();
        });

        this.loadingStepService$().subscribe(loading => {
            this.loadingStepService = loading;
            this.cdr.detectChanges();
        });

        this.loadingStepPayment$().subscribe(loading => {
            this.loadingStepPayment = loading;
            this.cdr.detectChanges();
        });

        this.loadingStepCompletion$().subscribe(loading => {
            this.loadingStepCompletion = loading;
            this.cdr.detectChanges();
        });

        this.loadingSelectCustomerId$().subscribe(id => {
            this.customerId = id;
            this.cdr.detectChanges();
        });

        this.actionsSubject.pipe(
            takeUntil(this.ngUnsubscribe),
            ofType(selfSignupActions.ActionTypes.CREATE_SUCCESS)
        ).subscribe((action: selfSignupActions.CreateSuccess) => {
            this.modalRef?.hide();
            this.loading = true;

            this.authService.isAuthenticated$().pipe(
                take(1)
            ).subscribe(e => {
                if (e) {
                    this.showCreatedMessage();

                    this.store.pipe(
                        select(selfSignupAccountsSelectors.getCustomerId),
                        takeUntil(this.ngUnsubscribe),
                        filter(e => e !== null)
                    ).subscribe(e => {
                        let url = '/customers/' + this.customerId + '/active-services';
                        this.router.navigate([url],  { queryParams: { self: 'accounts' } });

                        this.cdr.detectChanges();
                    });
                } else {
                    this.showNewUserCreatedMessage();
                    this.loginPageNavigate();
                }

                this.cdr.detectChanges();
            });

            this.cdr.detectChanges();
        });
    }

    ngOnDestroy(): void {
        this.unsubscribe();
    }

    unsubscribe() {
        this.ngUnsubscribe.next();
        this.ngUnsubscribe.complete();
    }

    private loadData(): void {
        this.store.pipe(
            select(selfSignupSelectors.getLoaded),
            takeUntil(this.ngUnsubscribe),
            filter(e => !e)
        ).subscribe(e => {
            this.store.dispatch(new selfSignupActions.Load());
            this.cdr.detectChanges();
        });
    }

    private getPackages() {
        return this.store.pipe(
            select(selfSignupSelectors.getPackages),
            takeUntil(this.ngUnsubscribe),
            filter(e => !!e)
        ).subscribe(p => {
            this.packages = p;
            this.cdr.detectChanges();
        });
    }

    private loadingPackages$() {
        return this.store.pipe(
            select(selfSignupSelectors.getLoaded),
            takeUntil(this.ngUnsubscribe)
        );
    }

    public selectPackage(p: SelfSignupPackages) {
        this.selectedPlan = p;

        this.store.dispatch(new selfSignupAccountsActions.Reset());

        this.store.dispatch(new selfSignupActions.StepService());
        this.store.dispatch(new selfSignupAccountsActions.SetPackageId(p.id));

        this.modalRef = this.bsModalService.show(this.modalTemplate);
        this.modalRef.setClass("modal-dialog-centered disable-modal-content");
    }

    private loadingStepRegister$() {
        return this.store.pipe(
            select(selfSignupSelectors.getStepRegister),
            takeUntil(this.ngUnsubscribe)
        );
    }

    private loadingStepService$() {
        return this.store.pipe(
            select(selfSignupSelectors.getStepService),
            takeUntil(this.ngUnsubscribe)
        );
    }

    private loadingStepPayment$() {
        return this.store.pipe(
            select(selfSignupSelectors.getStepPayment),
            takeUntil(this.ngUnsubscribe)
        );
    }

    private loadingStepCompletion$() {
        return this.store.pipe(
            select(selfSignupSelectors.getStepCompletion),
            takeUntil(this.ngUnsubscribe)
        );
    }

    private loadingSelectCustomerId$() {
        return this.store.pipe(
            select(selfSignupAccountsSelectors.getCustomerId),
            takeUntil(this.ngUnsubscribe)
        );
    }

    private showCreatedMessage() {
        this.toastService.success({
            title: 'Success',
            message: `The account was created successfully!`
        });
    }

    private showNewUserCreatedMessage() {
        this.toastService.success({
            title: 'Success',
            message: `Registration completed. Please verify your email to login.`
        });
    }

    private loginPageNavigate() {
        this.router.navigate(['/login'], {relativeTo: this.route});
    }
}