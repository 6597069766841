export interface ISelfSignupAccountsPackagesResponse {
    id: number;
    name: string;
    description: string;
    item_code: string;
    product_description: string;
    price: string;
    sort_id: number;
}

export class SelfSignupPackages {
    id: number = null;
    name: string = null;
    description: string = null;
    item_code: string = null;
    product_description: string = null;
    price: string = null;
    sort_id: number = null;

    constructor(data?: ISelfSignupAccountsPackagesResponse) {
        if (data) {
            this.id = data.id;
            this.name = data.name;
            this.description = data.description;
            this.item_code = data.item_code;
            this.product_description = data.product_description;
            this.price = data.price;
            this.sort_id = data.sort_id;
        }
    }
}