import { Injectable } from '@angular/core';
import { Action } from '@ngrx/store';
import { Actions, Effect, ofType } from '@ngrx/effects';

import { merge, Observable, of as observableOf } from 'rxjs';
import { map, switchMap, tap } from 'rxjs/operators';
import { isEmpty } from 'lodash';

import { ModalService } from '@appRoot/shared/components/modal/modal.service';
import { ErrorCatcherService } from '@appRoot/error-catcher/error-catcher.service';
import { Reset as AuzReset } from '@appRoot/core/user/ngrx-store/actions/authorization.actions';
import { Reset as UserReset } from '@appRoot/core/user/ngrx-store/actions/user.actions';

import * as coreActions from '../actions/core.actions';


@Injectable()
export class CoreEffects {

    @Effect()
    reset$: Observable<Action> = this.actions$.pipe(
        ofType(coreActions.ActionTypes.RESET),
        switchMap(e => merge(
            // observableOf(new permissionReset),
            // observableOf(new roleReset),
            observableOf(new AuzReset),
            observableOf(new UserReset),
            observableOf(new coreActions.ResetComplete),
        )),
    );
    @Effect()
    error$: Observable<Action> = this.actions$.pipe(
        ofType(coreActions.ActionTypes.SHOW_ERROR),
        tap((action: coreActions.ShowError) => {

            let output = {
                name: action.payload.instance.hasOwnProperty('name')? action.payload.instance.name : null,
                status: action.payload.instance.hasOwnProperty('status')? action.payload.instance.status : null,
                statusText: action.payload.instance.hasOwnProperty('statusText')? action.payload.instance.statusText : null,
                message: action.payload.instance.hasOwnProperty('message')? action.payload.instance.message : null,
                action: action.payload.action.type,
            };
            if(this.errorCatcherService.has(action.payload.instance)){
                const catchError = this.errorCatcherService.get(action.payload.instance);

                output['catchMessage'] = catchError.message;
                output['catchCode'] = catchError.code;
                if(!isEmpty(catchError.errors)){
                    output['catchErrors'] = {};
                    for (let prop in catchError.errors) {
                        // noinspection JSUnfilteredForInLoop
                        output['catchErrors'][prop] = catchError.errors[prop].toString();
                    }
                }
            }

            let html = '<p>'+ (output['catchMessage']? output['catchMessage']: output['name']+'<br>'+output['statusText']+'<br>status: '+output['status']) +'</p>';
            if(output['catchErrors']){
                html += '<p>'+'Errors: '+'</p>';
                html += '<ul class="text-left">';
                html += '<li>'+Object.values(output['catchErrors']).join('</li><li>')+'</li>';
                html += '</ul>';
            }

            console.error( output );

            this.modalService.options = {
                title: 'Error',
                type: 'error',
                html: html,
            };
            this.modalService.show();
        }),
        map(e => new coreActions.ErrorReset)
    );
    @Effect()
    reload$: Observable<Action> = this.actions$.pipe(
        ofType(coreActions.ActionTypes.RELOAD),
        map(action => new coreActions.ReloadSuccess),
    );

    constructor(
        private actions$: Actions,
        private modalService: ModalService,
        private errorCatcherService: ErrorCatcherService,
    ) {}
}
