import { Compiler, getModuleFactory, Injector, NgModuleFactory } from "@angular/core";
import { LoadChildrenCallback } from "@angular/router";


export const loadDynamicModule = (subject: { id: string; loadChildren: LoadChildrenCallback }, injector: Injector, compiler: Compiler)/*: Promise<NgModuleRef<any>>*/ => {
    try {
        return Promise.resolve(getModuleFactory(subject.id).create(injector));
    } catch (e) {
        const loadedAndRegistered = subject.loadChildren() as Promise<NgModuleFactory<any>>;
        return loadedAndRegistered.then((moduleOrFactory) => {
            if (moduleOrFactory instanceof NgModuleFactory) {
                return moduleOrFactory;
            } else {
                return compiler.compileModuleAsync(moduleOrFactory);
            }
        }).then(moduleFactory => moduleFactory.create(injector));
    }
};
