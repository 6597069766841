import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { select, Store } from '@ngrx/store';

import { SetLanguage } from './ngrx-store/actions/translator.actions';
import { State } from './ngrx-store/reducers/translator.reducer';
import { getCurrentLanguage } from './ngrx-store/selectors/translator.selectors';
import { GlobalStorageService } from '@appRoot/core/services/global-storage.service'


@Injectable({providedIn: "root"})
export class TranslatorService {

    private defaultLanguage: string = 'en_US';

    private availablelangs = [
        { code: 'en_US', text: 'English' },
        { code: 'is_IS', text: 'Icelandic' },
        { code: 'ru_RU', text: 'Russian' }
    ];

    constructor(
        public translate: TranslateService,
        private store: Store<State>,
        private storage: GlobalStorageService
        ) {

        if (!this.translate.getDefaultLang())
            this.translate.setDefaultLang(this.getStoreLang());

        this.useLanguage();
    }

    useLanguage(lang: string = null) {
        this.store.dispatch( new SetLanguage(lang || this.translate.getDefaultLang()) );
    }

    getCurrentLanguage(): Observable<string> {
        return this.store.pipe(select(getCurrentLanguage));
    }

    getAvailableLanguages() {
        return this.availablelangs;
    }

    getStoreLang(): string {
        return this.storage.get().lang || this.defaultLanguage;
    }

    setStoreLang(lang: string): void {
        this.storage.set({lang: lang});
    }

    stream(key: string|Array<string>, defaultValue: string|Array<string>, interpolateParams?: Object): Observable<string|Object>{
        return this.translate.stream(key).pipe(
            map( (res: string | Object) => {
                if(key === res) return this.translate.parser.interpolate(<string>defaultValue, interpolateParams);

                if( key instanceof Array ){
                    if( !(defaultValue instanceof Array) ){
                      throw new Error(`"defaultValue" should be an Array.`);
                    }
                    if( key.length !== defaultValue.length ){
                      throw new Error(`The length "defaultValue" must be equal to "key".`);
                    }

                    key.forEach((val, i) => {
                        res[val] = ( res[val] === val ) ? this.translate.parser.interpolate(defaultValue[i], interpolateParams) : res[val] ;
                    });
                }

                return res;
            })
        );
    }

    instant(key: string|Array<string>, defaultValue: string|Array<string>, interpolateParams?: Object): string|Object{
        let res: string | Object = this.translate.instant(key, interpolateParams);
        if(key === res) return this.translate.parser.interpolate(<string>defaultValue, interpolateParams);

        if( key instanceof Array ){
            if( !(defaultValue instanceof Array) ){
                throw new Error(`"defaultValue" should be an Array.`);
            }
            if( key.length !== defaultValue.length ){
                throw new Error(`The length "defaultValue" must be equal to "key".`);
            }

            key.forEach((val, i) => {
                res[val] = ( res[val] === val ) ? this.translate.parser.interpolate(defaultValue[i], interpolateParams) : res[val] ;
            });
        }

        return res;
    }

    /**
     * Gets the translated value of a key (or an array of keys)
     * @param  {string|Array<string>} key
     * @param  {string|Array<string>} defaultValue
     * @param  {Object}               interpolateParams
     * @return {Observable}
     */
    public get(key: string | Array<string>, defaultValue: string|Array<string>, interpolateParams?: Object): Observable<string|Object> {
        return this.translate.get(key).pipe(
            map( (res: string | Object) => {
                if(key === res) return this.translate.parser.interpolate(<string>defaultValue, interpolateParams);

                if( key instanceof Array ){
                    if( !(defaultValue instanceof Array) ){
                        throw new Error(`"defaultValue" should be an Array.`);
                    }
                    if( key.length !== defaultValue.length ){
                        throw new Error(`The length "defaultValue" must be equal to "key".`);
                    }

                    key.forEach((val, i) => {
                        res[val] = ( res[val] === val ) ? this.translate.parser.interpolate(defaultValue[i], interpolateParams) : res[val] ;
                    });
                }

                return res;
            })
        );
    }

}
