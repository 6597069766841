import { Action } from '@ngrx/store';
import { User } from '@appRoot/core/user/models';


export enum ActionTypes {
    ERROR = '[Confirmation] error',
    SEND = '[Confirmation] send',
    SEND_SUCCESS = '[Confirmation] send success',
    CONFIRM = '[Confirmation] confirm',
    CONFIRM_SUCCESS = '[Confirmation] confirm success',
    CONFIRM_FAILED = '[Confirmation] confirm failed',
    RESET = '[Confirmation] Reset',
}

export class Error implements Action {
    readonly type = ActionTypes.ERROR;
    constructor(readonly payload: {error: any, action: Actions}) { }
}

export class Reset implements Action {
    readonly type = ActionTypes.RESET;
}

export class Send implements Action {
    readonly type = ActionTypes.SEND;
    constructor(readonly payload: {email: string, uid?: number; user_id?: number} ) { }
}

export class SendSuccess implements Action {
    readonly type = ActionTypes.SEND_SUCCESS;
    constructor(readonly payload: User) {}
}

export class Confirm implements Action {
    readonly type = ActionTypes.CONFIRM;
    constructor(readonly payload: {token: string} ) { }
}

export class ConfirmSuccess implements Action {
    readonly type = ActionTypes.CONFIRM_SUCCESS;
    constructor(readonly payload: User) {}
}

export class ConfirmFailed implements Action {
    readonly type = ActionTypes.CONFIRM_FAILED;
}


export type Actions =
    | Error
    | Reset
    | Send
    | SendSuccess
    | Confirm
    | ConfirmSuccess
    | ConfirmFailed
    ;