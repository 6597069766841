import { OmitUID, SortDirection } from '@appRoot/core/interfaces';
import { Action } from '@ngrx/store';
import { NGRXError } from '@appRoot/core/ngrx-store/models/NGRXError';
import { IPermissionRequestIndexParams, Permission } from '../../models';

export enum ActionTypes {
    ADD_MANY = '[Permissions] add many',
    LOAD = '[Permissions] load',
    LOAD_SUCCESS = '[Permissions] load success',
    LOAD_FAILED = '[Permissions] load failed',
    LOAD_ALL = '[Permissions] load all',
    LOAD_ALL_SUCCESS = '[Permissions] load all success',
    LOAD_ALL_FAILED = '[Permissions] load all failed',
    GET = '[Permissions] get',
    GET_SUCCESS = '[Permissions] get success',
    UPDATE = '[Permissions] update',
    UPDATE_SUCCESS = '[Permissions] update success',
    ERROR = '[Permissions] error',
    RESET = '[Permissions] reset',
    MARK_AS_CHANGED = '[Permissions] mark as changed',
}

export class AddMany implements Action {
    readonly type = ActionTypes.ADD_MANY;
    constructor(readonly payload: {permissions: Permission[]}) { }
}

export class Load implements Action {
    readonly type = ActionTypes.LOAD;
    constructor(readonly payload: OmitUID<IPermissionRequestIndexParams> = {}) { }
}

export class LoadSuccess implements Action {
    readonly type = ActionTypes.LOAD_SUCCESS;
    constructor(readonly payload: {permissions: Permission[]}) { }
}

export class LoadFailed implements Action {
    readonly type = ActionTypes.LOAD_FAILED;
}

export class LoadAll implements Action {
    readonly type = ActionTypes.LOAD_ALL;
    constructor(readonly payload: {
        sort?: string,
        order?: SortDirection,
    } = {}) {
    }
}

export class LoadAllSuccess implements Action {
    readonly type = ActionTypes.LOAD_ALL_SUCCESS;
    constructor(readonly payload: {permissions: Permission[]}) { }
}

export class LoadAllFailed implements Action {
    readonly type = ActionTypes.LOAD_ALL_FAILED;
}

export class Get implements Action {
    readonly type = ActionTypes.GET;
    constructor(readonly payload: {id: number}) { }
}

export class GetSuccess implements Action {
    readonly type = ActionTypes.GET_SUCCESS;
    constructor(readonly payload: {permission: Permission}) { }
}

export class Update implements Action {
    readonly type = ActionTypes.UPDATE;
    constructor(readonly payload: {origin: Permission, changed: Permission}) { }
}

export class UpdateSuccess implements Action {
    readonly type = ActionTypes.UPDATE_SUCCESS;
    constructor(readonly payload: {origin: Permission, changed: Permission}) { }
}

export class Error implements Action {
    readonly type = ActionTypes.ERROR;
    constructor(readonly payload: NGRXError<Actions>) { }
}

export class Reset implements Action {
    readonly type = ActionTypes.RESET;
}

export class MarkAsChanged implements Action {
    readonly type = ActionTypes.MARK_AS_CHANGED;
    constructor(readonly payload: {ids: number[]}) { }
}

export type Actions =
| AddMany
| Load
| LoadSuccess
| LoadFailed
| Get
| GetSuccess
| Update
| UpdateSuccess
| Error
| Reset
| MarkAsChanged
| LoadAll
| LoadAllSuccess
| LoadAllFailed
;
