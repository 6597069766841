import { ICustomerAdminMessagesResponse } from '@appRoot/core/customer/models/ICustomerRequestParams';


export class CustomerAdminMessages {
    id: number;
    customer_id?: number;
    message: string;
    type: string;

    constructor(data?: ICustomerAdminMessagesResponse){
        if (data) {
            this.id = data.id;
            this.customer_id = data.customer_id;
            this.message = data.message;
            this.type = data.type;
        }
    }
}